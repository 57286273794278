export enum AlertEKeyField {
  alertIdWithMacAddress = "alertIdWithMacAddress",
  alertType = "messageType",
  groupId = "groupId",
  alertGenerated = "alertCreatedOn",
  latestAlertGenerated = "lastAlertDateTime",
  status = "status",
  deviceTypeName = "deviceTypeName"
}

export enum AlertlogDropdownSuggession {
  alertType = "alertType",
  groupId = "groupId"
}

export enum AlertTypeText {
  YETTOFIX = "Unresolved",
  INPROGRESS = "In progress",
  RESOLVED = "Resolved",
}

export const AlertTypeStatus = [
  {
    id: 1,
    label: AlertTypeText.YETTOFIX,
    color: "#F44336",
  },
  {
    id: 2,
    label: AlertTypeText.INPROGRESS,
    color: "#FFA63F",
  },
  {
    id: 3,
    label: AlertTypeText.RESOLVED,
    color: "#47EA43",
  },
];

export interface AlertData {
  id: number;
  messageType: string;
  groupId: number;
  groupNumberWithPrefix: string;
  siteId: number;
  siteNumberWithPrefix: string;
  deviceId: number;
  alertType: number;
  alertNumber: number;
  alertLevel: string;
  alertCode: string;
  macAddress: string;
  deviceTypeName: string;
  alertIdWithMacAddress: string;
  alertCreatedOn: Date;
  lastAlertDateTime: Date;
  status: number;
  comment: string;
  isDelete: true;
  createDate: Date;
  createUserId: number;
  resolvedDate: Date;
  modifyDate: Date;
  modifyUserId: number;
  index?: number;
}

export interface AlertCommentFormData {
  id: number;
  status: number;
  comment: string;
}

export class DataTableAlert {
  page: number;
  size: number;
  total: number;
  data: AlertData[];
}

export interface AlertCommentData {
  message: string;
  time: Date;
}

export class DataAlertComment {
  page: number;
  size: number;
  total: number;
  data: AlertCommentData[];
}
