import { Series } from "./chart";
import { TimeStampId } from "./solar";
import { TabModule } from "./tab-module";
import { TimeFrameKey } from "./data-log";

export enum ReportTabKey {
  GROUP = 0,
  NOTIFICATION = 1,
}

export const StartYear = 2024;

export const ReportTabModule: TabModule[] = [
  {
    active: true,
    removable: true,
    title: "Group Overview",
    id: 1,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    title: "Notifications",
    id: 2,
    disabled: false,
  },
];

export enum ReportNotificationTabKey {
  SUMMARY = 0,
  OUTSTANDING = 1,
}

export const ReportNotificationTabModule: TabModule[] = [
  {
    active: true,
    removable: true,
    title: "Summary",
    id: 1,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    title: "Outstanding Messages",
    id: 2,
    disabled: false,
  },
];

export interface ReportTab {
  id: string;
  label: string;
  active: boolean;
}

export interface ReportFilter {
  selectedTimeStamp: { id: number; label: string; active: boolean };
  fromDate: string;
  toDate: string;
  currentFromDate: string;
}

export const TIME_FRAME_KEY_MAP = {
  [TimeStampId.Day]: TimeFrameKey.Today,
  [TimeStampId.Week]: TimeFrameKey.Last7Days,
  [TimeStampId.Month]: TimeFrameKey.LastMonth,
  [TimeStampId.Year]: TimeFrameKey.LastYear,
  [TimeStampId.Custom]: TimeFrameKey.Custom,
  [TimeStampId.DayPerMinute]: TimeFrameKey.DayPerMinute,
  [TimeStampId.DayPer15Minute]: TimeFrameKey.DayPer15Minute,
};

export enum ReportTable {
  QSCORE = "Q-Score",
  ENERGY = "Energy",
  CARBON = "Carbon",
  EFFICIENCY = "Efficiency",
  POWERQUALITY = "PowerQuality",
  FINANCIAL = "Financial",
  QSCOREPERFORMANCE = "qSenseScorePerformance",
  ENERGYPERFORMANCE = "energyPerformance",
  CARBONPERFORMANCE = "carbonPerformance",
  EFFICIENCYPERFORMANCE = "efficiencyPerformance",
  POWERQUALITYPERFORMANCE = "powerQualityPerformance",
  FINANCIALPERFORMANCE = "financialPerformance"
}

export const ReportTableTab: ReportTab[] = [
  {
    id: ReportTable.QSCORE,
    label: "Q-Score",
    active: true,
  },
  {
    id: ReportTable.ENERGY,
    label: "Energy",
    active: false,
  },
  {
    id: ReportTable.CARBON,
    label: "Carbon",
    active: false,
  },
  {
    id: ReportTable.EFFICIENCY,
    label: "Efficiency",
    active: false,
  },
  {
    id: ReportTable.POWERQUALITY,
    label: "Power Quality",
    active: false,
  },
  {
    id: ReportTable.FINANCIAL,
    label: "Financial",
    active: false,
  },
];

export interface ReportPerformanceCard
  extends PerformanceDataValue,
  QScorePerformanceDataValue {
  id: string;
  title: string;
  imgPath: string;
  dataKey: string;
}

export const PerformanceCartArray = [
  {
    id: ReportTable.QSCORE,
    title: "Q-Score",
    imgPath: "assets/images/shield-blue-icon.svg",
    dataKey: "qSenseScorePerformance",
  },
  {
    id: ReportTable.ENERGY,
    title: "Energy",
    imgPath: "assets/images/energy.svg",
    dataKey: "energyPerformance",
  },
  {
    id: ReportTable.CARBON,
    title: "Carbon",
    imgPath: "assets/images/carbon.svg",
    dataKey: "carbonPerformance",
  },
  {
    id: ReportTable.EFFICIENCY,
    title: "Efficiency",
    imgPath: "assets/images/efficiency.svg",
    dataKey: "efficiencyPerformance",
  },
  {
    id: ReportTable.POWERQUALITY,
    title: "Power Quality",
    imgPath: "assets/images/power-quality.svg",
    dataKey: "powerQualityPerformance",
  },
  {
    id: ReportTable.FINANCIAL,
    title: "Financial",
    imgPath: "assets/images/financial.svg",
    dataKey: "financialPerformance",
  },
];

export interface ReportParams {
  fromDate: string;
  toDate: string;
  groupId: number;
  siteId?: number;
  timeFrame?: number;
}

export interface ReportSolarParams {
  fromDate: string;
  toDate: string;
  residenceId: number;
  timeFrame: number;
}

export interface ReportChartValue {
  value: number;
  lableXAxis: string;
}

export interface PowerQualityDataValue {
  labelXAixs: string;
  currentUnbalance: number;
  voltageUnbalance: number;
  thdv: number;
  thdi: number;
}

export interface QscoreDataValue {
  lableXAxis: string;
  overAll: number;
  energy: number;
  carbon: number;
  efficiency: number;
  powerQuality: number;
}

export interface PerformanceDataValue {
  total: number;
  totalDifference: number;
  totalGroup: number;
  rankingAgainstGlobalCurrentPosition: number;
  rankingAgainstGlobalDifference: number;
  topPerformer: string;
  topPerformerValue: number;
  worstPerformer: string;
  worstPerformerValue: number;
  mostImproved: string;
  mostImprovedValueDifference: number;
}

export interface QScorePerformanceDataValue {
  totalGroup: number;
  rankingAgainstGlobalCurrentPosition: number;
  rankingAgainstGlobalDifference: number;
  topPerformer: string;
  topPerformerScore: number;
  worstPerformer: string;
  worstPerformerScore: number;
  mostImproved: string;
  mostImprovedScoreDifference: number;
}

export interface ReportGroupDataList {
  qscoreData: QscoreDataValue[];
  energy: ReportChartValue[];
  carbon: ReportChartValue[];
  efficiency: ReportChartValue[];
  financial: ReportChartValue[];
  powerQuality: PowerQualityDataValue[];
  qSenseScorePerformance: QScorePerformanceDataValue;
  energyPerformance: PerformanceDataValue;
  carbonPerformance: PerformanceDataValue;
  efficiencyPerformance: PerformanceDataValue;
  powerQualityPerformance: PerformanceDataValue;
  financialPerformance: PerformanceDataValue;
}

export enum QscoreChartColor {
  OverAll = "#28C3F9",
  Energy = "#6B71EE",
  Carbon = "#FFBE3F",
  Efficiency = "#ED6E6E",
  Financial = "#31B854",
  PowerQuality = "#6BF28E",
  HarmonicsTHDI = "#6B71EE",
  HarmonicsTHDV = "#28C3F9",
  voltageUnbalance = "#6BF28E",
  CurrentUnbalance = "#ED6E6E",
}

export const QscoreSeries: Series[] = [
  {
    name: "Over All",
    key: "overAll",
    color: QscoreChartColor.OverAll,
    active: true,
    img: "assets/images/shield-blue-icon.svg",
  },
  {
    name: "Energy",
    key: "energy",
    color: QscoreChartColor.Energy,
    active: true,
  },
  {
    name: "Carbon",
    key: "carbon",
    color: QscoreChartColor.Carbon,
    active: true,
  },
  {
    name: "Efficiency",
    key: "efficiency",
    color: QscoreChartColor.Efficiency,
    active: true,
  },
  {
    name: "Power Quality",
    key: "powerQuality",
    color: QscoreChartColor.PowerQuality,
    active: true,
  },
];

export const ReportSeries: Series[] = [
  {
    name: "Q-Score",
    key: "value",
    color: QscoreChartColor.OverAll,
    active: true,
    img: "assets/images/shield-blue-icon.svg",
  },
  {
    name: "Energy",
    key: "value",
    color: QscoreChartColor.Energy,
    active: true,
  },
  {
    name: "Carbon",
    key: "value",
    color: QscoreChartColor.Carbon,
    active: true,
  },
  {
    name: "Efficiency",
    key: "value",
    color: QscoreChartColor.Efficiency,
    active: true,
  },
  {
    name: "Financial",
    key: "value",
    color: QscoreChartColor.Financial,
    active: true,
  },
];

export const PowerQualitySeries: Series[] = [
  {
    name: "Current Unbalance",
    key: "currentUnbalance",
    color: QscoreChartColor.CurrentUnbalance,
    active: true,
  },
  {
    name: "Voltage Unbalance",
    key: "voltageUnbalance",
    color: QscoreChartColor.voltageUnbalance,
    active: true,
  },
  {
    name: "THDV",
    key: "thdv",
    color: QscoreChartColor.HarmonicsTHDV,
    active: true,
  },
  {
    name: "THDI",
    key: "thdi",
    color: QscoreChartColor.HarmonicsTHDI,
    active: true,
  },
];

export const PerformanceSeries: Series[] = [
  {
    name: "",
    key: "value",
    color: QscoreChartColor.OverAll,
    active: true,
  },
];

export interface GraphNotificationData {
  labelXAxis: string;
  level1: number;
  level2: number;
  level3: number;
}

export interface SummaryNotificationData {
  siteId: number;
  siteName: string;
  level1YetToFix: number;
  level1InProgress: number;
  level1Resolved: number;
  level2YetToFix: number;
  level2InProgress: number;
  level2Resolved: number;
  level3YetToFix: number;
  level3InProgress: number;
  level3Resolved: number;
  total: number;
}

export interface OutStandingNotificationAlerts {
  siteId: number;
  siteName: string;
  alertId: number;
  alertIdWithMacAddress: string;
  alertType: string;
  alertStatus: string;
  alertGeneratedDate: Date;
  outstandingDays: number;
  alertMessage: string;
  alertCode: string;
  alertLevel: string;
}

export interface ReportNotificationDataList {
  groupId: number;
  groupName: string;
  graphData: GraphNotificationData[];
  summaryData: SummaryNotificationData[];
  outstandingAlerts: OutStandingNotificationAlerts[];
}

export enum ReportNotificationColor {
  Level1 = "#6B71EE",
  level2 = "#C471FF",
  Level3 = "#28C3F9",
}

export const ReportNotificationSeries: Series[] = [
  {
    name: "Level 1",
    key: "level1",
    color: ReportNotificationColor.Level1,
    active: true,
  },
  {
    name: "Level 2",
    key: "level2",
    color: ReportNotificationColor.level2,
    active: true,
  },
  {
    name: "Level 3",
    key: "level3",
    color: ReportNotificationColor.Level3,
    active: true,
  },
];

export enum ReportSolarTab {
  GENERATED = "Generated",
  USAGE = "Usage",
  IMPORTED = "Imported",
  EXPORTED = "Exported",
}

export interface GraphDataSolarReport {
  labelXAxis: number;
  used: number;
  generated: number;
  exported: number;
  imported: number;
  saved: number;
  graphUsage: number;
}

export interface CardReportSolar {
  currentValue: number;
  previousValue: number;
  differenceValue: number;
}

export interface ReportSolarDataList {
  residenceId: number;
  residenceName: string;
  locationLatitude: string;
  locationLongitude: string;
  graphData: GraphDataSolarReport[];
  generated: CardReportSolar;
  usage: CardReportSolar;
  imported: CardReportSolar;
  exported: CardReportSolar;
  savedFinancial: number;
  savedCarbon: number;
  selfSufficiency: number;
}

export interface ReportSolarCard extends CardReportSolar {
  id: string;
  title: string;
  dataKey: string;
}

export const ReportSolarCartArray = [
  {
    id: ReportSolarTab.GENERATED,
    title: ReportSolarTab.GENERATED,
    dataKey: "generated",
  },
  { id: ReportSolarTab.USAGE, title: ReportSolarTab.USAGE, dataKey: "usage" },
  {
    id: ReportSolarTab.IMPORTED,
    title: ReportSolarTab.IMPORTED,
    dataKey: "imported",
  },
  {
    id: ReportSolarTab.EXPORTED,
    title: ReportSolarTab.EXPORTED,
    dataKey: "exported",
  },
];

export enum ReportSolarSavingTab {
  FINANCIAL = "Financial",
  CARBON = "Carbon",
  SELFSUFFICIENCY = "Self-sufficiency",
}

export interface ReportSolarSavingCard {
  id: string;
  title: string;
  imgPath: string;
  dataKey: string;
  postMeasurement: string;
  preMeasurement: string;
  value: number;
}

export const ReportSolarSavingCartArray = [
  {
    id: ReportSolarSavingTab.FINANCIAL,
    title: ReportSolarSavingTab.FINANCIAL,
    imgPath: "assets/images/financial.svg",
    postMeasurement: "",
    preMeasurement: "AED ",
    dataKey: "savedFinancial",
    value: 0,
  },
  {
    id: ReportSolarSavingTab.CARBON,
    title: ReportSolarSavingTab.CARBON,
    imgPath: "assets/images/carbon.svg",
    postMeasurement: " KgCO2e",
    preMeasurement: "",
    dataKey: "savedCarbon",
    value: 0,
  },
  {
    id: ReportSolarSavingTab.SELFSUFFICIENCY,
    title: "Self Sufficiency",
    imgPath: "assets/images/self-sufficiency.svg",
    postMeasurement: "%",
    preMeasurement: "",
    dataKey: "selfSufficiency",
    value: 0,
  },
];

export enum ReportDropdownSuggession {
  groupId = "groupId",
  siteId = "siteId",
  residenceId = "residenceId",
  countryId = "countryId",
}


export interface ReportRequest {
  fromDate: string,
  toDate: string,
  groupId: number,
  siteId: number,
  timeFrame: number,
  currencyCode?: string
}