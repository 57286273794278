import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/main/core/guards/auth.guard";
import { LoginComponent } from "src/main/modules/login/login.component";
import { P404Component } from "src/main/shared/components/error/404.component";
import { DefaultLayoutComponent } from "src/main/shared/components/default-layout/default-layout.component";
import { ForgotPasswordComponent } from "src/main/modules/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "src/main/modules/reset-password/reset-password.component";
import { ExpireLinkComponent } from "src/main/modules/expire-link/expire-link.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "landing",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "set-password",
    component: ResetPasswordComponent,
  },
  {
    path: "change-password",
    component: ResetPasswordComponent,
  },
  {
    path: "expire-link",
    component: ExpireLinkComponent,
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    children: [
      {
        path: "landing",
        loadChildren: () =>
          import("../main/modules/landing/landing.module").then(
            (m) => m.LandingModule
          ),
      },
      {
        path: "live-status",
        loadChildren: () =>
          import("../main/modules/solar/solar.module").then(
            (m) => m.SolarModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("../main/modules/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "control-panel",
        loadChildren: () =>
          import(
            "../main/modules/residential-dashboard/residential-dashboard.module"
          ).then((m) => m.ResidentialDashboardModule),
      },
      {
        path: "mdm",
        loadChildren: () =>
          import("../main/modules/mdm/mdm.module").then(
            (m) => m.ResidentialModule
          ),
      },
      {
        path: "energy",
        loadChildren: () =>
          import("../main/modules/energy/energy.module").then(
            (m) => m.EnergyModule
          ),
      },
      {
        path: "carbon",
        loadChildren: () =>
          import("../main/modules/carbon/carbon.module").then(
            (m) => m.CarbonModule
          ),
      },
      {
        path: "financial",
        loadChildren: () =>
          import("../main/modules/financial/financial.module").then(
            (m) => m.FinancialModule
          ),
      },
      {
        path: "self-sufficiency",
        loadChildren: () =>
          import(
            "../main/modules/self-sufficience/self-sufficience.module"
          ).then((m) => m.SelfSufficienceModule),
      },
      {
        path: "data-log",
        loadChildren: () =>
          import("../main/modules/data-log/data-log.module").then(
            (m) => m.DataLogModule
          ),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("../main/modules/notification/notification.module").then(
            (m) => m.NotificationModule
          ),
      },
      {
        path: "alert-log",
        loadChildren: () =>
          import("../main/modules/alert-log/alert-log.module").then(
            (m) => m.AlertLogModule
          ),
      },
      {
        path: "performance-group",
        loadChildren: () =>
          import("../main/modules/group-overview/group-overview.module").then(
            (m) => m.GroupOverviewModule
          ),
      },
      {
        path: "performance-carbon",
        loadChildren: () =>
          import(
            "../main/modules/carbon-performance/carbon-performance.module"
          ).then((m) => m.CarbonPerformanceModule),
      },
      {
        path: "performance-energy",
        loadChildren: () =>
          import(
            "../main/modules/energy-performance/energy-performance.module"
          ).then((m) => m.EnergyPerformanceModule),
      },
      {
        path: "performance-financial",
        loadChildren: () =>
          import(
            "../main/modules/financial-performance/financial-performance.module"
          ).then((m) => m.FinancialPerformanceModule),
      },
      {
        path: "performance-efficiency",
        loadChildren: () =>
          import(
            "../main/modules/efficiency-performance/efficiency-performance.module"
          ).then((m) => m.EfficiencyPerformanceModule),
      },
      {
        path: "performance-power-quality",
        loadChildren: () =>
          import(
            "../main/modules/power-performance/power-performance.module"
          ).then((m) => m.PowerPerformanceModule),
      },
      {
        path: "device-management",
        loadChildren: () =>
          import(
            "../main/modules/device-management/device-management.module"
          ).then((m) => m.DeviceManagementModule),
      },
      {
        path: "global-index",
        loadChildren: () =>
          import("../main/modules/global-index/global-index.module").then(
            (m) => m.GlobalIndexModule
          ),
      },
      {
        path: "live-stream",
        loadChildren: () =>
          import("../main/modules/live-stream/live-stream.module").then(
            (m) => m.LiveStreamModule
          ),
      },
      {
        path: "reporting",
        loadChildren: () =>
          import("../main/modules/report/report.module").then(
            (m) => m.ReportModule
          ),
      },
      {
        path: "live-stream",
        loadChildren: () =>
          import("../main/modules/live-stream/live-stream.module").then(
            (m) => m.LiveStreamModule
          ),
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("../main/modules/contact-us/contact-us.module").then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: "analytics",
        loadChildren: () =>
          import("../main/modules/analytics/analytics.module").then(
            (m) => m.AnalyticsModule,
          ),
      },
    ],
  },
  { path: "**", component: P404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
