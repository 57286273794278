import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CONSTANTS } from "../constants/constants";
import { environment } from "src/environments/environment";
import { catchError, tap } from "rxjs";
import { ResponseCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class ResidentialDashboardService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //This API used for get list of user management
  residentUserList(residenceId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.LIST_USER}${residenceId}`,
        this.httpOptions,
      )
      .pipe(
        tap((res: ResponseCustom) => {
          if (res?.statusCode != 200)
            catchError((e) => {
              throw (
                e.exceptionMessage?.title ||
                e.exceptionMessage?.ResponseException ||
                e.exceptionMessage?.errors?.Description[0] ||
                "Unknown error"
              );
            });
        }),
        catchError((e) => {
          throw (
            e.exceptionMessage?.title ||
            e.exceptionMessage?.ResponseException ||
            e.exceptionMessage?.errors?.Description[0] ||
            "Unknown error"
          );
        }),
      );
  }

  //This API used for get solar live status.
  residentSolarLiveStatus(residenceId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.SOLAR_LIVE_STATUS}${residenceId}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar energy data.
  residentSolarEnergyList(residenceId: number, timeStamEnumId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.LIST_ENERGY}${residenceId}/${timeStamEnumId}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar carbon data.
  residentSolarCarbonList(residenceId: number, timeStamEnumId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.LIST_CARBON}${residenceId}/${timeStamEnumId}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar financial data.
  residentSolarFinancialList(residenceId: number, timeStamEnumId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.LIST_FINANCIAL}${residenceId}/${timeStamEnumId}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar self-sufficience data.
  residentSolarSelfSufficienceList(
    residenceId: number,
    timeStamEnumId: number,
  ) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTDASHBOARD.LIST_SEFL_SUFFICIENCY}${residenceId}/${timeStamEnumId}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
