import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { TabModuleService } from "../../services";
import { TabModule } from "../../models";
import { Subscription } from "rxjs";
import { CONSTANTS } from "../../constants/constants";
@Component({
  selector: "app-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
})
export class TabComponent implements OnInit {
  @ViewChild("refListModule") refListModule: ElementRef;
  tabs: TabModule[];
  tabsSearch: TabModule[];
  searchTab: string = "";
  isNewTab: boolean = false;
  tabActive: TabModule = null;
  loading: boolean = false;
  isOpenDropdown: boolean = false;
  totalDefaultTabShow: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_TAB_SHOW;
  private watchListTab$: Subscription;

  constructor(
    private router: Router,
    private tabModuleService: TabModuleService,
  ) {}

  ngOnDestroy() {
    if (this.watchListTab$) this.watchListTab$.unsubscribe();
  }

  ngOnInit() {
    this.getListTabs();
    this.watchListTab();
  }

  setLoading(status: boolean) {
    this.loading = status;
  }

  getClassTab() {
    if (!this.tabActive) return "";
    return "module-" + this.tabActive.link.replace("/", "");
  }

  // This function used for search tab
  onFilterTab(e) {
    this.searchTab = e.target.value || "";
    this.searchTabModule();
  }

  //This function used for when we change tab.
  changeModule(tab: TabModule) {
    if (tab.active) return;
    this.router.navigateByUrl("/");
    this.router.navigateByUrl(tab.link);
    this.getListTabs(tab);
  }

  //This function used for closed header.
  closeTabHeader(tab: TabModule) {
    if (tab?.link == "/landing") {
      this.isNewTab = false;
    }
    this.tabModuleService.closeTabHeader(tab.link);
  }

  //This function used for open new tab (i.e landing.)
  onNewTab() {
    this.router.navigateByUrl("/landing");
  }

  //This function used for open or closed dropdown.
  handleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
  }

  //This function used for get tabs list i.e open
  private getListTabs(newTab?: TabModule) {
    this.tabs = this.tabModuleService.getListTabs();
    if (newTab && newTab?.link !== "/landing") {
      const indexLanding = this.tabs.findIndex(
        (tab) => tab?.link === newTab?.link,
      );
      const landingItem = this.tabs.splice(indexLanding, 1);
      if (landingItem[0]) this.tabs.splice(1, 0, landingItem[0]);
    }
    this.checkTab();
    this.searchTabModule();
  }

  //Filter tab
  private searchTabModule() {
    if (!this.searchTab?.length) {
      this.tabsSearch = this.tabs.slice(
        CONSTANTS.DEFAULT_KEYS.DEFAULT_TAB_SHOW,
      );
      return;
    }
    const search = this.searchTab.toLocaleLowerCase();
    this.tabsSearch = this.tabs.filter((it) =>
      it.title.toLocaleLowerCase().includes(search),
    );
  }

  private watchListTab() {
    this.watchListTab$ = this.tabModuleService.tabsWatch.subscribe((tabs) => {
      this.tabs = tabs;
      this.checkTab();
      this.searchTabModule();
    });
  }

  // Get active tab and also checked active tab is landing or not.
  private checkTab() {
    const tab = this.tabs.find((it) => it.active);
    this.tabActive = tab;
    this.isNewTab = tab?.link === "/landing";
  }

  checkLandingTabAdded() {
    return this.tabs.find((it) => it.link === "/landing");
  }

  isLanding(): boolean {
    const url = window.location.href;
    if (RegExp(/landing/).exec(url)) return true;
    return false;
  }
}
