import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError, tap } from "rxjs";
import { ListCustom, ResponseCustom, UserForm } from "../models";

@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //This API used for get list of user management
  listUserManagement(data?: ListCustom) {
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.USERMANAGEMENT.LIST_USERMANAGEMENT}`,
        data,
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        tap((res: ResponseCustom) => {
          if (res?.statusCode != 200)
            catchError((e) => {
              throw (
                e.exceptionMessage?.title ||
                e.exceptionMessage?.ResponseException ||
                e.exceptionMessage?.errors?.Description[0] ||
                "Unknown error"
              );
            });
        }),
        catchError((e) => {
          throw (
            e.exceptionMessage?.title ||
            e.exceptionMessage?.ResponseException ||
            e.exceptionMessage?.errors?.Description[0] ||
            "Unknown error"
          );
        }),
      );
  }
  //This API used for Activate / deactivate user.
  activeInactiveUser(id: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.USERMANAGEMENT.ACTIVE_DEACTIVE_USER}/${id}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        tap((res: ResponseCustom) => {
          if (res?.statusCode != 200)
            catchError((e) => {
              throw (
                e.exceptionMessage?.title ||
                e.exceptionMessage?.ResponseException ||
                e.exceptionMessage?.errors?.Description[0] ||
                "Unknown error"
              );
            });
        }),
        catchError((e) => {
          throw (
            e.exceptionMessage?.title ||
            e.exceptionMessage?.ResponseException ||
            e.exceptionMessage?.errors?.Description[0] ||
            "Unknown error"
          );
        }),
      );
  }
  //This API used for create user.
  createUser(data: UserForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.USER.CREATE_USER}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for edit user.
  editUser(id: number, data: UserForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.USER.UPDATE_USER}/${id}`,
        { userId: id, ...data },
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of user management
  getUserRoleList(data?: ListCustom) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.USERMANAGEMENT.GET_ROLE_LIST}`,
        data,
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        tap((res: ResponseCustom) => {
          if (res?.statusCode != 200)
            catchError((e) => {
              throw (
                e.exceptionMessage?.title ||
                e.exceptionMessage?.ResponseException ||
                e.exceptionMessage?.errors?.Description[0] ||
                "Unknown error"
              );
            });
        }),
        catchError((e) => {
          throw (
            e.exceptionMessage?.title ||
            e.exceptionMessage?.ResponseException ||
            e.exceptionMessage?.errors?.Description[0] ||
            "Unknown error"
          );
        }),
      );
  }
}
