export interface PeerPerformanceDetail {
  name: string;
  score: number;
  powerQuality: number;
  efficiency: number;
  energy: number;
  carbon: number;
  financial: number;
  scoreDifference: number;
  powerDifference: number;
  efficiencyDifference: number;
  energyDifference: number;
  carbonDifference: number;
}

export interface SitePerformanceData {
  name: string;
  score: number;
  powerQuality: number;
  efficiency: number;
  energy: number;
  carbon: number;
  financial: number;
  scoreDifference: number;
  powerDifference: number;
  efficiencyDifference: number;
  energyDifference: number;
  carbonDifference: number;
}

export interface MapView {
  name: string;
  location: string;
  locationLatitude: string;
  locationLongitude: string;
  overallPerformanceScore: number;
  energyPerformanceScore: number;
  carbonPerformanceScore: number;
  efficiencyPerformanceScore: number;
  powerQualityPerformanceScore: number;
  scoreDifference: number;
  carbonDifference: number;
  energyDifference: number;
  powerDifference: number;
  efficiencyDifference: number;
}

export interface MapData {
  totalSites: number;
  totalDevices: number;
  mapView: MapView[];
}

export interface RankingAgainstGlobal {
  dateTime: string;
  groupAverage: number;
  baseLine: number;
}

export interface GroupPerformanceDataReq {
  groupId: number;
  timeFrameId: number;
  performanceTypeId: number;
  currencyCode?: string;
}

export interface SitePerformanceDataReq {
  siteId: number;
  timeFrameId: number;
  performanceTypeId: number;
  currencyCode?: string;
}

export interface PerformanceData {
  id: number;
  name: string;
  thisTypeValue: number;
  previousTypeValue: number;
  foreCast: number;
  typeValue: number;
  percentage: number;
  labelXAxis: string;
}

export class DataTablePerformance {
  page: number;
  size: number;
  total: number;
  data: PerformanceData[];
}

export interface GroupPerformanceData {
  siteId: number;
  siteName: string;
  currentValue: number;
  previousValue: number;
  foreCast: number;
  percentage: number;
  labelXAxis: string;
}

export interface SitePerformanceDataTable {
  deviceId: number;
  deviceName: string;
  deviceType: string;
  currentValue: number;
  previousValue: number;
  foreCast: number;
  percentage: number;
  labelXAxis: string;
}

export enum PerformanceType {
  Energy = 1,
  Carbon = 2,
  Financial = 3,
  Effieciency = 4,
  PowerQuality = 5,
}

export const PieChartLimit = 11;
export const PerformanceBreadCrumbs = [
  {
    id: 1,
    title: "Energy",
  },
  {
    id: 2,
    title: "Carbon",
  },
  {
    id: 3,
    title: "Financial",
  },
  {
    id: 4,
    title: "Efficiency",
  },
  {
    id: 5,
    title: "Power Quality",
  },
];

export interface EfficiencyChartData {
  labelXAxis: string;
  value: number;
}

export interface EfficiencySiteTable {
  deviceId: number;
  deviceName: string;
  deviceType: string;
  currentValue: number;
  previousValue: number;
  foreCast: number;
  percentage: number;
  labelXAxis: string;
}

export interface EfficiencyGroupData {
  siteWiseEfficiencyPerformanceData: GroupPerformanceData[];
  timeWiseEfficiencyPerformanceData: EfficiencyChartData[];
}

export interface EfficiencySiteData {
  deviceWiseEfficiencyPerformanceData: EfficiencySiteTable[];
  timeWiseEfficiencyPerformanceData: EfficiencyChartData[];
}

export interface PowerQualityGroupReq {
  groupId: number;
  timeFrameId: number;
}

export interface PowerQualitySiteReq {
  siteId: number;
  timeFrameId: number;
}

export interface PowerQualityGroupData {
  thdi: [
    {
      siteId: number;
      siteName: string;
      currentValue: number;
      previousValue: number;
    },
  ];
  thdv: [
    {
      siteId: number;
      siteName: number;
      currentValue: number;
      previousValue: number;
    },
  ];
  voltageUnbalance: [
    {
      siteId: number;
      siteName: number;
      currentValue: number;
      previousValue: number;
    },
  ];
  currentUnbalance: [
    {
      siteId: number;
      siteName: number;
      currentValue: number;
      previousValue: number;
    },
  ];
}

export interface PowerQualitySiteData {
  thdi: [
    {
      deviceId: number;
      deviceName: string;
      currentValue: number;
      previousValue: number;
    },
  ];
  thdv: [
    {
      deviceId: number;
      deviceName: string;
      currentValue: number;
      previousValue: number;
    },
  ];
  voltageUnbalance: [
    {
      deviceId: number;
      deviceName: string;
      currentValue: number;
      previousValue: number;
    },
  ];
  currentUnbalance: [
    {
      deviceId: number;
      deviceName: string;
      currentValue: number;
      previousValue: number;
    },
  ];
}

export enum PerformanceDropdownSuggession {
  groupId = "groupId",
}

export enum PerformanceDropdown {
  groupId = "groupId",
  countryId = "countryId"
}
