import { PerformanceBreadCrumbs, PerformanceType } from "./performance";

enum MainModule {
  MDM = "MDM",
  USER_MANAGEMENT = "User Management",
  RESIDENT_DASHBOARD = "Resident Dashboard",
  NOTIFICATION = "Notifications",
  ALERT_LOG = "Alert Log",
  DATA_LOG = "Data Log",
  DEVICE_MANAGEMENT = "Device Management",
  GLOBAL_INTELLIGENCE = "Global Index",
  GROUP_OVERVIEW = "Group Overview",
  PERFORMANCE = "Performance",
  LIVE_STREAM = "Live Stream",
  REPORTS = "Reports",
  ANALYTICS = "Analytics",
}

type ChildModule = {
  [key in keyof typeof PerformanceType]: string | undefined;
};

const ChildModules: ChildModule = {
  Energy: PerformanceBreadCrumbs.find(
    (tab) => tab.id === PerformanceType.Energy,
  )?.title,
  Carbon: PerformanceBreadCrumbs.find(
    (tab) => tab.id === PerformanceType.Carbon,
  )?.title,
  Financial: PerformanceBreadCrumbs.find(
    (tab) => tab.id === PerformanceType.Financial,
  )?.title,
  Effieciency: PerformanceBreadCrumbs.find(
    (tab) => tab.id === PerformanceType.Effieciency,
  )?.title,
  PowerQuality: PerformanceBreadCrumbs.find(
    (tab) => tab.id === PerformanceType.PowerQuality,
  )?.title,
};

const generateBreadcrumb = (
  mainModule: MainModule,
  childModule: string = "",
) => ({ mainModule, childModule });

export const generatePerformanceBreadcrumb = (moduleId: number) => {
  return generateBreadcrumb(
    MainModule.PERFORMANCE,
    PerformanceBreadCrumbs.find((tab) => tab.id === moduleId)?.title,
  );
};

export const MDM_BREADCRUMB = (moduleName: string) => {
  return generateBreadcrumb(MainModule.MDM, moduleName);
};

export const DEVICE_BREADCRUMB = (moduleName: string) => {
  return generateBreadcrumb(MainModule.DEVICE_MANAGEMENT, moduleName);
};

export const USER_MANAGEMENT_BREADCRUMB = generateBreadcrumb(
  MainModule.USER_MANAGEMENT,
  "",
);
export const ALERT_LOG_BREADCRUMB = generateBreadcrumb(
  MainModule.ALERT_LOG,
  "",
);
export const DATA_LOG_BREADCRUMB = generateBreadcrumb(MainModule.DATA_LOG, "");
export const LIVE_STREAM_BREADCRUMB = generateBreadcrumb(
  MainModule.LIVE_STREAM,
  "",
);
export const DEVICE_MANAGEMENT_BREADCRUMB = generateBreadcrumb(
  MainModule.DEVICE_MANAGEMENT,
  "",
);
export const GLOBAL_INTELLIGENCE_BREADCRUMB = generateBreadcrumb(
  MainModule.GLOBAL_INTELLIGENCE,
  "",
);
export const GROUP_OVERVIEW_BREADCRUMB = generateBreadcrumb(
  MainModule.GROUP_OVERVIEW,
  "",
);
export const NOTIFICATION_BREADCRUMB = generateBreadcrumb(
  MainModule.NOTIFICATION,
  "",
);
export const RESIDENT_DASHBOARD_BREADCRUMB = generateBreadcrumb(
  MainModule.RESIDENT_DASHBOARD,
  "",
);
export const PERFORMANCE_ENERGY_BREADCRUMB = generateBreadcrumb(
  MainModule.PERFORMANCE,
  ChildModules.Energy,
);
export const PERFORMANCE_CARBON_BREADCRUMB = generateBreadcrumb(
  MainModule.PERFORMANCE,
  ChildModules.Carbon,
);
export const PERFORMANCE_FINANCIAL_BREADCRUMB = generateBreadcrumb(
  MainModule.PERFORMANCE,
  ChildModules.Financial,
);
export const PERFORMANCE_EFFIECIENCY_BREADCRUMB = generateBreadcrumb(
  MainModule.PERFORMANCE,
  ChildModules.Effieciency,
);
export const PERFORMANCE_POWER_QUALITY_BREADCRUMB = generateBreadcrumb(
  MainModule.PERFORMANCE,
  ChildModules.PowerQuality,
);
export const REPORTS = generateBreadcrumb(MainModule.REPORTS, "");

export const ANALYTICS = generateBreadcrumb(MainModule.ANALYTICS, "");

