import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { GroupForm, GroupList } from "../models/group-management";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class GroupManagementService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******************  GROUP MANAGEMENT   ***************** ///

  // This API is used to get All Business Type
  getAllBusinessTypes() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.BUSINESS_TYPE}`,
        { ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used to get All Sector Type
  getSectorType() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.SECTOR_TYPE}`,
        { ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used to get All Size Type
  getPropertySizeType() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.PROPERTY_SIZE_TYPE}`,
        { ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used to get All Business Type
  getBlockNumber() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.BLOCK_NUMBER}`,
        { ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used to get All Property size Type
  getPropertySize(businessSectorTypeId: number, propertySizeTypeId: number) {
    const params = { businessSectorTypeId, propertySizeTypeId };
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.PROPERTY_SIZE}`,
        { params, ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used to get All Tariff Type
  getTariffType(
    size: number = this.itemsPerPage,
    page: number = this.page,
    statecityemirateId?: number
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
      statecityemirateId,
    };
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.TARIFF_TYPE.LIST_TARIFF_TYPE}`,
        { params, ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API is used for get group list
  listGroup(group?: GroupList) {
    const data: ListCustom = group.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.LIST_GROUP}`,
        { ...data, sortByEnabledorDisabled: group.sortByEnabledorDisabled },
        { params: { ...data }, ...this.httpOptions }
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  //This API used for create group.
  createGroup(data: GroupForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.CREATE_GROUP}`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API used for edit group.
  editGroup(id: number, data: GroupForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.UPDATE_GROUP}/${id}`,
        data,
        this.httpOptions
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }

  // This API used for active deactive group
  activeDeactiveGroup(id: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.ACTIVE_DEACTIVE_GROUP}/${id}/${status}`,
        {},
        this.httpOptions
      )
      .pipe(
        catchError((e) => {
          throw e;
        })
      );
  }
}
