<div class="password">
  <div class="password-left">
    <div class="password-info">
      <picture>
        <img src="assets/images/landing/logo-lens-white.svg" alt="Qlens" />
      </picture>
      <h1>
        <span>Visualise,</span>
        <span>Understand,</span>
        <span>Optimise.</span>
      </h1>
      <p>Your next generation energy management platform</p>
    </div>
    <div class="left-help">
      <p>
        Having troubles?
        <a href="#" class="help-text">Get Help</a>
      </p>
    </div>
  </div>
  <div class="password-right">
    <div class="right-wrap">
      <div class="signIn-right-header">
        <h2 [ngClass]="{ 'change-password-title': isChangePassword}">
          {{
          isChangePassword
          ? "Change Password"
          : isSetPassword
          ? "Set Password"
          : "Reset Password"
          }}
        </h2>
      </div>

      <form [formGroup]="form" class="signin-form">
        <app-status-message *ngIf="statusForm?.message" [statusForm]="statusForm"></app-status-message>
        <div class="login-form-group" *ngIf="isChangePassword">
          <label for="email">Current Password<span class="common-star">*</span></label>
          <div class="input-group password-grp" [ngClass]="{ 'validation-border': getError('currentpassword') }">
            <input trim type="password" id="currentpassword" formControlName="currentpassword" class="password-input"
              placeholder="Current Password" [type]="showHideCurrentPassword ? 'text' : 'password'" />
            <img [src]="
                showHideCurrentPassword
                  ? 'assets/images/landing/hide.svg'
                  : 'assets/images/landing/show.svg'
              " (click)="showHideCurrentPass()" alt="hide" />
          </div>
          <div class="input-error" *ngIf="getError('currentpassword')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{
              getError("currentpassword", "required") &&
              Message.CURRENTPASS_REQ
              }}
              {{
              getError("currentpassword", "minlength") &&
              Message.CURRENTPASS_MIN_LENGTH
              }}
            </p>
          </div>
        </div>

        <div class="login-form-group">
          <label for="email">New Password<span class="common-star">*</span></label>
          <div class="input-group password-grp" [ngClass]="{ 'validation-border': getError('password') }">
            <input trim type="password" id="password" formControlName="password" class="password-input"
              placeholder="Password" [type]="showHidePassword ? 'text' : 'password'" />
            <img [src]="
                showHidePassword
                  ? 'assets/images/landing/hide.svg'
                  : 'assets/images/landing/show.svg'
              " (click)="showHidePass()" alt="hide" />
          </div>
          <div class="input-error" *ngIf="getError('password')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{ getError("password", "required") && Message.PASS_REQ }}
              {{
              getError("password", "minlength") &&
              Message.PASS_MIN_LENGTH
              }}
              {{
              getError("password", "notEqualValidator") &&
              Message.CURRENT_NEWPASS_NOT_SAME
              }}
            </p>
          </div>
        </div>

        <div class="login-form-group">
          <label for="email">Confirm New Password<span class="common-star">*</span></label>
          <div class="input-group password-grp" [ngClass]="{ 'validation-border': getError('confirmpassword') }">
            <input trim type="password" id="confirmpassword" formControlName="confirmpassword" class="password-input"
              placeholder="Confirm New Password" [type]="showHideConfirmPassword ? 'text' : 'password'" />
            <img [src]="
                showHideConfirmPassword
                  ? 'assets/images/landing/hide.svg'
                  : 'assets/images/landing/show.svg'
              " (click)="showHideConfirmPass()" alt="hide" />
          </div>
          <div class="input-error" *ngIf="getError('confirmpassword')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{
              getError("confirmpassword", "required") &&
              Message.CONFIRMPASS_REQ
              }}
              {{
              getError("confirmpassword", "minlength") &&
              Message.CONFIRMPASS_MIN_LENGTH
              }}
              {{
              getError("confirmpassword", "confirmedValidator") &&
              Message.NEW_CONFIRMPASS_SAME
              }}
            </p>
          </div>
        </div>
        <button class="signin-btn" (click)="onSubmit()">
          {{ isChangePassword ? "Confirm" : "Submit" }}
        </button>
        <a class="cancel-link" routerLink="/landing"><button *ngIf="isChangePassword">Cancel</button></a>
      </form>
    </div>
    <div class="poweredBy">
      <p>Powered by</p>
      <img src="assets/images/landing/powered-img.svg" alt="infix" />
    </div>
  </div>
</div>