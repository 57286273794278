import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { SiteForm, SiteList } from "../models/group-management";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class SiteManagementService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******************  SITE MANAGEMENT   ***************** ///

  // This API is used for get site list
  listSites(siteData?: SiteList) {
    const data: ListCustom = siteData.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.LIST_SITE}`,
        { ...data, groupIds: siteData.groupIds },
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for create group.
  createSite(data: SiteForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.CREATE_SITE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API used for edit group.
  editSite(id: number, data: SiteForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.UPDATE_SITE}/${id}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API used for active deactive group
  activeDeactiveSite(id: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.ACTIVE_DEACTIVE_SITE}/${id}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
