export interface DeviceAdd {
  userId: number;
  deviceToken: string;
  platformId: number;
}

export interface NotificationData {
  id: number;
  title: string;
  description: string;
  toUserId: number;
  time: string;
  objectTypeId: number;
  objectId: number;
  bellNotificationReadStatusId: number;
  isRead: boolean;
  readTime: string;
  moduleApplicationId: number;
  isConfidential: boolean;
  projectId: number;
  action: number;
  totalMinus: number;
  onlyPushForPlatformId: number;
}

export class DataTableNotification {
  page: number;
  size: number;
  total: number;
  data: NotificationData[];
}

export enum NotificationObjectTypeId {
  SolarDeviceOffline = 1,
  SolarDeviceMonthlyPerformanceSummary = 2,
  SolarDeviceGeneration = 3,
  SolarDeviceSelfSufficiency = 4,
  SolarDeviceCleaning = 5,
  SolarDeviceAnnualPerformanceSummary = 6,
}

export enum CommonSubTabKey {
  LENSLITE = 0,
  LENSPLUS = 1,
}

export enum NotificationModuleApplicationId {
  SolarDevice_Notifications = 1,
  QSenseDevice_Notifications = 2,
}

// -------------------- QSense Notification ---------------------------------------

export class DataTableQSenseNotification {
  page: number;
  size: number;
  total: number;
  data: NotificationData[];
}
