import { INavData } from "@coreui/angular";
import { ModelApplicationId, Module } from "./../main/shared/models/SignalR";

interface INavDataCutom extends INavData {
  blank?: boolean;
  notSave?: boolean;
  modelApplicationId?: number;
  nameMini?: string;
  nameParent?: string;
  nameMix?: string;
  moduleId?: number;
}
export const navItems: INavDataCutom[] = [
  {
    title: true,
    name: "Performance",
    nameMini: "OPS",
    moduleId: Module.Performance,
  },
  {
    name: "Group",
    nameMix: "Group",
    url: "/performance-group",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformanceGroup,
  },
  {
    name: "Energy",
    nameMix: "Energy",
    url: "/performance-energy",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformanceEnergy,
  },
  {
    name: "Carbon",
    nameMix: "Carbon",
    url: "/performance-carbon",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformanceCarbon,
  },
  {
    name: "Efficiency",
    nameMix: "Efficiency",
    url: "/performance-efficiency",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformanceEfficiency,
  },
  {
    name: "Power Quality",
    nameMix: "Power Quality",
    url: "/performance-power-quality",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformancePowerQuality,
  },
  {
    name: "Financial",
    nameMix: "Financial",
    url: "/performance-financial",
    nameParent: "Performance",
    modelApplicationId: ModelApplicationId.PerformanceFinancial,
  },
  {
    title: true,
    name: "Solar",
    nameMini: "Solar",
    moduleId: Module.Solar,
  },
  {
    name: "Live status",
    nameMix: "Live Status",
    url: "/live-status",
    nameParent: "Solar",
    modelApplicationId: ModelApplicationId.LiveStatus,
  },
  {
    name: "Energy",
    nameMix: "Energy",
    url: "/energy",
    nameParent: "Solar",
    modelApplicationId: ModelApplicationId.Energy,
  },
  {
    name: "Carbon",
    nameMix: "Carbon",
    url: "/carbon",
    nameParent: "Solar",
    modelApplicationId: ModelApplicationId.Carbon,
  },
  {
    name: "Financial",
    nameMix: "Financial",
    url: "/financial",
    nameParent: "Solar",
    modelApplicationId: ModelApplicationId.Financial,
  },
  {
    name: "Self-Sufficiency",
    nameMix: "Impact",
    url: "/self-sufficiency",
    nameParent: "Solar",
    modelApplicationId: ModelApplicationId.Impact,
  },
  {
    title: true,
    name: "Communication",
    nameMini: "Communication",
    moduleId: Module.Communication,
  },
  {
    name: "Notifications",
    nameMix: "Notifications",
    url: "/notifications",
    nameParent: "Communication",
    modelApplicationId: ModelApplicationId.Notifications,
  },
  // {
  //   name: "Control Panel",
  //   nameMix: "ControlPanel",
  //   url: "/controlpanel",
  //   nameParent: "Admin",
  //   modelApplicationId: ModelApplicationId.ControlPanel,
  // },
  {
    name: "Alert Log",
    nameMix: "AlertLog",
    url: "/alert-log",
    nameParent: "Communication",
    modelApplicationId: ModelApplicationId.AlertLog,
  },
  {
    name: 'Contact Us',
    nameMix: 'ContactUs',
    url: '/contact-us',
    nameParent: 'Communication',
    modelApplicationId: ModelApplicationId.ContactUs
  },
  {
    title: true,
    name: "Admin",
    nameMini: "Admin",
    moduleId: Module.Admin,
  },
  {
    name: "User Management",
    nameMix: "UserManagement",
    url: "/user/management",
    nameParent: "Admin",
    modelApplicationId: ModelApplicationId.UserManagement,
  },
  {
    name: "MDM",
    nameMix: "MDM",
    url: "/mdm/management",
    nameParent: "Admin",
    modelApplicationId: ModelApplicationId.MDM,
  },
  {
    title: true,
    name: "Technical",
    nameMini: "Technical",
    moduleId: Module.Technical,
  },
  {
    name: "Device Management",
    nameMix: "Device Management",
    url: "/device-management",
    nameParent: "Technical",
    modelApplicationId: ModelApplicationId.DeviceManagement,
  },
  {
    name: "Live Stream",
    nameMix: "Live Stream",
    url: "/live-stream",
    nameParent: "Technical",
    modelApplicationId: ModelApplicationId.LiveStream,
  },
  {
    name: "Data Log",
    nameMix: "Data Log",
    url: "/data-log",
    nameParent: "Technical",
    modelApplicationId: ModelApplicationId.DataLog,
  },

  {
    title: true,
    name: "Intelligence",
    nameMini: "Intelligence",
    moduleId: Module.Intelligence,
  },
  {
    name: "Analytics",
    nameMix: "Analytics",
    url: "/analytics",
    nameParent: "Intelligence",
    modelApplicationId: ModelApplicationId.Analytics,
  },
  {
    name: "Reporting",
    nameMix: "Reporting",
    url: "/reporting",
    nameParent: "Intelligence",
    modelApplicationId: ModelApplicationId.Reporting,
  },
  {
    name: "Tariff Simulator",
    nameMix: "Tariff Simulator",
    url: "/tariff-simulator",
    nameParent: "Intelligence",
    modelApplicationId: ModelApplicationId.TariffSimulator,
  },
  {
    name: "Global Index",
    nameMix: "Global",
    url: "/global-index",
    nameParent: "Intelligence",
    modelApplicationId: ModelApplicationId.Global,
  },
  {
    name: "KPIs",
    nameMix: "KPIs",
    url: "/KPIs",
    nameParent: "Intelligence",
    modelApplicationId: ModelApplicationId.KPIs,
  },
];
