import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrimWhiteSpace } from "./trim-input-directive";
import { OutsideClickDirective } from "./outside-click.directive";

@NgModule({
  declarations: [TrimWhiteSpace, OutsideClickDirective],
  imports: [CommonModule],
  exports: [TrimWhiteSpace, OutsideClickDirective],
})
export class CommonDirectivesModule {}
