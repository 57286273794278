// Staging

export const environment = {
  production: true,
  corsBase: "https://cors-anywhere.herokuapp.com/",
  baseAPIURL: "https://staging-api.qlens.online/api/",
  baseURLSignalR: "https://staging-api.qlens.online",
  googleMapAPIKey: "AIzaSyBTppBR3naYH9p1mBRbL12mVuZHJeMZNfo",
  webURL: "https://staging.qlens.online",
  firebase: {
    apiKey: "AIzaSyA-4H6gNOxH4zHh2uWW8zKFnZyrvnGGDWQ",
    authDomain: "qlens-c375f.firebaseapp.com",
    projectId: "qlens-c375f",
    storageBucket: "qlens-c375f.appspot.com",
    messagingSenderId: "994456242892",
    appId: "1:994456242892:web:ba0d0c16b7154d43b19069",
    measurementId: "G-C076XG50Y5",
  },
};
