import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ResponseCustom } from "src/main/shared/models";
import { ResetPasswordForm } from "src/main/shared/models/auth";
import { AuthenticationService } from "src/main/shared/services";
import { Subject, catchError, finalize, takeUntil, tap } from "rxjs";
import { MESSAGE } from "src/main/shared/constants/message";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  statusForm: { success: boolean; message: string } = {
    success: true,
    message: "",
  };
  isSubmitForm = false;
  showHideCurrentPassword = false;
  showHidePassword = false;
  showHideConfirmPassword = false;
  resetToken: string = null;
  userDetail: ResetPasswordForm;
  isSetPassword = false;
  isChangePassword = false;
  showSpinner = false;
  ngDestroy$: Subject<void> = new Subject<void>();

  readonly Message = MESSAGE;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        currentpassword: [null, [Validators.required, Validators.minLength(8)]],
        password: [null, [Validators.required, Validators.minLength(8)]],
        confirmpassword: [null, [Validators.required, Validators.minLength(8)]],
      },
      {
        validator: this.ConfirmedValidator(
          "password",
          "confirmpassword",
          "currentpassword",
        ),
      },
    );
    this._getToken();
  }

  ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete()
  }

  //This validator used for check both form control have same value or not.
  ConfirmedValidator(
    controlName: string,
    matchingControlName: string,
    currentPasswordControl: string,
  ) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      const currentControl = formGroup.controls[currentPasswordControl];

      // Validation logic for currentpassword and password not being equal
      if (currentControl.value === control.value) {
        control.setErrors({ notEqualValidator: true });
      } else {
        control.setErrors(null);
      }

      // Validation logic for password and confirm password being equal
      if (
        matchingControl.errors &&
        !matchingControl.errors["confirmedValidator"]
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  //This function used for get if any error in form.
  getError(name: string, error?: string, dirtyOrTouched: boolean = true) {
    const control: AbstractControl = this.form.get(name);
    if (!control) return;
    if (error) {
      if (dirtyOrTouched) {
        if (this.isSubmitForm) return control.invalid && control.errors[error];
        return (
          (control.dirty || control.touched) &&
          control.invalid &&
          control.errors[error]
        );
      } else {
        return control.invalid && control.errors[error];
      }
    } else if (dirtyOrTouched) {
      if (this.isSubmitForm) return control.invalid;
      return (control.dirty || control.touched) && control.invalid;
    }
    return control.invalid;
  }

  //This function used for show or hide password.
  showHidePass() {
    this.showHidePassword = !this.showHidePassword;
  }

  //This function used for show or hide confirm password.
  showHideConfirmPass() {
    this.showHideConfirmPassword = !this.showHideConfirmPassword;
  }

  //This function used for show or hide current password.
  showHideCurrentPass() {
    this.showHideCurrentPassword = !this.showHideCurrentPassword;
  }

  //On click of save this function call.
  onSubmit() {
    this.isSubmitForm = true;
    if (!this.form.valid) return;
    const url = window.location.href;
    if (url.match(/change-password/)) {
      this._onChangePassword();
    } else {
      this._onResetPassword();
    }
  }

  // Helper method to update validators
  _updateValidators(
    control: AbstractControl,
    validators: ValidatorFn[],
  ): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  //When we call sent set or reset password API then we received mail with url. So from that url will recived token.
  //If that URL comes from set-password then handle case for set-password else reset-password
  _getToken() {
    const url = window.location.href;
    const currentPasswordControl = this.form.get("currentpassword");
    if (url.match(/change-password/)) {
      this.isChangePassword = true;
      this._updateValidators(currentPasswordControl, [Validators.required]);
    } else if (url.match(/reset-password/)) {
      this.isSetPassword = false;
      this._updateValidators(currentPasswordControl, []);
    } else {
      this.isSetPassword = true;
      this._updateValidators(currentPasswordControl, []);
    }
    if (
      this.router["browserUrlTree"] &&
      this.router["browserUrlTree"]["queryParams"]
    ) {
      let { token } = this.router["browserUrlTree"]["queryParams"];
      if (token && !token) this.router.navigateByUrl("/login");
      this.resetToken = token;
      this._getUserDetailFromToken(this.resetToken);
    }
  }

  //This function used for get user details from token(i.e we received in mail through link)
  _getUserDetailFromToken(token) {
    if (!token || token === "") return;
    this.authenticationService
      .getUserDetailFromToken(token)
      .pipe(
        takeUntil(this.ngDestroy$),
        tap((res: ResponseCustom) => {
          if (res.statusCode !== 200) return;
          this.userDetail = res.result;
        }),
        catchError((error) => {
          this.statusForm = {
            success: false,
            message: error.toString(),
          };
          throw error;
        }),
      )
      .subscribe();
  }

  // This function used for set password.
  _onResetPassword() {
    this.showSpinner = true;
    this.authenticationService
      .resetPassword({
        password: this.form.value.confirmpassword,
        token: this.resetToken,
        userEmailAddress: this.userDetail?.userEmailAddress,
        userId: this.userDetail?.userId,
      })
      .pipe(
        takeUntil(this.ngDestroy$),
        tap((res: ResponseCustom) => {
          if (res.statusCode !== 200) return;
          this.toastr.success("Password reset successfully.");
          this.router.navigateByUrl("/login");
        }),
        catchError((error) => {
          // Handle errors
          this.statusForm = {
            success: false,
            message: error.toString(),
          };
          throw error;
        }),
        finalize(() => {
          this.isSubmitForm = false;
          this.showSpinner = false;
        }),
      )
      .subscribe();
  }

  // This function used for change password.
  _onChangePassword() {
    this.showSpinner = true;
    this.authenticationService
      .changePassword({
        newPassword: this.form.value.confirmpassword,
        currentPassword: this.form.value.currentpassword,
      })
      .pipe(
        takeUntil(this.ngDestroy$),
        tap((res: ResponseCustom) => {
          if (res.statusCode !== 200) return;
          this.toastr.success("Password change successfully.");
          this.router.navigateByUrl("/login");
        }),
        catchError((error) => {
          // Handle errors
          this.statusForm = {
            success: false,
            message: error.toString(),
          };
          throw error;
        }),
        finalize(() => {
          this.isSubmitForm = false;
          this.showSpinner = false;
        }),
      )
      .subscribe();
  }
}
