

import { createReducer, on } from "@ngrx/store"
import { ColumnShowHide, CommonModel, QsenseDataLogList, QsenseDataLogTable, TabModule } from "src/main/shared/models"
import { DropdownResponse } from "src/main/shared/models/analytics"
import { DataLogActions } from "./action-types"

export interface DataLogState {
    groupNames: DropdownResponse,
    siteIdsNames: DropdownResponse,
    deviceNames: DropdownResponse,
    columns: ColumnShowHide[]
    group: CommonModel,
    siteIds: CommonModel,
    deviceId: CommonModel[],
    bottomTab: TabModule[],
    filters: QsenseDataLogList,
    dataList: QsenseDataLogTable,
    isLoaded: boolean,
    error: Partial<Error>
}

export const initialState: DataLogState = {
    groupNames: null,
    siteIdsNames: null,
    deviceNames: null,
    columns: null,
    group: null,
    siteIds: null,
    deviceId: null,
    bottomTab: null,
    filters: null,
    dataList: null,
    isLoaded: false,
    error: null
}

export const dataLogReducers = createReducer(
    initialState,
    on(DataLogActions.setGroup, (state, { group }) => {
        return {
            ...state,
            group
        }
    }),

    on(DataLogActions.setSiteIds, (state, { siteIds }) => {
        return {
            ...state,
            siteIds
        }
    }),

    on(DataLogActions.setDeviceId, (state, { deviceId }) => {
        return {
            ...state,
            deviceId
        }
    }),

    on(DataLogActions.setDataLogFitlers, (state, { filters }) => {
        return {
            ...state,
            filters
        }
    }),

    on(DataLogActions.loadGroupIdsSuccess, (state, { groupIds }) => {
        return {
            ...state,
            groupNames: {
                ...groupIds,
                data: groupIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                    currencyCode: data.currencyCode,
                }))
            }
        }
    }),

    on(DataLogActions.loadGroupIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(DataLogActions.loadSiteIdsSuccess, (state, { siteIds }) => {
        return {
            ...state,
            siteIdsNames: {
                ...siteIds,
                data: siteIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                }))
            }
        }
    }),

    on(DataLogActions.loadSiteIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(DataLogActions.loadDeviceIdsSuccess, (state, { deviceIds }) => {
        return {
            ...state,
            deviceNames: {
                ...deviceIds,
                data: deviceIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.deviceTypeName,
                    macId: data.name
                }))
            }
        }
    }),

    on(DataLogActions.loadDeviceIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(DataLogActions.loadQsenseDataListSuccess, (state, { dataList }) => {
        return {
            ...state,
            dataList,
            isLoaded: true,
            error: null
        }
    }),

    on(DataLogActions.loadQsenseDataListFailure, (state, { error }) => {
        return {
            ...state,
            isLoaded: false,
            error: error
        }
    }),

    on(DataLogActions.setColumns, (state, { columns }) => {
        return {
            ...state,
            columns
        }
    }),

    on(DataLogActions.setBottomTab, (state, { bottomTab }) => {
        return {
            ...state,
            bottomTab
        }
    }),

    on(DataLogActions.resetDataLog, () => {
        return { ...initialState }
    }),

    on(DataLogActions.addRemoveColumn, (state, { columns }) => {
        return {
            ...state,
            columns
        }
    })
)