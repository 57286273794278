import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthenticationService,
  ThemeService,
  UserPermissionService,
} from "src/main/shared/services";
import { DataRealtimeHubService } from "src/main/shared/services/data-realtime-hub.service";
import { Globals } from "./global";
import * as signalR from "@microsoft/signalr";
import { ActionSignalR, SignalRData } from "src/main/shared/models";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FirebaseNotificationService } from "src/main/shared/services/firebase-notification.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Qlens";
  isDarkTheme: boolean;
  hubConnection: signalR.HubConnection;

  constructor(
    public globals: Globals,
    private themeService: ThemeService,
    private router: Router,
    private dataRealtimeHubService: DataRealtimeHubService,
    private userPermissionService: UserPermissionService,
    private messagingService: FirebaseNotificationService,
    private authenticationService: AuthenticationService,
  ) {
    this.isDarkTheme = globals?.isDarkTheme === true;
    if (this.isDarkTheme) {
      this.themeService.setDarkTheme();
    } else {
      this.themeService.setDarkTheme();
    }
  }

  async ngOnInit() {
    const url = window.location.href;
    if (url.match(/reset-password/) || url.match(/set-password/)) {
      const route = url.match(/reset-password/)
        ? "/reset-password"
        : "/set-password";
      const token = url.split("token=")[1];
      this.router.navigate([route], { queryParams: { token } });
    } else if (!url.match(/login/)) {
      this.connectHub();
    }
    await this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.connectNotification();
    // this.message = this.messagingService.currentMessage
  }

  //This is used for connect SignalR
  private async connectHub() {
    try {
      const hubConnection = await this.dataRealtimeHubService.getHubConnect();
      if (!hubConnection) {
        setTimeout(() => {
          this.connectHub();
        }, 1000);
        return;
      }
      this.hubConnection = hubConnection;
      this.listenPermissionUpdate();
    } catch (r) {
      setTimeout(() => {
        this.connectHub();
      }, 1000);
    }
  }

  //This is SignalR event.
  //This is received when any permission update.
  private listenPermissionUpdate() {
    this.hubConnection.on(
      ActionSignalR.RealtimePermission,
      async (data: SignalRData) => {
        if (data.action !== parseInt(ActionSignalR.RealtimePermission, 10))
          return;
        if (!this.userPermissionService.getUserInfo()) return;
        const userInfo = data.data;
        const perOld = this.userPermissionService.getPermissionUser();
        const perNew = userInfo?.permissionIds || [];
        this.userPermissionService.saveUserInfo(userInfo);
        await this.getNewToken();
        if (
          perOld.some((id) => !perNew.includes(id)) ||
          perNew.some((id) => !perOld.includes(id))
        ) {
          Swal.fire({
            title:
              "Your permission has been updated. Please refresh the website.",
            confirmButtonText: "Ok",
          }).then((result) => {
            window.location.reload();
          });
        }
      },
    );
  }
  //Generate Refresh token
  private async getNewToken() {
    let error = null;
    let newToken: { accessToken: string; refreshToken: string };
    this.authenticationService.getNewToken().subscribe({
      next: (res: {
        result: { accessToken: string; refreshToken: string };
      }) => {
        newToken = res.result;
        this.authenticationService.setToken(res.result);
      },
      error: (e) => {
        Swal.fire({
          title: "Session expired. Please login again!",
          confirmButtonText: "Ok",
        }).then((result) => {
          window.location.reload();
          localStorage.clear();
        });
        return Promise.resolve(null);
      },
    });
    if (!newToken) throw new Error(error);
    return {
      token: newToken.accessToken,
      refreshToken: newToken.refreshToken,
    };
  }

  //Get FCM token.
  private connectNotification() {
    if (!this.messagingService.getIsGetPermission()) {
      setTimeout(() => {
        this.connectNotification();
      }, 1000);
      return;
    }

    if (
      !!this.messagingService.getIsGetPermission() &&
      !this.messagingService.getDeviceToken()
    ) {
      return;
    }

    if (!this.userPermissionService.getUserInfo()) {
      setTimeout(() => {
        this.connectNotification();
      }, 1000);
      return;
    }
    this.messagingService
      .addDevice(this.userPermissionService.getUserInfo()?.userId)
      .subscribe({
        next: (res) => {},
        error: (e) => {},
      });
  }
}
