import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../../../main/shared/constants/constants";
import { catchError } from "rxjs/operators";
import {
  ChangePasswordForm,
  ForgotPasswordForm,
  LoginForm,
  ResetPasswordForm,
  SetPassword,
} from "../models/auth";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor(
    private _http: HttpClient,
    private router: Router,
  ) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //Token exist or not.
  isAuthenticated() {
    let token = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.TOKEN_KEY);
    return token && token != null;
  }

  //Get token
  getToken() {
    return localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.TOKEN_KEY) || null;
  }

  //Get refresh token
  getRefreshToken() {
    return (
      localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.REFRESH_TOKEN) || null
    );
  }

  //Set token
  setToken(token: { accessToken: string; refreshToken: string }) {
    localStorage.setItem(
      CONSTANTS.LOCALSTORAGE_KEYS.TOKEN_KEY,
      token?.accessToken,
    );
    localStorage.setItem(
      CONSTANTS.LOCALSTORAGE_KEYS.REFRESH_TOKEN,
      token?.refreshToken,
    );
  }

  //This API used for logout
  async logout() {
    try {
      const refreshToken = this.getRefreshToken();
      if (refreshToken) {
        const url = `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.LOGOUT}`;
        return this._http
          .post(url, { refreshToken })
          .pipe(catchError((e) => e));
      }
    } finally {
      this.router.navigateByUrl("/login");
    }
  }

  //This API used for get new JWT token based on refresh token.
  getNewToken() {
    const url = `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.REFRESH}`;
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) throw new Error("Refresh Token not exist");
    return this._http.post(url, { refreshToken }).pipe(
      catchError((e) => {
        throw e;
      }),
    );
  }

  //This API used for login.
  login(data: LoginForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.LOGIN}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for forgot passowrd.
  forgotPassword(data: ForgotPasswordForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.FORGOT_PASSWORD}`,
        {},
        { ...this.httpOptions, params: { ...data } },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for reset password.
  resetPassword(data: ResetPasswordForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.RESET_PASSWORD}`,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for send password link.
  sendSetPasswordLink(data: SetPassword) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.SEND_SET_PASSWORD_LINK}`,
        {},
        { ...this.httpOptions, params: { ...data } },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get user detail using token.
  getUserDetailFromToken(token: string) {
    const params = {
      token,
    };
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.GET_USER_RESET_PASSWORD_DETAIL}`,
        { ...this.httpOptions, params },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for change password.
  changePassword(data: ChangePasswordForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.AUTHENTICATION.CHANGE_PASSWORD}`,
        JSON.stringify(data),
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
