import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe,
} from "@angular/common";
import { AppRoutingModule } from "./app/app-routing.module";
import { AppComponent } from "./app/app.component";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "src/main/core/interceptors/jwt.interceptor";
import { ErrorInterceptor } from "src/main/core/interceptors/error.interceptor";
import { HttpErrorHandler } from "src/main/shared/error/HttpErrorResponse";
import {
  AuthenticationService,
  ThemeService,
  UserPermissionService,
} from "src/main/shared/services";
import { Globals } from "./app/global";
//Module Imports
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
//Component Import
import { LoginComponent } from "./main/modules/login/login.component";
import { P404Component } from "./main/shared/components/error/404.component";
import { HeaderComponent } from "./main/modules/header/header.component";
import { DefaultLayoutComponent } from "./main/shared/components/default-layout/default-layout.component";
import { SharedModule } from "./main/shared/shared.module";
import { ResetPasswordComponent } from "./main/modules/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./main/modules/forgot-password/forgot-password.component";
import { ExpireLinkComponent } from "./main/modules/expire-link/expire-link.component";
import { environment } from "./environments/environment";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { metaReducers, reducers } from "./app/reducer/app.reducer";
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from "@ngrx/effects";
import { loginReducers } from "./main/modules/login/state/login-reducer";
import { dataLogReducers } from "./main/modules/data-log/state/data-log.reducer";
import { analyticsReducer } from "./main/modules/analytics/state/analytics.reducer";
import { reportReducers } from "./main/modules/report/state/report.reducers";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    P404Component,
    DefaultLayoutComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ExpireLinkComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    StoreModule.forRoot({
      ...reducers,
      login: loginReducers,
      datalog: dataLogReducers,
      analytics: analyticsReducer,
      reporting: reportReducers
    }, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([])
  ],
  providers: [
    Globals,
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
    HttpErrorHandler,
    UserPermissionService,
    AuthenticationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
