export const dark: { [key: string]: string } = {
  "--header-bg-tab-module-item": "#132137",
  "--header-bg-tab-module-item-active": "#1A2B46",
  "--header-color-tab-module-item": "#999999",
  "--header-color-tab-module-item-active": "#FFFFFF",
  "--header-bg-tab-module-item-not-landing": "#172439",
  "--header-bg-tab-module-item-not-landing-active": "#162133",
  "--header-color-tab-module-item-not-landing": "#FFFFFF",
  "--header-color-tab-module-item-not-landing-active": "#45B8AB",
  "--header-icon-create-feedback":
    "url(/../../assets/img/icons/Dark/icon-create-feedback.svg)",
  "--context-menu-bg": "#1A273C",
  "--context-menu-bg-active": "#1e3648",
  "--context-menu-box-shadow": "0px 2px 2px rgba(0, 0, 0, 0.15)",
  "--context-menu-item-color": "#FFFFFF",
  "--context-menu-item-bd": "#4D5E7A",
  "--header-icon-dashboard-toggle-left":
    "url(/../../assets/img/icons/Dark/icon-dashboard-toggle-left.svg)",
  "--header-bg-tab-border": "#000000",
  "--sub-tab-bg": "#1A2B46",
  "--header-bg-sub-tab-module-item": "#1A2B46",
  "--header-bg-sub-tab-module-item-active": "#132137",
  "--header-color-sub-tab-module-item-active": "#23C9AB",
  "--header-bg-sub-tab-module-item-active-border": "#000000",
  "--header-bg-tab-module-item-active-border": "#000000",
};
