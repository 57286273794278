import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import {
  QsensDeviceManagementForm,
  QsensDeviceList,
} from "../models/mdm-device-management";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class QSenseDeviceManagementService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  // Http Options
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private _http: HttpClient) { }

  // ******************  QSENSE DEVICE MANAGEMENT   ***************** ///

  //This API used for get all device type.
  getAllDeviceType(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.DEVICE_TYPE}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all transformer.
  getAllTransformerName(
    parentId: number,
    siteId: number,
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
      parentId,
      siteId,
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.TRANSFORMER}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all main DB.
  getAllMainDbName(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.MAIN_DB}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all sub main DB.
  getAllSubDbName(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.SUB_DB}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all circuit.
  getAllCircuitName(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.CIRCUIT}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all group name.
  getAllGroupName(
    keyword?: string,
    size: number = 20,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.GROUP_NAME}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all site name.
  getAllSiteName(
    groupId: number,
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.SITE_NAME}${groupId}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for create qsens device.
  createQsensDevice(data: QsensDeviceManagementForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.CREATE_DEVICE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This api is used for edit qsens device data
  editQsensDevice(id: number, data: QsensDeviceManagementForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.UPDATE_DEVICE}/${id}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of qsens device
  listQsensDevices(deviceData?: QsensDeviceList) {
    const data: ListCustom = deviceData.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;

    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.LIST_DEVICE}`,
        deviceData.registered ? {
          ...data,
          groupIds: deviceData.groupIds,
          siteIds: deviceData.siteIds,
          deviceIds: deviceData.deviceIds,
          registered: deviceData.registered
        } : {
          ...data,
          groupIds: deviceData.groupIds,
          siteIds: deviceData.siteIds,
          deviceIds: deviceData.deviceIds,
        },
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for Activate / deactivate device.
  activeInactiveQsensDevice(deviceId: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.QSENSDEVICEMANAGEMENT.ACTIVE_DEACTIVE_DEVICE}/${deviceId}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
