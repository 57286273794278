export enum ObjectTypeIdSignalR {
  User = "1",
  Residence = "5",
  SolarDevice = "6",
  SolarLive = "7",
  SolarEnergy = "8",
  SolarCarbon = "9",
  SolarFinancial = "10",
  SolarSelfSufficiency = "11",
  DataLog = "12",
  Notification = "3",
  Group = "13",
  Site = "14",
  QsensDevice = "15",
  QsenseDeviceDataLog = "17",
  Transformer = "16",
  liveStream = "18",
}

export enum ActionSignalRVP {
  Create = 0,
  Update = 1,
  Delete = 2,
  Redelivered = 3,
}

export enum ActionTypeSignalR {
  LEAVE_GROUP = "LeaveGroup",
  JOIN_GROUP = "JoinGroup",
}

export enum GeneralHub {
  RealtimePermission = 1,
  MarkAllAsRead = 2,
  RealTimeData = 3,
  ForceRefreshApp = 4,
  ForceShowMaintenancePage = 5,
}

export class SignalRCRM {
  data: any;
  action: number;
  objectId: number;
  objectTypeId: number;
}

export class SignalRData {
  data: any;
  action: number;
  objectId: number;
  objectTypeId: string;
}

export enum ModelApplicationId {
  UserManagement = 1,
  MDM = 2,
  ControlPanel = 3,
  Notifications = 4,
  Commercial = 5,
  LiveStatus = 6,
  Energy = 7,
  Carbon = 8,
  Financial = 9,
  Impact = 10,
  PerformanceGroup = 11,
  PerformanceEnergy = 12,
  PerformanceCarbon = 13,
  PerformanceEfficiency = 14,
  PerformancePowerQuality = 15,
  PerformanceFinancial = 16,
  Analytics = 17,
  Reporting = 18,
  TariffSimulator = 19,
  Global = 20,
  KPIs = 21,
  DeviceManagement = 22,
  LiveStream = 23,
  DataLog = 24,
  AlertLog = 25,
  ContactUs = 26,
}

export enum CronJobStatus {
  Success = 0,
  Running = 1,
  Fail = 2,
}

export enum Module {
  Admin = 1,
  Communication = 2,
  Solar = 3,
  Performance = 4,
  Intelligence = 5,
  Technical = 6,
}

export enum ActionSignalR {
  Create = "0",
  Update = "1",
  Delete = "2",
  Move = "3",
  Rename = "4",
  LockRow = "5",
  UnlockRow = "6",
  UpdateStatus = "7",
  MarkRead = "8",
  MarkUnRead = "9",
  MarkAllAsRead = "10",
  MarkReadObject = "11",
  ForceRefreshApp = "12",
  ForceShowMaintenancePage = "13",
  RealtimePermission = "14",
  NewNotification = "15",
  ReadNotification = "16",
  DeleteNotification = "17",
  DeleteAllBellNotificationOfObject = "18",
}
