import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { ListCustom, UnitCostForm, UnitCostList } from "../models";

@Injectable({
  providedIn: "root",
})
export class UnitCostService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) { }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******************  UNIT COST   ***************** ///

  //This API used for get list of unit cost
  listUnitCost(unitCost?: UnitCostList) {
    const data: ListCustom = unitCost.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;

    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.UNITCOST.LIST_UNITCOST}`,
        {
          ...data,
          stateCityEmirateId: unitCost.stateCityEmirateId,
          tariffType: unitCost.tariffType,
        },
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  addUnitCost(data: UnitCostForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.UNITCOST.ADD_UNITCOST}`,
        {
          ...data,
          stateCityEmirateId: data?.stateCityEmirateId ? data?.stateCityEmirateId : 0
        },
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  updateUnitCost(id: number, data: UnitCostForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.UNITCOST.UPDATE_UNIT_COST}/${id}`,
        {
          ...data,
          stateCityEmirateId: data?.stateCityEmirateId ? data?.stateCityEmirateId : 0
        },
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
  //This API used for Activate / Inactive Unit cost.
  activeInactiveUnitCost(energypriceslabId: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.UNITCOST.ACTIVE_DEACTIVE_UNITCOST}/${energypriceslabId}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
