import { CommonMapOption } from "./tab-module";

export enum ProductType {
  QLensLite = 1,
  QLensPlus = 2,
  Operations = 3,
}

export enum UserEKeyField {
  fullName = "fullName",
  email = "email",
  productTypeEnumId = "productTypeEnumId",
  residence = "residence",
  siteName = "siteName",
  roles = "roles",
  status = "status",
}

export interface UserManagementData {
  userId: number;
  fullName: string;
  email: string;
  lastLogin: Date;
  modifyDate: string;
  phoneNumber: number;
  productTypeEnumId: number;
  isPasswordCreated: boolean;
  status: boolean;
  roles: CommonMapOption[];
  residence: CommonMapOption;
  group: CommonMapOption;
  sites: CommonMapOption[];
  createDate: Date;
}

export const UserType = [
  {
    id: ProductType.QLensLite,
    label: "Residential",
  },
  {
    id: ProductType.QLensPlus,
    label: "Group",
  },
  {
    id: ProductType.Operations,
    label: "Operations",
  },
];

export interface UserForm {
  userId: number;
  fullName: string;
  email: string;
  phoneNumber: string;
  status: boolean;
  productTypeEnumId: number;
  residenceId: number;
  roleIds: Array<number>;
}

export enum UserDropdownSuggession {
  residenceId = "residenceId",
  groupId = "groupId",
  siteIds = "siteIds",
}

export class UserDataTableProject {
  page: number;
  size: number;
  total: number;
  data: UserManagementData[];
}
export const UserRoleIndex = {
  [ProductType.QLensLite]: 0,
  [ProductType.QLensPlus]: 1,
  [ProductType.Operations]: 2,
};

export const UserRole = [
  {
    [ProductType.QLensLite]: ProductType.QLensLite,
    roleIds: [2],
  },
  {
    [ProductType.QLensPlus]: ProductType.QLensPlus,
    roleIds: [4, 5, 6],
  },
  {
    [ProductType.Operations]: ProductType.Operations,
    roleIds: [1, 3, 6],
  },
];

export interface Permissions {
  id: number;
  order: number;
  name: string;
  moduleApplicationId: number;
  moduleApplicationName: string;
}

export interface UserRoleData {
  id: number;
  name: string;
  isActive: boolean;
  isDisabled: boolean;
  isChecked: boolean;
  permissions: Permissions[];
  roleGroupId: number;
}

export class UserRoleDataTable {
  page: number;
  size: number;
  total: number;
  data: UserRoleData[];
}

export enum UserRoleGroup {
  Residence = 1,
  Group = 2,
  Operations = 3,
}
