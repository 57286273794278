import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
  ElementRef,
} from "@angular/core";

@Directive({
  selector: "[outsideClick]",
})
export class OutsideClickDirective {
  //When we click outside element then outsideClick event will emit.
  @Output() outsideClick = new EventEmitter();

  constructor(private _elementRef: ElementRef) {}

  @HostListener("document:click", ["$event", "$event.target"])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    // This code is for targer value outer side
    if (this._elementRef.nativeElement.parentElement.id === "parentNode") {
      this._elementRef.nativeElement =
        this._elementRef.nativeElement.parentElement;
    }
    const clickedInside =
      this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.outsideClick.emit(event);
    }
  }
}
