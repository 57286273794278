import { createReducer, on } from "@ngrx/store";
import {
  AnalyticsFilters,
  Country,
  Data,
  DeviceDetail,
  DropdownResponse,
  ParameterFilters,
  TIME_FRAME_HOUR_LIST,
} from "src/main/shared/models/analytics";
import { AnalyticsActions } from "./action-types";
import { CommonModel, Series, TimeStamEnum } from "src/main/shared/models";
import { parameterChartData } from "./analytics.selector";

export const defaultFilters: AnalyticsFilters = {
  performanceFilters: {
    id: "11",
    value: "Q-Performance",
  },
  paramFilters: {
    paramFilter: [
      {
        id: "1",
        value: "kVA",
      },
    ],
  },
  parameterFilters: {
    paramFilter: {
      id: "",
      value: "",
    },
    phaseFilter: "",
  },
  compareFilters: {
    macAddresses: null,
    siteIds: null,
    filters: null,
    timeFrameId: 1,
    dateRange: {
      startDate: "",
      endDate: "",
    },
    timeStamp: null,
  },
  timeStamp: TimeStamEnum[0],
  timeHr: TIME_FRAME_HOUR_LIST[0],
  dateRange: {
    startDate: "",
    endDate: "",
  },
};

export interface ChartData {
  data: Data[];
  deviceDetail: DeviceDetail;
}

export interface ParameterChartData {
  kVA: {
    currentChartData: ChartData;
    seriesLegend: Series
    compareChartData: ChartData;
    compareSeriesLegend: Series
  };
  KW: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  kVAR: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  PF: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  THDI: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  THDV: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  Voltage: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
  Amps: {
    currentChartData: ChartData;
    seriesLegend: Series;
    compareChartData: ChartData;
    compareSeriesLegend: Series;
  };
}

export interface AnalyticsState {
  groupNames: DropdownResponse;
  siteIdsNames: DropdownResponse;
  deviceNames: DropdownResponse;
  compareDeviceNames: DropdownResponse;
  countries: Country[];
  activeTabNo: number;
  group: CommonModel;
  siteIds: CommonModel;
  deviceId: CommonModel;
  currency: CommonModel;
  currentSeriesLegend: Series;
  compareSeriesLegend: Series;
  currentChartData: ChartData;
  compareChartData: ChartData;
  parameterChartData: ParameterChartData[];
  currentFlag: boolean;
  compareFlag: boolean;
  filters: AnalyticsFilters;
  error: Partial<Error>;
}

export const initialState: AnalyticsState = {
  groupNames: null,
  siteIdsNames: null,
  deviceNames: null,
  compareDeviceNames: null,
  countries: null,
  activeTabNo: 0,
  group: null,
  siteIds: null,
  deviceId: null,
  currency: null,
  currentSeriesLegend: null,
  compareSeriesLegend: null,
  currentChartData: undefined,
  compareChartData: undefined,
  parameterChartData: null,
  currentFlag: false,
  compareFlag: false,
  filters: defaultFilters,
  error: undefined,
};

export const analyticsReducer = createReducer(
  initialState,
  on(AnalyticsActions.loadGroupIdsSuccess, (state, { groupIds }) => {
    return {
      ...state,
      groupNames: {
        ...groupIds,
        data: groupIds?.data.map((data: any) => ({
          id: data.id,
          label: data.name,
          currencyCode: data.currencyCode,
        })),
      },
    };
  }),

  on(AnalyticsActions.loadGroupIdsFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(AnalyticsActions.loadSiteIdsSuccess, (state, { siteIds }) => {
    return {
      ...state,
      siteIdsNames: {
        ...siteIds,
        data: siteIds?.data.map((data: any) => ({
          id: data.id,
          label: data.name,
        })),
      },
    };
  }),
  on(AnalyticsActions.loadSiteIdsFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(AnalyticsActions.loadDeviceIdsSuccess, (state, { deviceIds }) => {
    return {
      ...state,
      deviceNames: {
        ...deviceIds,
        data: deviceIds?.data.map((data: any) => ({
          id: data.id,
          label: data.deviceTypeName,
        })),
      },
    };
  }),

  on(AnalyticsActions.loadDeviceIdsFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(
    AnalyticsActions.loadCompareDeviceIdsSuccess,
    (state, { compareDeviceNames }) => {
      return {
        ...state,
        compareDeviceNames: {
          ...compareDeviceNames,
          data: compareDeviceNames?.data.map((data: any) => ({
            id: data.id,
            label: data.deviceTypeName,
          })),
        },
      };
    }
  ),

  on(AnalyticsActions.loadCompareDeviceIdsFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(AnalyticsActions.loadAllCountriesSuccess, (state, { countries }) => {
    return {
      ...state,
      countries: countries?.result.map((item) => ({
        id: item.id,
        label: `${item.name} - ${item.currency} - ${item.currencySybmol}`,
      })),
    };
  }),

  on(AnalyticsActions.loadAllCountriesFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(AnalyticsActions.setGroup, (state, { group }) => {
    return {
      ...state,
      group: group,
    };
  }),

  on(AnalyticsActions.setSiteIds, (state, { siteIds }) => {
    return {
      ...state,
      siteIds: siteIds,
    };
  }),

  on(AnalyticsActions.setDeviceId, (state, { deviceId }) => {
    return {
      ...state,
      deviceId: deviceId,
    };
  }),

  on(AnalyticsActions.setCurrency, (state, { currency }) => {
    return {
      ...state,
      currency: currency,
    };
  }),

  on(
    AnalyticsActions.loadCurrentAnalyticsSuccess,
    (state, { analyticsData }) => {
      return {
        ...state,
        currentFlag: true,
        currentChartData: analyticsData,
      };
    }
  ),

  on(
    AnalyticsActions.loadParameterAnalyticsSuccess,
    (state, { analyticsData, parameterTypeIds }) => {
      const selectedParameters = parameterTypeIds.map((id) =>
        ParameterFilters.find((param) => param.id === id.toString())
      );
      const validParameters = selectedParameters.filter(Boolean);
      if (validParameters.length === 0) {
        console.warn(`No parameter found for ids: ${parameterTypeIds}`);
        return state;
      }
      const updatedParameterChartData = validParameters.reduce((acc: any, selectedParameter: any) => {
        const parameterData = analyticsData.data[selectedParameter.key];
        const parameterDeviceData = analyticsData.deviceDetail[selectedParameter.key];

        if (parameterData) {
          acc[selectedParameter.key] = {
            ...state.parameterChartData[selectedParameter.key],
            ...acc[selectedParameter.key],
            currentChartData: {
              data: parameterData,
              deviceDetail: parameterDeviceData,
            },
          };
        }
        return acc;
      }, {});

      return {
        ...state,
        currentFlag: true,
        parameterChartData: updatedParameterChartData,
      };
    }
  ),

  on(
    AnalyticsActions.loadCompareParameterAnalyticsSuccess,
    (state, { analyticsData, parameterTypeIds }) => {
      const selectedParameters = parameterTypeIds.map((id) =>
        ParameterFilters.find((param) => param.id === id.toString())
      );
      const validParameters = selectedParameters.filter(Boolean);
      if (validParameters.length === 0) {
        console.warn(`No parameter found for ids: ${parameterTypeIds}`);
        return state;
      }

      const updatedParameterChartData = validParameters.reduce((acc: any, selectedParameter: any) => {
        const parameterData = analyticsData.data[selectedParameter.key];
        const parameterDeviceData = analyticsData.deviceDetail[selectedParameter.key];

        if (parameterData) {
          acc[selectedParameter.key] = {
            ...state.parameterChartData[selectedParameter.key],
            ...acc[selectedParameter.key],
            compareChartData: {
              data: parameterData,
              deviceDetail: parameterDeviceData,
            },
          };
        }
        return acc;
      }, {});

      return {
        ...state,
        compareFlag: true,
        parameterChartData: updatedParameterChartData,
      };
    }
  ),

  on(AnalyticsActions.loadParameterAnalyticsFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),

  on(
    AnalyticsActions.loadCompareAnalyticsSuccess,
    (state, { analyticsData }) => {
      return {
        ...state,
        compareFlag: true,
        compareChartData: analyticsData,
      };
    }
  ),

  on(AnalyticsActions.setAnalyticsFilters, (state, { filters }) => {
    return {
      ...state,
      filters: filters,
    };
  }),

  on(AnalyticsActions.setCurrentSeriesLegend, (state, { series }) => {
    return {
      ...state,
      currentSeriesLegend: series,
    };
  }),

  on(AnalyticsActions.setCompareSeriesLegend, (state, { series }) => {
    return {
      ...state,
      compareSeriesLegend: series,
    };
  }),

  on(AnalyticsActions.setActiveFilterTab, (state, { activeTabNo }) => {
    return {
      ...state,
      activeTabNo,
    };
  }),

  on(AnalyticsActions.removeSiteIds, (state) => {
    return {
      ...state,
      siteIdsNames: null,
    };
  }),

  on(AnalyticsActions.removeDeviceIds, (state) => {
    return {
      ...state,
      deviceNames: null,
    };
  }),

  on(AnalyticsActions.removeCurrentChartData, (state) => {
    return {
      ...state,
      currentChartData: null,
    };
  }),

  on(AnalyticsActions.removeCompareChartData, (state) => {
    return {
      ...state,
      compareChartData: null,
    };
  }),

  on(AnalyticsActions.removeSeriesLegend, (state) => {
    return {
      ...state,
      currentSeriesLegend: null,
    };
  }),

  on(AnalyticsActions.removeCompareSeriesLegend, (state) => {
    return {
      ...state,
      compareSeriesLegend: null,
    };
  }),

  on(AnalyticsActions.removeSelectedDeviceId, (state) => {
    return {
      ...state,
      deviceId: null,
    };
  }),

  on(AnalyticsActions.removeSelectedSiteId, (state) => {
    return {
      ...state,
      siteIds: null,
    };
  }),

  on(AnalyticsActions.setCurrentDataLoaded, (state, { currentFlag }) => {
    return {
      ...state,
      currentFlag,
    };
  }),

  on(AnalyticsActions.setCompareDataLoaded, (state, { compareFlag }) => {
    return {
      ...state,
      compareFlag,
    };
  }),

  on(AnalyticsActions.setParameterDataLoaded, (state, { flag }) => {
    return {
      ...state,
      flag,
    };
  }),

  on(AnalyticsActions.resetAnalyticsState, () => {
    return {
      ...initialState,
    };
  }),

  on(AnalyticsActions.removeCompareFilter, (state) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        compareFilters: null,
      },
    };
  }),

  on(AnalyticsActions.removeParameterChart, (state, { title, reset }) => {
    if (reset) {
      const resetParameterChartData = Object.keys(state.parameterChartData).reduce((acc, key) => {
        acc[key] = {
          ...state.parameterChartData[key],
          compareChartData: null,
          compareSeriesLegend: null,
        };
        return acc;
      }, { ...state.parameterChartData });

      return {
        ...state,
        parameterChartData: resetParameterChartData,
      };
    }

    // For non-reset case, remove the specific key's compare data
    const selectedId = ParameterFilters.filter(dt => dt.value === title).map(dt => dt.key)[0];
    const updatedParameterChartData = { ...state.parameterChartData };
    delete updatedParameterChartData[selectedId];

    return {
      ...state,
      parameterChartData: updatedParameterChartData,
    };
  }),


  on(AnalyticsActions.setParameterSeriesLegend, (state, { filter }) => {
    return {
      ...state,
      parameterChartData: {
        ...state.parameterChartData,
        ...filter.paramFilter.reduce((acc, filterItem) => {
          const parameterId = filterItem.id;
          const selectedSeries = ParameterFilters
            .filter(dt => dt.id == parameterId)
            .map(dt => ({
              key: dt.key,
              data: dt.seriesLegends
            }))[0];
          if (selectedSeries && selectedSeries.key) {
            acc[selectedSeries.key] = {
              seriesLegend: selectedSeries.data
            };
          } else {
            console.warn(`No valid series found for parameterId: ${parameterId}`);
          }

          return acc;
        }, {})
      }
    };
  }),

  on(AnalyticsActions.setParameterCompareSeriesLegend, (state, { filter }) => {
    return {
      ...state,
      parameterChartData: {
        ...state.parameterChartData,
        ...filter.paramFilter.reduce((acc, filterItem) => {
          const parameterId = filterItem.id;
          const selectedSeries = ParameterFilters
            .filter(dt => dt.id == parameterId)
            .map(dt => ({
              key: dt.key,
              data: dt.compareSeriesLegends
            }))[0]
          if (selectedSeries && selectedSeries.key) {
            acc[selectedSeries.key] = {
              ...state.parameterChartData[selectedSeries.key],
              compareSeriesLegend: selectedSeries.data
            }
          } else {
            console.warn(`No valid series found for parameterId: ${parameterId}`);
          }
          return acc;
        }, {})
      }
    }
  })
);

