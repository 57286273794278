import { createAction, props } from "@ngrx/store";
import { LoginForm } from "src/main/shared/models/auth";


const enum loginActionTypes {
    USER_LOGIN = "[Login Page] User Login",
    USER_LOGOUT = "[Top Menu] User Logout"
}

export const login = createAction(
    loginActionTypes.USER_LOGIN,
    props<{ user: LoginForm }>()
)


export const logout = createAction(
    loginActionTypes.USER_LOGOUT,
)
