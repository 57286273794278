import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { TransformerForm } from "../models/group-management";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class TransformerService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) { }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******************  TRANSFORMER MANAGEMENT   ***************** ///

  //This API used for get list of transformer.
  listTransformer(params?: ListCustom, data?: { siteId: number }) {
    params["sortedField"] = params["sortedField"]
      ? params["sortedField"]
      : "modifyDate";
    params["isDescending"] =
      params["isDescending"] != undefined ? params["isDescending"] : true;
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.LIST_TRANSFORMER}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get detail of transformer.
  getTransformerDetail(id: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.GET_TRANSFORMER}/${id}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for create transformer.
  createTransformer(data: TransformerForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.CREATE_TRANSFORMER}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for delete transformer.
  deleteTransformer(id) {
    return this._http
      .delete(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.DELETE_TRANSFORMER}/${id?.id}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for edit transformer.
  editTransformer(id: number, data: TransformerForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.GROUPMANAGEMENT.UPDATE_TRANSFORMER}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
