import { Component, Input } from "@angular/core";

@Component({
  selector: "app-status-message",
  templateUrl: "./status-message.component.html",
  styleUrls: ["./status-message.component.scss"],
})
export class StatusMessageComponent {
  @Input() statusForm: { success: boolean; message: string };
}
