import { Series } from "./chart";

export interface LiveStreamFilterParams {
  groupId: number;
  siteId: number;
  macAddress: string;
}

export interface LiveStreamPhase {
  labelXAxis: string;
  value: number;
  full?: number;
}

export interface LiveStreamList {
  status: boolean;
  lastSyncDatetime: Date;
  powerFactor: LiveStreamPhase[];
  amps: LiveStreamPhase[];
  volts: LiveStreamPhase[];
  kW: LiveStreamPhase[];
  kVA: LiveStreamPhase[];
  kVAr: LiveStreamPhase[];
  thdi: LiveStreamPhase[];
  thdv: LiveStreamPhase[];
  voltageUnbalance: LiveStreamPhase[];
  currentUnbalance: LiveStreamPhase[];
}

export enum LiveStreamDropdownSuggession {
  macAddress = "macAddress",
  siteId = "siteId",
  groupId = "groupId",
}

export enum LiveStreamChartColor {
  phase1 = "#6B71EE",
  phase2 = "#C471FF",
  phase3 = "#27FCE9",
}

export const LiveStreamSeries: Series[] = [
  {
    name: "L1",
    key: "value",
    color: LiveStreamChartColor.phase1,
    active: true,
  },
  {
    name: "L2",
    key: "value",
    color: LiveStreamChartColor.phase2,
    active: true,
  },
  {
    name: "L3",
    key: "value",
    color: LiveStreamChartColor.phase3,
    active: true,
  },
];

export const LiveStreamDonutColors = ["#6B71EE", "#C471FF", "#27FCE9"];

export const LiveCurrentColors = ["#28C3F9", "#211C45"];

export const LiveVoltageColors = ["#EFC36C", "#211C45"];
