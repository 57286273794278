import { ListCustom } from "./response-custom";

export interface UnitCostBase {
  energyPriceSlabId: number;
  tariffName: string;
  cityName: string;
  countryId: string;
  stateCityEmirateId: number;
  propertyType: number;
  ownershipType: number;
  fromDate: Date;
  toDate: Date;
  isActive: boolean;
  slab: SlabData[];
  countryName: string;
  appliedLevel: string;
  stateId: number;
  stateName: string;
  cityId: number;
}

export interface SlabData {
  energyPriceSlabId: number;
  minKWHPerMonth: number;
  maxKWHPerMonth: number;
  kwhPrice: number;
  above: boolean;
}

export interface UnitCostData extends UnitCostBase {
  previous: UnitCostBase[];
  untilNow: boolean;
  hasFuelSurcharge: boolean;
  fuelSurcharge: number;
  cityName: string;
}

export class DataTableUnitCost {
  page: number;
  size: number;
  total: number;
  data: UnitCostData[];
}

export interface UnitCostForm {
  id: number;
  tariffName: string;
  stateCityEmirateId: number;
  propertyType: number;
  startDate: string;
  endDate: string;
  isActive: boolean;
  untilNow: boolean;
}

export enum UnitCostDropdownSuggession {
  stateCityEmirateId = "stateCityEmirateId",
  state = "state",
  propertyType = "propertyType",
  countryId = "countryId",
  city = "city",
}

export const Tariff_Country_SubState_City_Map = {
  "United Arab Emirates": false,
  Bahrain: false,
  Kuwait: false,
  Oman: false,
  Qatar: false,
  "Saudi Arabia": false,
  Philippines: true,
};

export const showSubStateCity = (countryCode) => {
  if (Tariff_Country_SubState_City_Map.hasOwnProperty(countryCode?.label)) {
    return Tariff_Country_SubState_City_Map[countryCode?.label];
  } else {
    return false;
  }
};

export const TarrifType = [
  {
    id: 1,
    label: "Residential-Citizen",
  },
  {
    id: 2,
    label: "Residential-Resident",
  },
  {
    id: 3,
    label: "Commercial-Citizen",
  },
  {
    id: 4,
    label: "Commercial-Resident",
  },
  {
    id: 5,
    label: "Industrial-Citizen",
  },
  {
    id: 6,
    label: "Industrial-Resident",
  },
];

export interface UnitCostList {
  data?: ListCustom;
  emiratesId?: number;
}

export enum UnitCostEKeyField {
  propertyType = "propertyType",
  stateCityEmirateId = "stateCityEmirateId",
  countryId = "countryId",
  fromDate = "fromDate",
  toDate = "toDate",
}
export enum PropertyType {
  Residential_Citizen = 1,
  Residential_Resident = 2,
  Commercial_Citizen = 3,
  Commercial_Resident = 4,
  Industrial_Citizen = 5,
  Industrial_Resident = 6,
}
export interface Slab {
  minKWHPerMonth: number;
  maxKWHPerMonth: number;
  kwhPrice: number;
}
export enum UnitCostStatusType {
  Active = "Active",
  Inactive = "Inactive",
}

export function getPropertyType(propertyType: number): string {
  if (propertyType === PropertyType.Residential_Citizen) {
    return "Residential-Citizen";
  } else if (propertyType === PropertyType.Residential_Resident) {
    return "Residential-Resident";
  } else if (propertyType === PropertyType.Commercial_Citizen) {
    return "Commercial-Citizen";
  } else if (propertyType === PropertyType.Commercial_Resident) {
    return "Commercial-Resident";
  } else if (propertyType === PropertyType.Industrial_Citizen) {
    return "Industrial-Citizen";
  } else if (propertyType === PropertyType.Industrial_Resident) {
    return "Industrial-Resident";
  } else {
    return "";
  }
}

const PropertyTypes = [
  {
    id: 11,
    value: 1,
    customerType: 1,
    tariffType: 1,
    label: PropertyType.Residential_Citizen,
  },
  {
    id: 12,
    value: 2,
    customerType: 2,
    tariffType: 1,
    label: PropertyType.Residential_Resident,
  },
  {
    id: 21,
    value: 3,
    customerType: 1,
    tariffType: 2,
    label: PropertyType.Commercial_Citizen,
  },
  {
    id: 22,
    value: 4,
    customerType: 2,
    tariffType: 2,
    label: PropertyType.Commercial_Resident,
  },
  {
    id: 31,
    value: 5,
    customerType: 1,
    tariffType: 3,
    label: PropertyType.Industrial_Citizen,
  },
  {
    id: 32,
    value: 6,
    customerType: 2,
    tariffType: 3,
    label: PropertyType.Industrial_Resident,
  },
];

export const setPropertyType = (customerTypeId: number, tariffTypeId: number) =>
  PropertyTypes.filter(
    (data: any) => data.id === +`${tariffTypeId}${customerTypeId}`
  )[0].value;

export const setCustomerType = (propertyType: number) =>
  PropertyTypes.filter((data) => data.value === propertyType)[0].customerType;

export const setTariffType = (propertyType: number) =>
  PropertyTypes.filter((data) => data.value === propertyType)[0].tariffType;

export const CustomerType = [
  {
    id: 1,
    label: "Citizen",
    active: true,
  },
  {
    id: 2,
    label: "Resident",
    active: false,
  },
];

export const TariffType = [
  {
    id: 1,
    label: "Residential",
    active: true,
  },
  {
    id: 2,
    label: "Commercial",
    active: false,
  },
  {
    id: 3,
    label: "Industrial",
    active: false,
  },
];

export interface UnitCostList {
  data?: ListCustom;
  stateCityEmirateId: number;
  tariffType?: number;
}

export const APPLIED_LEVEL_LIST = [
  {
    id: 1,
    label: "Country",
    active: true,
  },
  {
    id: 2,
    label: "State",
    active: true,
  },
  {
    id: 3,
    label: "City",
    active: true,
  },
];

export const getCustomerTariffByProperty = (propertyId) => {
  return PropertyTypes.find((p) => p.value === propertyId);
};
