import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";
import { GlobalIndexFilterParams } from "../models";

@Injectable({
  providedIn: "root",
})
export class GlobalIndexService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private _http: HttpClient) {}

  //This API used for get global intelligence map details.

  getGlobalIntelligenceDetails(data: GlobalIndexFilterParams) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.GLOBAL_INTELLIGENCE.LIST}`,
        { ...data },
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
