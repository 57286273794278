<div
  class="app-list-tab-module"
  [ngClass]="{
    'module-tab-header': !isLanding(),
    'landing-tab-header': isLanding()
  }"
>
  <div class="box-list-tab">
    <div class="list list-main" #refListModule>
      <ul (outsideClick)="isOpenDropdown = false">
        <ng-container *ngFor="let tab of tabs || []; let i = index">
          <li
            *ngIf="i < totalDefaultTabShow"
            class="list-tab"
            [ngClass]="{ active: tab.active }"
            (click)="changeModule(tab)"
          >
            <ng-container *ngIf="tab.title !== 'New Tab'">
              <span>{{ tab.title }}</span>
              <span
                class="tab-img"
                *ngIf="!(isNewTab && tabs?.length === 1)"
                (click)="$event.stopPropagation(); closeTabHeader(tab)"
              >
                <img src="assets/images/cancel.svg" alt="cancel" />
              </span>
            </ng-container>
            <ng-container *ngIf="tab.title === 'New Tab'">
              <span class="home-img"></span>
            </ng-container>
          </li>
        </ng-container>
        <li class="add" *ngIf="!checkLandingTabAdded()">
          <button class="add-btn" [disabled]="isNewTab" (click)="onNewTab()">
            <img src="assets/images/add.svg" alt="add" />
          </button>
        </li>
        <li class="ellipsis" *ngIf="tabs.length > totalDefaultTabShow">
          <button
            class="elipsis-dropdown"
            (click)="handleDropdown()"
            [ngClass]="{ 'elipsis-open': isOpenDropdown }"
          >
            <img src="assets/images/down.svg" alt="down" />
          </button>
          <ul class="tab-dropdown-menu common-scrollbar" *ngIf="isOpenDropdown">
            <!-- <li class="tab-search">
              <div class="form-group">
                <img src="assets/images/search.svg" alt="Search">
                <input type="text" [value]="searchTab" name="searchTab" placeholder="Search tabs"
                  (input)="onFilterTab($event)" />
              </div>
            </li> -->
            <li
              class="menu-list"
              *ngFor="let tab of tabsSearch || []"
              [ngClass]="{ active: tab.active }"
              (click)="changeModule(tab)"
            >
              <ng-container *ngIf="tab.title !== 'New Tab'">
                <a href="javascript:;">{{ tab.title }}</a>
                <button (click)="$event.stopPropagation(); closeTabHeader(tab)">
                  <img src="assets/images/cancel.svg" alt="cancel" />
                </button>
              </ng-container>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
