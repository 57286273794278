<div class="app-body">
  <!-- Main content -->
  <app-header class="w-100"></app-header>
  <main class="main">
    <div class="container-fluid" style="height: calc(var(--h100vh) - 40px)">
      <router-outlet></router-outlet>
    </div>
    <!-- /.conainer-fluid -->
  </main>
</div>
