<app-loader [showSpinner]="showSpinner"></app-loader>
<header
  class="header"
  [ngClass]="{ 'module-header': !isLanding(), 'landing-header': isLanding() }"
>
  <div class="container">
    <div class="header-wrap">
      <div class="header-left">
        <div class="logo-wrap">
          <a class="main-logo" href="">
            <img [src]="getProductImg()" alt="logo" />
          </a>
        </div>
        <div>
          <app-tab></app-tab>
        </div>
      </div>
      <div class="header-search">
        <ul class="header-right-list">
          <li
            class="header-group-label"
            *ngIf="this.user.productTypeEnumId !== ProductType.Operations"
          >
            <img src="assets/images/group-header.svg" alt="group-name" />
            <h3>{{ user?.group?.name || user?.residence?.name }}</h3>
          </li>
          <li>
            <div class="header-dropdown-wrap">
              <picture>
                <img src="assets/images/user.svg" alt="user" />
              </picture>
              <div class="header-user-dropdown-wrap">
                <div class="header-user-dropdown">
                  <div class="dropdown-info">
                    <img
                      src="assets/images/header-username.svg"
                      alt="username"
                    />
                    <span>{{ user?.fullName || "-" }}</span>
                  </div>
                  <div class="dropdown-info">
                    <img src="assets/images/email.svg" alt="email" />
                    <span>{{ user?.email || "-" }}</span>
                  </div>
                  <div class="dropdown-info">
                    <img src="assets/images/contact.svg" alt="contact" />
                    <span>{{ user?.phoneNumber || user?.mobile || "-" }}</span>
                  </div>
                  <a routerLink="/change-password">
                    <button class="common-primary-btn">Change Password</button>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li class="header-main-list" (outsideClick)="isOpenDrawer = false">
            <div class="header-notification">
              <span (click)="onNotificationDrawer()">
                <img src="/assets/images/header-bell.svg" alt="bell" />
                <div
                  class="notification-count"
                  *ngIf="notificationCount > 0"
                  [ngClass]="{
                    'notification-count-plus': notificationCount >= 100
                  }"
                >
                  <span *ngIf="notificationCount < 100">{{
                    notificationCount
                  }}</span>
                </div>
              </span>
              <div
                class="notification-modal common-scrollbar"
                *ngIf="isOpenDrawer"
                (scroll)="onScrollEnd($event)"
              >
                <div
                  class="notification-modal-header common-notification-header"
                >
                  <h2>Notifications</h2>
                  <label
                    class="unread-notification-status common-switch-toggle"
                  >
                    <span>Only Show Unread</span>
                    <input
                      type="checkbox"
                      [(ngModel)]="showOnlyUnread"
                      (change)="onShowUnreadNotifications($event)"
                    />
                  </label>
                </div>
                <div class="latest-notification-status">
                  <h3>Latest</h3>
                  <div
                    class="mark-all-notification"
                    (click)="markAllAsReadNotification()"
                  >
                    <img src="assets/images/check-right-icon.svg" alt="right" />
                    <h2>Mark all as read</h2>
                  </div>
                </div>
                <div class="notification-list common-notification-list">
                  <ul>
                    <li
                      *ngFor="let item of data?.data"
                      (click)="singleReadNotification(item)"
                      [ngClass]="{ 'read-notification': item?.isRead }"
                    >
                      <div class="notification-list-header common-list-header">
                        <div class="notification-list-name">
                          <h3>{{ item?.title }}</h3>
                          <span>{{
                            (item?.time | date: dateFormat) || "-"
                          }}</span>
                        </div>
                        <div class="notification-list-cancel">
                          <span
                            class="notification-dot"
                            *ngIf="!item?.isRead"
                            (click)="singleReadNotification(item)"
                          ></span>
                          <span (click)="deleteNotification(item?.id)">
                            <picture>
                              <img
                                src="assets/images/cancel.svg"
                                alt="cancel"
                              />
                            </picture>
                          </span>
                        </div>
                      </div>
                      <div class="notification-list-discription">
                        <p>
                          {{
                            parseDescriptionToJson(item.description)?.text ||
                              item?.description
                          }}
                        </p>
                        <ng-container
                          *ngIf="
                            item?.objectTypeId ===
                            NotificationObjectTypeId.SolarDeviceMonthlyPerformanceSummary
                          "
                        >
                          <button
                            (click)="toggleShowMore()"
                            class="show-toggle"
                          >
                            <h3>{{ showMore ? "Show Less" : "Show More" }}</h3>
                            <figure
                              [ngClass]="{
                                showMore: showMore,
                                showless: !showMore
                              }"
                            >
                              <img src="/assets/images/down.svg" alt="toggle" />
                            </figure>
                          </button>
                          <table *ngIf="showMore">
                            <tr>
                              <th>Type</th>
                              <th>
                                {{
                                  parseDescriptionToJson(item.description)?.date
                                }}
                              </th>
                              <th>Change</th>
                            </tr>
                            <tr
                              *ngFor="
                                let description of parseDescriptionToJson(
                                  item.description
                                )?.data
                              "
                            >
                              <td>{{ description?.type }}:</td>
                              <td>{{ description?.unit }}</td>
                              <td>
                                <span
                                  [ngClass]="{
                                    'common-red': description?.flag == '-1',
                                    green: description?.flag == '1'
                                  }"
                                  >{{ description?.change }}</span
                                >
                              </td>
                            </tr>
                          </table>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            item?.objectTypeId ===
                            NotificationObjectTypeId.SolarDeviceAnnualPerformanceSummary
                          "
                        >
                          <button
                            (click)="toggleShowMore()"
                            class="show-toggle"
                          >
                            <h3>{{ showMore ? "Show Less" : "Show More" }}</h3>
                            <figure
                              [ngClass]="{
                                showMore: showMore,
                                showless: !showMore
                              }"
                            >
                              <img src="/assets/images/down.svg" alt="toggle" />
                            </figure>
                          </button>
                          <table *ngIf="showMore">
                            <tr>
                              <th>Type</th>
                              <th>
                                {{
                                  parseDescriptionToJson(item.description)?.date
                                }}
                              </th>
                            </tr>
                            <tr
                              *ngFor="
                                let description of parseDescriptionToJson(
                                  item.description
                                )?.data
                              "
                            >
                              <td>{{ description?.type }}:</td>
                              <td>{{ description?.unit }}</td>
                            </tr>
                          </table>
                        </ng-container>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="menu-list" (click)="logout()">
            <div class="header-setting">
              <img src="/assets/images/header-logout.svg" alt="bell" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
