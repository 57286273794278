import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../../shared/constants/constants";
import { environment } from "../../../environments/environment";
import {
  UserPermissionModel,
  ResponseCustom,
  Module,
  ModelApplicationId,
  CommonMapOption,
} from "./../models";
import { navItems } from "../../../app/_nav";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { PermissionId } from "../models";
import { MESSAGE } from "../constants/message";
export interface IUserInfo {
  userId: number;
  email: string;
  ssoName: string;
  roles: CommonMapOption[];
}

@Injectable({ providedIn: "root" })
export class UserPermissionService {
  private _isNoPermission: Subject<boolean> = new Subject<boolean>();
  private _infoUser: Subject<UserPermissionModel> =
    new Subject<UserPermissionModel>();
  infoUser = this._infoUser.asObservable();

  data: UserPermissionModel;

  myHeader = new HttpHeaders({
    "Content-Type": "application/json",
  });

  constructor(private _http: HttpClient) { }

  getPermission() {
    let url = environment.baseAPIURL + CONSTANTS.API.AUTHENTICATION.INFO_USER;
    if (!!this.data) return Promise.resolve(this.data);

    return this._http
      .get(url, { headers: this.myHeader })
      .toPromise()
      .then((res: ResponseCustom) => {
        if (res.statusCode !== 200) return Promise.reject(res);
        this.saveUserInfo(res.result);
        return Promise.resolve(this.data);
      });
  }

  getPermissionRead(url: string) {
    if (!this.data) return false;
    return this.data?.moduleApplicationUrls.includes(url);
  }

  getHasPermission(permissionId: PermissionId) {
    if (!this.data) return false;
    return !!this.data.permissionIds.includes(permissionId);
  }

  getPermissionReadModule(moduleId: number) {
    if (!this.data) return false;
    return !!this.data.moduleApplicationIds.includes(moduleId);
  }

  //This function used for check main module permission.
  checkPermissionForMainModule(moduleId: number) {
    let permissionIds: ModelApplicationId[];
    switch (moduleId) {
      case Module.Admin:
        permissionIds = [
          ModelApplicationId.MDM,
          ModelApplicationId.UserManagement,
        ];
        break;
      case Module.Intelligence:
        permissionIds = [
          ModelApplicationId.Analytics,
          ModelApplicationId.Reporting,
          ModelApplicationId.TariffSimulator,
          ModelApplicationId.Global,
          ModelApplicationId.KPIs,
        ];
        break;
      case Module.Communication:
        permissionIds = [
          ModelApplicationId.Notifications,
          ModelApplicationId.AlertLog,
          ModelApplicationId.ContactUs,
        ];
        break
      case Module.Performance:
        permissionIds = [
          ModelApplicationId.PerformanceGroup,
          ModelApplicationId.PerformanceEnergy,
          ModelApplicationId.PerformanceCarbon,
          ModelApplicationId.PerformanceEfficiency,
          ModelApplicationId.PerformancePowerQuality,
          ModelApplicationId.PerformanceFinancial,
        ];
        break;
      case Module.Solar:
        permissionIds = [
          ModelApplicationId.LiveStatus,
          ModelApplicationId.Energy,
          ModelApplicationId.Carbon,
          ModelApplicationId.Financial,
          ModelApplicationId.Impact,
        ];
        break;
      case Module.Technical:
        permissionIds = [
          ModelApplicationId.DataLog,
          ModelApplicationId.LiveStream,
          ModelApplicationId.DeviceManagement,
        ];
        break;
    }
    return permissionIds.some((item) =>
      this.data?.moduleApplicationIds.includes(item),
    );
  }

  //This function used for get message for permission not allowed.
  getPermissionNotAllowedMessage(moduleId: number) {
    let permissionNotAllowedMsg: string;
    if (moduleId === Module.Admin) {
      permissionNotAllowedMsg = MESSAGE.INTERNAL_USE_ONLY;
    } else if (
      [Module.Communication, Module.Solar, Module.Technical].includes(moduleId)
    ) {
      permissionNotAllowedMsg = MESSAGE.QLENS_LITE_USE;
    } else {
      permissionNotAllowedMsg = MESSAGE.QLENS_PLUS_USE;
    }
    return permissionNotAllowedMsg;
  }
  //This function used for save user permission.
  saveUserInfo(data: UserPermissionModel) {
    const moduleDefault = ["#", "/landing"];
    let moduleApplicationUrls: any[] = [];

    moduleApplicationUrls = data.moduleApplicationIds.map((moduleId) => {
      const nav = navItems.filter((nv) => nv?.modelApplicationId === moduleId);
      if (nav) return nav.map((n: any) => n.url);
      return "#";
    });
    moduleApplicationUrls = moduleApplicationUrls.flat(1);
    data.moduleApplicationUrls = [...moduleDefault, ...moduleApplicationUrls];
    this.data = data;
    this._infoUser.next(data);
  }

  getListPermission() {
    return this.data;
  }

  getUserInfoOb() {
    return this.infoUser;
  }

  getUserInfo() {
    return this.data;
  }

  getPermissionUser() {
    return this.data?.permissionIds || [];
  }

  getIsNoPermission() {
    return this._isNoPermission.asObservable();
  }
  setIsNoPermission(status: boolean) {
    this._isNoPermission.next(status);
  }

  setUserDataNull() {
    this.data = null;
  }
}
