import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { DataLogList } from "../models/data-log";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class DataLogService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;

  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // This API used for get all devices.
  getAllDevice(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
    residenceId: number = 0,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
      residenceId: residenceId,
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.TECHNICALDATALOG.LIST_DEVICE}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get data log list.
  getDatalogList(DataLog: DataLogList) {
    const data: ListCustom = DataLog.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;

    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.TECHNICALDATALOG.LIST_DATALOG}`,
        {
          ...data,
          deviceSNs: DataLog.deviceSNs,
          residenceId: DataLog.residenceId,
          fromDate: DataLog.fromDate,
          toDate: DataLog.toDate,
        },
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  exportDataLog(dataLogParams: {
    fromDate: string;
    toDate: string;
    deviceIds: Array<string>;
    residenceId: number;
  }) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.TECHNICALDATALOG.EXPORT_DATALOG}`,
        dataLogParams,
        {
          responseType: "text",
          ...this.httpOptions,
        },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
