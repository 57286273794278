import { createReducer, on } from "@ngrx/store";
import { CommonModel, GraphNotificationData, OutStandingNotificationAlerts, PerformanceDataValue, PowerQualityDataValue, QScorePerformanceDataValue, QscoreDataValue, ReportChartValue, ReportFilter, ReportGroupDataList, ReportTab, SummaryNotificationData, TabModule } from "src/main/shared/models";
import { ReportActions } from "./action-types";
import { Country, DropdownResponse } from "src/main/shared/models/analytics";


export interface ReportState {
    groupNames: DropdownResponse,
    residentNames: DropdownResponse,
    siteIdsNames: DropdownResponse,
    countries: Country[],
    group: CommonModel,
    siteIds: CommonModel,
    deviceId: CommonModel,
    currency: CommonModel,
    filters: ReportFilter;
    qscoreData: QscoreDataValue[];
    energy: ReportChartValue[];
    carbon: ReportChartValue[];
    efficiency: ReportChartValue[];
    financial: ReportChartValue[];
    powerQuality: PowerQualityDataValue[];
    qSenseScorePerformance: QScorePerformanceDataValue;
    energyPerformance: PerformanceDataValue;
    carbonPerformance: PerformanceDataValue;
    efficiencyPerformance: PerformanceDataValue;
    powerQualityPerformance: PerformanceDataValue;
    financialPerformance: PerformanceDataValue;
    graphData: GraphNotificationData[];
    summaryData: SummaryNotificationData[];
    outstandingAlerts: OutStandingNotificationAlerts[];
    reportDataLoaded: boolean;
    notificationLoaded: boolean;
    reportTab: ReportTab;
    topTab: TabModule[];
    bottomTab: TabModule[];
    notificationTab: TabModule[];
    error: Partial<Error>
}


export const initialState: ReportState = {
    groupNames: null,
    residentNames: null,
    siteIdsNames: null,
    countries: null,
    group: null,
    siteIds: null,
    deviceId: null,
    currency: null,
    filters: null,
    qscoreData: [],
    energy: [],
    carbon: [],
    efficiency: [],
    financial: [],
    powerQuality: [],
    qSenseScorePerformance: null,
    energyPerformance: null,
    carbonPerformance: null,
    efficiencyPerformance: null,
    powerQualityPerformance: null,
    financialPerformance: null,
    graphData: null,
    summaryData: null,
    outstandingAlerts: null,
    reportDataLoaded: false,
    notificationLoaded: false,
    reportTab: null,
    topTab: null,
    bottomTab: null,
    notificationTab: null,
    error: null
}


export const reportReducers = createReducer(
    initialState,
    on(ReportActions.loadGroupIdsSuccess, (state, { groupIds }) => {
        return {
            ...state,
            error: null,
            groupNames: {
                ...groupIds,
                data: groupIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                    currencyCode: data.currencyCode,
                }))
            }
        }
    }),

    on(ReportActions.loadGroupIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(ReportActions.loadSiteIdsSuccess, (state, { siteIds }) => {
        return {
            ...state,
            error: null,
            siteIdsNames: {
                ...siteIds,
                data: siteIds?.data.map((data: any) => ({
                    id: data.id,
                    label: data.name,
                }))
            }

        }
    }),

    on(ReportActions.loadSiteIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(ReportActions.loadAllCountriesSuccess, (state, { countries }) => {
        return {
            ...state,
            countries: countries?.result.map((item) => ({
                id: item.id,
                label: `${item.name} - ${item.currency} - ${item.currencySybmol}`,
            })),
            error: null
        }
    }),

    on(ReportActions.loadAllCountriesFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),

    on(ReportActions.loadResidentIdsSuccess, (state, { residentNames }) => ({
        ...state,
        error: null,
        residentNames
    })),

    on(ReportActions.loadResidentIdsFailure, (state, { error }) => {
        return {
            ...state,
            error: error
        }
    }),


    on(ReportActions.setGroup, (state, { group }) => {
        return {
            ...state,
            group: group
        }
    }),

    on(ReportActions.setSiteIds, (state, { siteIds }) => {
        return {
            ...state,
            siteIds: siteIds
        }
    }),

    on(ReportActions.setDeviceId, (state, { deviceId }) => {
        return {
            ...state,
            deviceId: deviceId
        }
    }),

    on(ReportActions.setCurrency, (state, { currency }) => {
        return {
            ...state,
            currency: currency
        }
    }),

    on(ReportActions.setReportTab, (state, { reportTab }) => {
        return {
            ...state,
            reportTab
        }
    }),

    on(ReportActions.setTopTab, (state, { topTab }) => {
        return {
            ...state,
            topTab
        }
    }),

    on(ReportActions.setBottomTab, (state, { bottomTab }) => {
        return {
            ...state,
            bottomTab
        }
    }),

    on(ReportActions.setNotificationTab, (state, { notificationTab }) => {
        return {
            ...state,
            notificationTab
        }
    }),

    on(ReportActions.setReportFilters, (state, { filters }) => {
        return {
            ...state,
            filters
        }
    }),

    on(ReportActions.loadReportDataSuccess, (state, { report }) => (
        {
            ...state,
            qscoreData: report.qscoreData,
            energy: report.energy,
            carbon: report.carbon,
            efficiency: report.efficiency,
            financial: report.financial,
            powerQuality: report.powerQuality,
            qSenseScorePerformance: report.qSenseScorePerformance,
            energyPerformance: report.energyPerformance,
            carbonPerformance: report.carbonPerformance,
            efficiencyPerformance: report.efficiencyPerformance,
            powerQualityPerformance: report.powerQualityPerformance,
            financialPerformance: report.financialPerformance,
            reportDataLoaded: true,
            error: null
        }
    )),

    on(ReportActions.loadReportDataFailure, (state, { error }) => ({
        ...state,
        reportDataLoaded: false,
        error
    })),

    on(ReportActions.setLoaded, (state, { flag }) => ({
        ...state,
        reportDataLoaded: flag,
    })),

    on(ReportActions.loadNotificationSuccess, (state, { notification }) => (
        {
            ...state,
            graphData: notification.graphData,
            summaryData: notification.summaryData,
            outstandingAlerts: notification.outstandingAlerts,
            notificationLoaded: true,
            error: null
        }
    )),

    on(ReportActions.loadNotificationFailure, (state, { error }) => ({
        ...state,
        notificationLoaded: false,
        error
    })),

    on(ReportActions.resetReportState, () => {
        return {
            ...initialState
        }
    })


)

