import { createAction, props } from "@ngrx/store"
import { ColumnShowHide, CommonModel, DataLogList, DataLogTable, ExportDataLog, QsenseDataLogList, QsenseDataLogTable, TabModule } from "src/main/shared/models"
import { DropdownResponse } from "src/main/shared/models/analytics"


const enum datalogActionTypes {
    SET_GROUP_DATALOG = "[Datalog Page] Set Group",
    SET_SITE_DATALOG = "[Datalog Page] Set Site",
    SET_DEVICE_DATALOG = "[Datalog Page] Set Device",
    SET_DATALOG_FILTERS = "[Datalog Page] Save Filters",
    SET_DATALOG_COLUMNS = "[Datalog Page] Set DataLog Columns",
    SET_BOTTOM_TAB = "[Datalog Page] Set Bottom  Selected Tab",
    RESET_DATA_LOG = "[Datalog Page] Reset Datalog State",
    ADD_REMOVE_DATALOG_COLUMN = "[Datalog Page] Add Remove Datalog Column",
    LOAD_GROUP_DATALOG = "[Datalog Page] Load Group Ids",
    LOAD_GROUP_DATALOG_SUCCESS = "[Datalog Page] Load Group Ids Success",
    LOAD_GROUP_DATALOG_FAILURE = "[Datalog Page] Load Group Ids Failure",
    LOAD_SITE_DATALOG = "[Datalog Page] Load Site Ids",
    LOAD_SITE_DATALOG_SUCCESS = "[Datalog Page] Load Site Ids Success",
    LOAD_SITE_DATALOG_FAILURE = "[Datalog Page] Load Site Ids Failure",
    LOAD_DEVICE_DATALOG = "[Datalog Page] Load Device Ids",
    LOAD_DEVICE_DATALOG_SUCCESS = "[Datalog Page] Load Device Ids Success",
    LOAD_DEVICE_DATALOG_FAILURE = "[Datalog Page] Load Device Ids Failure",
    LOAD_QSENSE_DATA_LIST = "[Datalog Page] Load Qsense Data List",
    LOAD_QSENSE_DATA_LIST_SUCCESS = "[Datalog Page] Load Qsense Data Dist Success",
    LOAD_QSENSE_DATA_LIST_FAILURE = "[Datalog Page] Load Qsense Data List Failure",
}

export const loadGroupIds = createAction(
    datalogActionTypes.LOAD_GROUP_DATALOG,
    props<{ keyword?: string, size?: number, page?: number }>()
)

export const loadGroupIdsSuccess = createAction(
    datalogActionTypes.LOAD_GROUP_DATALOG_SUCCESS,
    props<{ groupIds: DropdownResponse }>()
)

export const loadGroupIdsFailure = createAction(
    datalogActionTypes.LOAD_GROUP_DATALOG_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadSiteIds = createAction(
    datalogActionTypes.LOAD_SITE_DATALOG,
    props<{ groupId: number, keyword?: string, page?: number, size?: number }>()
)

export const loadSiteIdsSuccess = createAction(
    datalogActionTypes.LOAD_SITE_DATALOG_SUCCESS,
    props<{ siteIds: DropdownResponse }>()
)

export const loadSiteIdsFailure = createAction(
    datalogActionTypes.LOAD_SITE_DATALOG_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadDeviceIds = createAction(
    datalogActionTypes.LOAD_DEVICE_DATALOG,
    props<
        {
            fromDate: string,
            toDate: string,
            groupId: number,
            siteId: number,
            keyword?: string,
            page?: number,
            size?: number
        }
    >()
)

export const loadDeviceIdsSuccess = createAction(
    datalogActionTypes.LOAD_DEVICE_DATALOG_SUCCESS,
    props<{ deviceIds: DropdownResponse }>()
)

export const loadDeviceIdsFailure = createAction(
    datalogActionTypes.LOAD_DEVICE_DATALOG_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const setGroup = createAction(
    datalogActionTypes.SET_GROUP_DATALOG,
    props<{ group: CommonModel }>()
)

export const setSiteIds = createAction(
    datalogActionTypes.SET_SITE_DATALOG,
    props<{ siteIds: CommonModel }>()
)

export const setDeviceId = createAction(
    datalogActionTypes.SET_DEVICE_DATALOG,
    props<{ deviceId: CommonModel[] }>()
)

export const loadQsenseDataList = createAction(
    datalogActionTypes.LOAD_QSENSE_DATA_LIST,
    props<{ payload: QsenseDataLogList }>()
)

export const loadQsenseDataListSuccess = createAction(
    datalogActionTypes.LOAD_QSENSE_DATA_LIST_SUCCESS,
    props<{ dataList: QsenseDataLogTable }>()
)

export const loadQsenseDataListFailure = createAction(
    datalogActionTypes.LOAD_QSENSE_DATA_LIST_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const setDataLogFitlers = createAction(
    datalogActionTypes.SET_DATALOG_FILTERS,
    props<{ filters: QsenseDataLogList }>()
)

export const setColumns = createAction(
    datalogActionTypes.SET_DATALOG_COLUMNS,
    props<{ columns: ColumnShowHide[] }>()
)

export const setBottomTab = createAction(
    datalogActionTypes.SET_BOTTOM_TAB,
    props<{ bottomTab: TabModule[] }>()
)

export const resetDataLog = createAction(
    datalogActionTypes.RESET_DATA_LOG
)

export const addRemoveColumn = createAction(
    datalogActionTypes.ADD_REMOVE_DATALOG_COLUMN,
    props<{ columns: ColumnShowHide[] }>()
)