<div class="forgot-password">
  <div class="forgot-password-left">
    <div class="signin-info">
      <picture>
        <img src="assets/images/landing/logo-lens-white.svg" alt="Qlens" />
      </picture>
      <h1>
        <span>Visualise,</span>
        <span>Understand,</span>
        <span>Optimise.</span>
      </h1>
      <p>Your next generation energy management platform</p>
    </div>
    <div class="left-help">
      <p>
        Having troubles?
        <a href="#" class="help-text">Get Help</a>
      </p>
    </div>
  </div>
  <div class="forgot-password-right">
    <div class="right-wrap">
      <div class="signIn-right-header">
        <h2>Forgot Password</h2>
      </div>
      <form [formGroup]="form" class="signin-form">
        <app-status-message [statusForm]="statusForm"></app-status-message>
        <div class="login-form-group forgot-password-form-group">
          <label for="email">Email</label>
          <div
            class="input-group"
            [ngClass]="{ 'validation-border': getError('userEmail') }"
          >
            <input
              trim
              class="email-input"
              type="email"
              formControlName="userEmail"
              placeholder="Email"
            />
          </div>
          <div class="input-error" *ngIf="getError('userEmail')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{ getError("userEmail", "required") && MESSAGE.EMAIL_REQ }}
              {{ getError("userEmail", "email") && MESSAGE.EMAIL_INVALID }}
            </p>
          </div>
        </div>
        <button class="signin-btn" (click)="onSubmit()">Submit</button>
        <div class="login-link">
          <a class="backToLogin" routerLink="/login">Back to Login</a>
        </div>
      </form>
    </div>
    <div class="poweredBy">
      <p>Powered by</p>
      <img src="assets/images/landing/powered-img.svg" alt="infix" />
    </div>
  </div>
</div>
