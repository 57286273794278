import { ListCustom } from "./response-custom";

export interface SiteData {
  siteId: number;
  siteName: string;
  status: boolean;
}

export interface SiteDeviceData {
  deviceId: number;
  deviceReference: string;
  deviceTypeId: number;
  status: boolean;
  deviceTypeName: string;
  macId: string
}

export class DataTableDeviceList {
  page: number;
  size: number;
  total: number;
  data: SiteData[];
}

export class DataTableSiteDeviceList {
  page: number;
  size: number;
  total: number;
  data: SiteDeviceData[];
}
export interface SiteList {
  data?: ListCustom;
  groupId?: number;
  siteId?: number;
  sortByEnabledorDisabled?: boolean;
}
export enum SiteEKeyField {
  siteName = "siteName",
}

export enum SiteDeviceEKeyField {
  deviceId = "deviceId",
  deviceReference = "deviceReference",
  deviceTypeId = "deviceTypeId",
}

export enum SiteDeviceStatusType {
  Enable = "Enabled",
  Disable = "Disabled",
}

export interface TransformerDBCircuitDeviceDetail {
  deviceId: number;
  macId: string;
  deviceType: string;
  deviceRefFreeText: string;
  devicePF: number;
  deviceActivePowerKV: number;
  status: number;
}
export interface TransformerDBCircuitDetail {
  id: number;
  systemNameWithPreFix: string;
  name: string;
  typeId: number;
  subTransformerDBCircuits?: TransformerDBCircuitDetail[];
  transformerDBCircuitDevices: TransformerDBCircuitDeviceDetail[];
}

export class SiteMapDetail {
  siteId: number;
  siteNumberWithPrefix: string;
  name: string;
  transformerDBCircuits: TransformerDBCircuitDetail[];
}
