import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../../shared/constants/constants";
import { environment } from "../../../environments/environment";
import { UserPermissionService } from "./user-permission.service";
import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs/internal/Subject";
import { UserPermissionModel } from "../models/user-permission";
import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class DataRealtimeHubService {
  myHeader = new HttpHeaders({
    "Content-Type": "application/json",
  });

  private hubConnection: signalR.HubConnection;
  private isReconnect: boolean = true;
  private isConnected: boolean = false;
  private isDisconnect: boolean = false;

  reHubConnection: Subject<boolean> = new Subject<boolean>();
  user: UserPermissionModel;
  isSignalRConnectFail: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private userPermissionService: UserPermissionService,
    private authenticationService: AuthenticationService,
    // private generalService: GeneralService
  ) {
    this.user = this.userPermissionService.getUserInfo();
  }

  setReHubConnection(status: boolean) {
    this.reHubConnection.next(status);
  }

  async getHubConnect() {
    if (this.hubConnection && this.isConnected) return this.hubConnection;
    if (!this.hubConnection) {
      await this.connectHub();
    }
    if (!this.isConnected) {
      return null;
    }
    return this.hubConnection;
  }

  private async connectHub() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${environment.baseURLSignalR}${CONSTANTS.SignalR.DATA_REALTIME_UPDATE}`,
        {
          accessTokenFactory: () => this.authenticationService.getToken(),
        },
      )
      .configureLogging(1)
      .build();
    await this.startConnection();
    this.hubConnection.onclose(async () => {
      if (this.isReconnect) {
        this.isDisconnect = true;
        setTimeout(() => this.connectHub(), 5000);
      }
    });
  }
  setSignalRConnectFail(status: boolean) {
    this.isSignalRConnectFail.next(status);
  }

  checkSignalRConnectFail(err: { statusCode: number }) {
    if (err.statusCode === 401) this.isSignalRConnectFail.next(true);
  }

  //This function used for get new token based on refresh token.
  private async getNewToken() {
    let error = null;
    let newToken: { accessToken: string, refreshToken: string }
    this.authenticationService.getNewToken().subscribe({
      next: (res: { result: { accessToken: string, refreshToken: string } }) => {
        newToken = res.result
        this.authenticationService.setToken(res.result)
        if (!newToken) throw new Error(error)
        return {
          token: newToken.accessToken,
          refreshToken: newToken.refreshToken,
        }
      },
      error: (e) => {
        error = e.errors;
        return Promise.resolve(null);
      }
    })
  }

  private async refreshTokenAndReconnect() {
    try {
      // Call your authentication service to refresh the token
      await this.getNewToken();

      // Update the SignalR connection with the new token
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.baseURLSignalR}${CONSTANTS.SignalR.DATA_REALTIME_UPDATE}`, {
          accessTokenFactory: () => this.authenticationService.getToken()
        }).configureLogging(1).build();

      // Retry the SignalR connection
      await this.startConnection();
    } catch (refreshError) {
      console.error(refreshError);
    }
  }

  private async startConnection() {
    try {
      await this.hubConnection.start();
      console.log('SignalR Connection started');
      // Other connection-related logic
    } catch (error) {
      if (error && (error.message.includes('401') || error.statusCode === 401)) {
        console.log('SignalR Connection failed with 401 Unauthorized');
        // Handle token refresh
        await this.refreshTokenAndReconnect();
      } else {
        setTimeout(() => this.connectHub(), 5000);
      }
    }
  }
}
