import { IAngularMyDpOptions } from "@nodro7/angular-mydatepicker";
import { DefaultView } from "./tab-module";

export const myDatePickerOptions: IAngularMyDpOptions = {
  dateRange: false,
  dateFormat: "dd/mm/yyyy",
  divHostElement: { enabled: true, placeholder: "Select a date" },
  defaultView: DefaultView.Date,
  stylesData: {
    selector: "dp1",
    styles: `
          .dp1 .myDpSelector,
          .dp1 .myDpMonthYearSelBar,
          .dp1 .myDpCalTable, 
          .dp1 .myDpMonthTable,
          .dp1 .myDpYearTable,
          .dp1 .myDpDaycell,
          .dp1 .myDpMonthcell,
          .dp1 .myDpYearcell {
            background: #151035;
            color: #fff;
          }
          .dp1 .myDpSelector {
            border: 1px solid #151035;
          }
          .dp1 .myDpSelectorArrow:after {
            border-bottom-color: #151035;
          }
          .dp1 .myDpSelectorArrow:before {
            border-color: #0B091C;
            border-width: 0
          }
          .dp1 .myDpMonthYearSelBar {
            background: #151035;
          }
          .dp1 .myDpRangeColor {
            background: #A5ABF2;
          }
         .dp1 .myDpIconLeftArrow,
         .dp1 .myDpIconRightArrow,
         .dp1 .myDpHeaderBtn {
             color: #fff;
          }
          .myDpCalTable {
            position: relative;
          }
          .myDpCalTable:before{
            content: "";
            position: absolute;
            left: 16px;
            width: 1px;
            height: 200px;
            background : #5754BD;
          }
          .dp1 .myDpDaycell:focus,
          .dp1 .myDpMonthcell:focus,
          .dp1 .myDpYearcell:focus {
            box-shadow: inset 0 0 0 1px #0B091C;
          }
          .dp1 .myDpSelector:focus {
            box-shadow: -1px 1px 10px 1px #0B091C;
          }
         .dp1 .myDpDaycellWeekNbr {
             color: #fff;
          }
         .dp1 .myDpPrevMonth,
         .dp1 .myDpNextMonth {
             color: #6495ED;
          }
         .dp1 .myDpWeekDayTitle {
             background-color: transparent;
             color: #fff;
             font-weight: bold;
          }
         .dp1 .myDpMarkCurrDay, 
         .dp1 .myDpMarkCurrMonth, 
         .dp1 .myDpMarkCurrYear {
             border-bottom: 2px solid #fff;
          }
         .dp1 .myDpDisabled {
             color: #999;
          }
         .dp1 .myDpHighlight {
             color: #cd5c5c;
          }
         .dp1 .myDpTableSingleDay:hover, 
         .dp1 .myDpTableSingleMonth:hover, 
         .dp1 .myDpTableSingleYear:hover {
             background-color: #34305B;
             color: #fff;
          }
         .dp1 .myDpSelectedDay,
         .dp1 .myDpSelectedMonth,
         .dp1 .myDpSelectedYear {
             background-color: #6B71EE;
          }
       `,
  },
};
