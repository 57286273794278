import { Component, Input, SimpleChanges } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
})
export class LoaderComponent {
  @Input() showSpinner: boolean = false;
  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit() {
    if (this.showSpinner) {
      this.showLoader();
    }
  }
  ngOnChanges(props: SimpleChanges) {
    const { showSpinner } = props;
    if (showSpinner?.currentValue) {
      this.showLoader();
    } else {
      this.stopLoader();
    }
  }
  showLoader() {
    this.spinner.show();
  }
  stopLoader() {
    this.spinner.hide();
  }
}
