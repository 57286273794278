import { ActionReducerMap, createReducer, on } from "@ngrx/store";
import { LoginForm } from "src/main/shared/models/auth";
import { LoginActions } from "./action-types";



export interface LoginState {
    user: LoginForm
}

export const initialAppState: LoginState = {
    user: undefined
}

export const loginReducers = createReducer(
    initialAppState,
    on(LoginActions.login, (state, { user }) => {
        return {
            user: user
        }
    })
)

