export const MESSAGE = {
  DEFAULT: "Something went wrong, please try again",

  //Login
  LOGIN_SUCC: "Login successfully done.",
  EMAIL_REQ: "Email is required.",
  EMAIL_INVALID: "Email invalid.",
  PASS_REQ: "Password is required.",
  PASS_MIN_LENGTH: "Password minimum 8 characters.",

  //Forgot password
  FORGOT_PASS_SUCC:
    "Reset password link sent to your email. Can you please check your email.",

  //Reset password
  CURRENTPASS_REQ: "Current Password is required.",
  CURRENTPASS_MIN_LENGTH: "Current Password minimum 8 characters.",
  CURRENT_NEWPASS_NOT_SAME: "Current password and new password not be same.",
  CONFIRMPASS_REQ: "Confirm password is required.",
  CONFIRMPASS_MIN_LENGTH: "Confirm password minimum 8 characters.",
  NEW_CONFIRMPASS_SAME: "New password and confirm password must be same.",

  //Landing
  INTERNAL_USE_ONLY: "Internal use only",
  QLENS_PLUS_USE:
    "Only available with QLens+\nContact QLens support to upgrade.",
  QLENS_LITE_USE:
    "Only available with QLens Lite\nContact QLens support for more information.",

  //Contact-us
  MESSAGE_SENT_SUCC: "Message sent successfully.",

  /**------------------------MDM-------------------------*/

  /**------------------------Device Management-------------------------*/
  DEVICE_SN_NO_REQ: "Device serial number is required.",
  CONFIRM_DEVICE_SN_NO_REQ: "Confirm device serial number is required.",
  DEVICE_SN_CONFIRM_SN_SAME:
    "Device serial number and confirm device serial number must be same.",
  DEVICE_TYPE_REQ: "Device type is required.",
  RESIDENCY_REQ: "Residency is required.",
  DEVICE_ALREADY_EXIST: "Device serial number already exist.",
  DEVICE_ADDED_SUCC: "Device added successfully.",
  DEVICE_EDITED_SUCC: "Device edited successfully.",

  /** -------------- Qsens device Management ------------------------- */
  DEVICE_MAC_ADDRESS_REQ: "Device is required.",
  CONFIRM_MAC_ADDRESS_REQ: "Confirm device is required.",
  DEVICE_MAC_CONFIRM_MAC_SAME:
    "Device device and confirm device number must be same.",
  TRANSFORMER_NAME_REQ: "Transformer is required.",
  DEVICE_ACTIVATE_SUCC: "Device enabled successfully.",
  DEVICE_DEACTIVATE_SUCC: "Device disabled successfully.",
  DEVICE_NOT_SELECTED: "Please select device",

  /**------------------------Resident-------------------------*/
  OWNER_NAME_REQ: "Owner name is required.",
  OWNER_NAME_ALPHA_ALLOW: "Only alphabets allowed in Owner name field.",
  RESIDENCE_NAME_REQ: "Residence name is required.",
  ADDRESS_ONE_REQ: "Address one is required.",
  COUNTRY_REQ: "Country is required.",
  CITY_EMIRATE_REQ: "City is required.",
  STATE_REQ: "State is required",
  SUBSTATE_REQ: "Substate is required",
  LOCATION_REQ: "Location is required.",
  CONNECTION_TYPE_REQ: "Please select electricity connection type.",
  RESIDENCE_ADDED_SUCC: "Residence added successfully.",
  RESIDENCE_EDITED_SUCC: "Residence edited successfully.",
  RESIDENCE_ACTIVATE_SUCC: "Residence activated successfully.",
  RESIDENCE_DEACTIVATE_SUCC: "Residence disabled successfully.",

  /**------------------------User MGMT-------------------------*/
  FULL_NAME_REQ: "Full name is required.",
  PHONE_MAX_SIZE: "Phone number cannot exceed more than 15 characters.",
  PHONE_PATTERN: "Please enter correct phone number",
  USER_TYPE_REQ: "Please select user type.",
  USER_ROLE_REQ: "At least one role must be selected.",
  RESIDENTIAL_NAME_REQ: "Please select residence.",
  USER_ADDED_SUCC: "User added successfully.",
  USER_EDITED_SUCC: "User edited successfully.",
  EMAIL_PATTERN: "Please enter valid email address",

  /**------------------------Unit cost-------------------------*/
  TARIFF_NAME_REQ: "Tariff name is required.",
  TARIFF_NAME_ONLY_ALPHA: "Tariff name is only allow alphabets.",
  TARIFF_TYPE_REQ: "Tariff type is required.",
  START_DATE_REQ: "Start date is required.",
  END_DATE_REQ: "End date is required.",
  SLAB_FROM_REQ: "Slab from is required.",
  SLAB_TO_REQ: "Slab to is required.",
  SLAB_FROM_GREATER_PREVIOUS_SLAB_TO:
    "Slab from must be greater than previous Slab to.",
  END_DATE_GREATER_START_DATE: "End date must be greater than Start date.",
  SLAB_TO_GREATER_SLAB_FROM: "Slab to must be greater than Slab from.",
  AMOUNT_REQ: "Amount is required.",
  FUEL_SURCHARGE_REQ: "Fuel surcharge required.",
  UNIT_COST_ADDED_SUCC: "Unit cost added successfully.",
  UNIT_COST_EDITED_SUCC: "Unit cost edited successfully.",

  /**------------------------Data Log-------------------------*/
  INCORRECT_DATE: "Please select correct date.",
  DATA_NOT_AVAILABLE: "No Data Available",
  SELECT_COLUMN: "Select atleast one column",
  SELECT_DROPDOWN: "Select all dropdown",
  /** -------------- Landing ------------------------- */
  DISABLED_MENU_TEXT:
    "To enable, upgrade your product package or contact Qlens team for more information",

  /** -------------- Residence Dashboard ------------------------- */
  RESIDENT_NOT_SELECTED: "Please select a resident to view the data.",

  /** -------------- Group Management ------------------------- */
  GROUP_NAME_REQ: "Group name is required.",
  GROUP_ADDED_SUCC: "Group added successfully.",
  GROUP_EDITED_SUCC: "Group edited successfully.",
  BUSINESS_SECTOR_REQ: "Business sector is required.",
  GROUP_ACTIVATE_SUCC: "Group activate successfully.",
  GROUP_DEACTIVATE_SUCC: "Group deactivate successfully.",
  DEFINE_SIZE_TYPE_REQ: "Define Size Type required",
  DEFINE_SECTOR_TYPE_ID_REQ: "Define Size in Sq.ft required",
  GROUP_ALREADY_EXIST: "Group name already exists",

  /** -------------- Site Management ------------------------- */
  SITE_NAME_REQ: "Site name is required.",
  BLOCK_NUMBER_REQ: "Block number is required.",
  TRANSFORMER_REQ: "Number of transformer is required.",
  SITE_ADDED_SUCC: "Site added successfully.",
  SITE_EDITED_SUCC: "Site edited successfully.",
  SITE_ACTIVATE_SUCC: "Site activate successfully.",
  SITE_DEACTIVATE_SUCC: "Site deactivate successfully.",
  SELECT_SITE:"Select Site",
  SITE_ALREADY_EXIST: "Site name already exists",

  /** -------------- Alert Log ------------------------- */
  UPDATE_ALERT_LOG_STATUS: "Alert log status updated successfully.",
  COMMENT_ADDED_SUCC: "Comment added successfully.",
  COMMENT_REQ: "Comment is required",

  /** -------------- Live Stream ------------------------- */
  MACADDRESSSELECT: "Please select device.",

  /** -------------- Performance ------------------------- */
  SELECTGROUP: "Please select group",
  SELECTRESIDENCE: "Please select residence",
  SELECTPARAMETERFILTER: "Please select parameter filter",
};
