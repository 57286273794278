<app-loader [showSpinner]="showSpinner"></app-loader>
<div class="signIn">
  <div class="signIn-left">
    <div class="signin-info">
      <picture>
        <img src="assets/images/landing/logo-lens-white.svg" alt="Qlens" />
      </picture>
      <h1>
        <span>Watt</span>
        <span>Matters</span>
        <span>Most</span>
      </h1>
      <p>Your partner in Energy Management Solutions</p>
    </div>
    <div class="left-help">
      <p>
        Having troubles?
        <a href="#" class="help-text">Get Help</a>
      </p>
    </div>
  </div>
  <div class="signIn-right">
    <div class="right-wrap">
      <div class="signIn-right-header">
        <h2>
          <span>Hello,</span>
          Welcome Back!
        </h2>
      </div>
      <form [formGroup]="form" class="signin-form">
        <app-status-message [statusForm]="statusForm"></app-status-message>
        <div class="login-form-group email-form-group">
          <label for="email">E-mail</label>
          <div
            class="input-group"
            [ngClass]="{ 'validation-border': getError('username') }"
          >
            <input
              trim
              class="email-input"
              id="email"
              type="email"
              formControlName="username"
              placeholder="Email"
            />
          </div>
          <div class="input-error" *ngIf="getError('username')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{ getError("username", "required") && getMessage.EMAIL_REQ }}
              {{ getError("username", "email") && getMessage.EMAIL_INVALID }}
            </p>
          </div>
        </div>
        <div class="login-form-group password-from-group">
          <label for="email">Password</label>
          <div
            class="input-group password-grp"
            [ngClass]="{ 'validation-border': getError('password') }"
          >
            <input
              trim
              type="password"
              id="password"
              formControlName="password"
              class="password-input"
              placeholder="Password"
              [type]="showHidePassword ? 'text' : 'password'"
            />
            <img
              [src]="
                showHidePassword
                  ? 'assets/images/landing/hide.svg'
                  : 'assets/images/landing/show.svg'
              "
              (click)="showHidePass()"
              alt="hide"
            />
          </div>
          <div class="input-error" *ngIf="getError('password')">
            <img src="assets/images/landing/exclanation.svg" alt="err-icn" />
            <p>
              {{ getError("password", "required") && getMessage.PASS_REQ }}
              {{
                getError("password", "minlength") && getMessage.PASS_MIN_LENGTH
              }}
            </p>
          </div>
        </div>
        <div class="forgot-password-link">
          <a class="password" routerLink="/forgot-password">Forgot Password</a>
        </div>
        <button class="signin-btn" (click)="onSubmit()">Sign in</button>
      </form>
    </div>
    <div class="poweredBy">
      <p>Powered by</p>
      <img src="assets/images/landing/powered-img.svg" alt="infix" />
    </div>
  </div>
</div>
