import * as moment from "moment";
export interface SolarLiveStatusCardDetail {
  title: string;
  imgPath: string;
  today: number;
  week: number;
  month: number;
  year: number;
  lifeTime: number;
  preMeasurement: string;
  postMeasurement: string;
  roundDigit: number;
}

export interface SolarLiveStatusData {
  userId: number;
  residenceId: number;
  residenceName: string;
  locationLatitude: string;
  locationLongitude: string;
  numberOfActiveDevice: number;
  status: boolean;
  lastUpdatedTime: Date;
  generation: number;
  import: number;
  export: number;
  energy: SolarLiveStatusCardDetail;
  carbon: SolarLiveStatusCardDetail;
  financial: SolarLiveStatusCardDetail;
  selfSufficient: SolarLiveStatusCardDetail;
}

export interface SolarData {
  labelXAxis: string;
  used: number;
  generated: number;
  exported: number;
  imported: number;
  saved: number;
}
export interface CustomSolarData {
  residenceId: number;
  residenceName: string;
  locationLatitude: string;
  locationLongitude: string;
  solarData: SolarData[];
}

export interface Solar {
  timeStamEnumId: number;
  residenceId: number;
}

export const SOLAR_FIELD_NAMES = [
  {
    id: "used",
    label: "Used",
  },
  {
    id: "generated",
    label: "Generated",
  },
  {
    id: "exported",
    label: "Exported",
  },
  {
    id: "imported",
    label: "Imported",
  },
  {
    id: "saved",
    label: "Saved",
  },
];

export enum TimeStampId {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
  Today = 5,
  // CurrentWeek = 6,
  // CurrentMonth = 7,
  // CurrentYear = 8,
  Custom = 5,
  DayPerMinute = 6,
  DayPer15Minute = 7,
}

export const TimeStamEnum = [
  {
    id: TimeStampId.Day,
    label: "Day",
    active: true,
    chartTitle: moment().format("dddd"),
  },
  {
    id: TimeStampId.Week,
    label: "Week",
    active: false,
    chartTitle: `${moment()
      .startOf("isoWeek")
      .format("DD MMM")} - ${moment().endOf("isoWeek").format("DD MMM")}`,
  },
  {
    id: TimeStampId.Month,
    label: "Month",
    active: false,
    chartTitle: `${moment().format("MMMM")}`,
  },
  {
    id: TimeStampId.Year,
    label: "Year",
    active: false,
    chartTitle: `${moment().format("yyyy")}`,
  },
];

export interface CarbonData {
  labelXAxis: string;
  used: number;
  saved: number;
}

export const CARBON_FIELD_NAMES = [
  {
    id: "used",
    label: "Used",
  },
  {
    id: "saved",
    label: "Saved",
  },
];

export const SELF_SUFFICIENT_FIELD_NAMES = [
  {
    id: "used",
    label: "Used",
  },
  {
    id: "generated",
    label: "Generated",
  },
];

export interface WeatherData {
  lable: string;
  temp: number;
}

export interface TimeStampSolar {
  id: number;
  label: string;
  active: boolean;
  chartTitle: string;
}

export const CommonCartArray = [
  {
    title: "Energy",
    imgPath: "assets/images/energy.svg",
    postMeasurement: " KWh",
    preMeasurement: "",
    dataKey: "energy",
    roundDigit: 4,
  },
  {
    title: "Carbon",
    imgPath: "assets/images/carbon.svg",
    postMeasurement: " KgCO2e",
    preMeasurement: "",
    dataKey: "carbon",
    roundDigit: 4,
  },
  {
    title: "Financial",
    imgPath: "assets/images/financial.svg",
    postMeasurement: "",
    preMeasurement: "AED ",
    dataKey: "financial",
    roundDigit: 4,
  },
  {
    title: "Self Sufficiency",
    imgPath: "assets/images/self-sufficiency.svg",
    postMeasurement: "%",
    preMeasurement: "",
    dataKey: "selfSufficient",
    roundDigit: 2,
  },
];

export const SolarTableHeading = [
  {
    id: "Energy",
    name: "KWH",
  },
  {
    id: "Carbon",
    name: "KGCO2",
  },
  {
    id: "Financial",
    name: "FINANCIAL",
  },
  {
    id: "self-sufficiency",
    name: "SELF SUFFICIENCY",
  },
];

export enum SolarTableKey {
  Energy = "Energy",
  Carbon = "Carbon",
  Financial = "Financial",
  selfSufficiency = "Self Sufficiency",
}
