import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { CONSTANTS } from "src/main/shared/constants/constants";
import {
  ActionSignalR,
  ActionTypeSignalR,
  DataTableNotification,
  NotificationData,
  NotificationObjectTypeId,
  ObjectTypeIdSignalR,
  ResponseCustom,
  SignalRData,
  UserPermissionModel,
  ProductType,
} from "src/main/shared/models";
import {
  AuthenticationService,
  TabModuleService,
  UserPermissionService,
} from "src/main/shared/services";
import { FirebaseNotificationService } from "src/main/shared/services/firebase-notification.service";
import * as signalR from "@microsoft/signalr";
import { Subscription, catchError, debounceTime, map, of, takeUntil, tap } from "rxjs";
import { DataRealtimeHubService } from "src/main/shared/services/data-realtime-hub.service";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { DataLogState } from "../data-log/state/data-log.reducer";
import { resetDataLog } from "../data-log/state/data-log.actions";
import { resetAnalyticsState } from "../analytics/state/analytics.actions";
import { resetReportState } from "../report/state/report.actions";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {

  hubConnection: signalR.HubConnection;
  user: UserPermissionModel;
  data: DataTableNotification;
  dateFormat = CONSTANTS.DEFAULT_DATE_FORMAT;
  isOpenDrawer = false;
  notificationCount = 0;
  showSpinner = false;
  showMore = false;
  isAdmin = false;
  showOnlyUnread = false;

  _isFetch = false;
  _reHubConnection$: Subscription;

  readonly NotificationObjectTypeId = NotificationObjectTypeId;
  readonly ProductType = ProductType;

  constructor(
    private userPermissionService: UserPermissionService,
    private authenticationService: AuthenticationService,
    private tabService: TabModuleService,
    private notificationService: FirebaseNotificationService,
    private dataRealtimeHubService: DataRealtimeHubService,
    private store: Store
  ) { }

  ngOnInit() {
    this.user = this.userPermissionService.getUserInfo();
    this.countUnreadNotification();
    setTimeout(() => {
      this._connectHub();
      this._reConnectHub();
      this._joinGroup();
    }, 1000);
  }

  async logout() {
    this.showSpinner = true;
    this.store.dispatch(resetDataLog());
    this.store.dispatch(resetAnalyticsState());
    this.store.dispatch(resetReportState());
    // Delete the device associated with the user's notifications
    await this.notificationService.deleteDevice()?.subscribe();
    (await this.authenticationService.logout()).subscribe({
      next: (res: ResponseCustom) => {
        // Clear local storage
        localStorage.clear();
        // Clear the tab service
        this.tabService.clearTab();
        // Clear user data in the user permission service
        this.userPermissionService.setUserDataNull();
        this.showSpinner = false;
      },
      error: (error) => {
        this.showSpinner = false;
      },
    });
  }

  // Check if the current page is a landing page
  isLanding() {
    const url = window.location.href;
    if (RegExp(/landing/).exec(url)) return true;
    return false;
  }

  // get header logo as per user login
  getProductImg() {
    if (this.user.productTypeEnumId === this.ProductType.QLensPlus) {
      return "assets/images/logo-plus.svg";
    } else if (this.user.productTypeEnumId === this.ProductType.QLensLite) {
      return "assets/images/logo-lite.svg";
    } else if (this.user.productTypeEnumId === this.ProductType.Operations) {
      return "assets/images/landing/logo-lens-white.svg";
    } else {
      return "assets/images/landing/logo-lens-white.svg";
    }
  }

  // This function is triggered when we click on the bell icon to open or close the notification drawer.
  onNotificationDrawer() {
    // Toggle the isOpenDrawer property to open or close the drawer
    this.isOpenDrawer = !this.isOpenDrawer;

    // If the drawer is opened, fetch the list of notifications
    if (this.isOpenDrawer) {
      this._reHubConnection$ = this._fetchNotifications().subscribe(data => this.data = data);
    }
  }

  // Method to delete a notification
  deleteNotification(id: number) {
    this.showSpinner = true;
    this._reHubConnection$ = this.notificationService.deleteNotification(id)
      .pipe(
        tap(() => {
          this.showSpinner = false
          this.data.data = this.data?.data.filter(data => data.id !== id)
        }),
        catchError(() => of(this.showSpinner = false))
      )
      .subscribe();
  }

  //This function used for get all unread notification count.
  countUnreadNotification() {
    this.showSpinner = true;
    this._reHubConnection$ = this.notificationService.countUnreadNotification()
      .pipe(
        map((res: ResponseCustom) => this.notificationCount = res?.result?.total),
        tap(() => this.showSpinner = false),
        catchError(() => of(this.showSpinner = false))
      )
      .subscribe();
  }

  //This function used for all notifications as read.
  markAllAsReadNotification() {
    this.showSpinner = true;
    this._reHubConnection$ =
      this.notificationService.markAllAsReadNotification()
        .pipe(
          tap(() => {
            this.data.data = this.data.data.map((item) => ({
              ...item,
              isRead: true
            }))
            this.showSpinner = false;
            this.notificationCount = 0;
          }),
          catchError((error) => {
            this.showSpinner = false;
            return of(error)
          })
        )
        .subscribe()
  }

  // This function used for read single notification
  singleReadNotification(item: NotificationData) {
    if (item.isRead) return;
    this.showSpinner = true;
    this._reHubConnection$ = this.notificationService.singleReadNotification(item.id)
      .pipe(
        tap(() => { item.isRead = true, this.showSpinner = false, this.notificationCount -= 1 }),
        catchError(() => of(this.showSpinner = false))
      )
      .subscribe();
  }

  // Method to parse JSON description string
  parseDescriptionToJson(description: string): any {
    try {
      return JSON.parse(description);
    } catch (error) {
      return {};
    }
  }

  // Method to toggle showMore
  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  onShowUnreadNotifications(event) {
    this._reHubConnection$ = this._fetchNotifications()
      .subscribe(data =>
        this.data = data
      )
  }

  onScrollEnd(event: any) {
    if (
      !!this.data &&
      !this._isFetch &&
      !!this.data?.data?.length &&
      this.data?.size &&
      event.target.scrollHeight -
      event.target.clientHeight -
      event.target.scrollTop <=
      30
    ) {

      const totalPages = Math.ceil(this.data.total / this.data.size) || 0;
      const page = this.data.page + 1;
      if (totalPages >= page) {
        this._isFetch = true;
        this._fetchNotifications(this.data?.size, page)
          .pipe(
            debounceTime(200)
          )
          .subscribe(
            (data: any) =>
              this.data = {
                data: this.data.data.concat(data.data),
                size: data.size,
                page: data.page,
                total: data.total,
              }
          )
        this._isFetch = false;
      }
    }
  };

  ngOnDestroy() {
    if (this._reHubConnection$)
      this._reHubConnection$.unsubscribe();
  }

  //Private function declarations

  // Method to list all notifications
  _fetchNotifications(size?: number, page?: number) {
    const params = {
      beforeTime: moment
        .utc()
        .format(CONSTANTS.DEFAULT_DATE_FORMAT_API)
        .toString(),
      isRead: this.showOnlyUnread,
      includeDeleted: false,
    };
    this.showSpinner = true;
    return this.notificationService
      .listNotification(
        params,
        size || CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE,
        page || CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER,
      ).pipe(
        map((res: ResponseCustom) => res.result),
        tap(() => this.showSpinner = false),
        catchError((error) => {
          this.showSpinner = false;
          return of(error)
        })
      )
  }

  /**
   * /-------------SignalR---------------/
   */
  async _connectHub() {
    try {
      const hubConnection = await this.dataRealtimeHubService.getHubConnect();
      if (!hubConnection) {
        setTimeout(() => {
          this._connectHub();
        }, 1000);
        return;
      }
      this.hubConnection = hubConnection;
    } catch (r) {
      setTimeout(() => {
        this._connectHub();
      }, 1000);
    }
  }

  _reConnectHub() {
    this._reHubConnection$ =
      this.dataRealtimeHubService.reHubConnection.subscribe((isReConnect) => {
        if (isReConnect) {
          this._connectHub();
          this.dataRealtimeHubService.setReHubConnection(false);
        }
      });
  }

  //This function used for Join notification Group SignalR
  _joinGroup() {
    if (!this.hubConnection) {
      setTimeout(() => {
        this._joinGroup();
      }, 1000);
      return;
    }
    setTimeout(() => {
      this.hubConnection
        .invoke(ActionTypeSignalR.JOIN_GROUP, ObjectTypeIdSignalR.Notification)
        .then(() => {
          console.log("[Notification] Join group success");
          this._handleListnerCreate();
          this._handleListnerRead();
          this._handleListnerReadAll();
          this._handleListnerDelete();
        })
        .catch((e) => {
          setTimeout(() => this._joinGroup(), 1000);
        });
    }, 1000);
  }

  //Leave SignalR Notification group
  _leaveGroup() {
    if (!this.hubConnection) return;
    this.hubConnection
      .invoke(ActionTypeSignalR.LEAVE_GROUP, ObjectTypeIdSignalR.Notification)
      .then(() => {
        this.hubConnection.off(ActionSignalR.NewNotification);
        this.hubConnection.off(ActionSignalR.ReadNotification);
        this.hubConnection.off(ActionSignalR.MarkAllAsRead);
        this.hubConnection.off(ActionSignalR.DeleteNotification);
      })
      .catch(() => {
        setTimeout(() => this._leaveGroup(), 1000);
      });
  }

  //Add SignalR event
  //Whenever any notification add then add event fired from BE so here we received it.
  _handleListnerCreate() {
    this.hubConnection.on(
      ActionSignalR.NewNotification,
      (dataResult: SignalRData) => {
        const { data, objectTypeId } = dataResult;
        if (objectTypeId !== ObjectTypeIdSignalR.Notification) return;
        this._addNotificationItem(data);
        this.notificationCount += 1;
      },
    );
  }

  //Mark as read SignalR event
  //Whenever any notification mark as read then this event fired from BE so here we received it.
  _handleListnerRead() {
    this.hubConnection.on(
      ActionSignalR.ReadNotification,
      (dataResult: SignalRData) => {
        const { data, objectTypeId } = dataResult;
        if (objectTypeId !== ObjectTypeIdSignalR.Notification) return;
        this._updateReadNotificationItem(data);
        if (this.notificationCount > 0) {
          this.notificationCount -= 1;
        }
      },
    );
  }

  //Read all SignalR event
  //Whenever any notification read all then this event fired from BE so here we received it.
  _handleListnerReadAll() {
    this.hubConnection.on(
      ActionSignalR.MarkAllAsRead,
      (dataResult: SignalRData) => {
        const { data, objectTypeId } = dataResult;
        if (objectTypeId !== ObjectTypeIdSignalR.Notification) return;
        this._updateReadAllNotificationItem(data);
        this.notificationCount = 0;
      },
    );
  }

  //Delete SignalR event
  //Whenever any notification delete then delete event fired from BE so here we received it.
  _handleListnerDelete() {
    this.hubConnection.on(
      ActionSignalR.DeleteNotification,
      (dataResult: SignalRData) => {
        const { objectId, objectTypeId, data } = dataResult;
        if (objectTypeId !== ObjectTypeIdSignalR.Notification) return;
        if (!data?.isRead && this.notificationCount > 0) {
          this.notificationCount -= 1;
        }
        this._removeNotificationItem(objectId);
      },
    );
  }

  //Add notification in list.
  _addNotificationItem(item: NotificationData) {
    if (!this.data) this.data = { page: 1, size: 10, data: [], total: 0 };
    if (!this.data.data) this.data.data = [];
    this.data.data.unshift(item);
    this.data.total = this.data?.total + 1;
  }

  //Delete notification from list.
  _removeNotificationItem(id: number) {
    if (!this.data) this.data = { page: 1, size: 1, data: [], total: 0 };
    const newData = this.data.data.filter((item) => item.id !== id);
    if (newData.length < this.data.data.length)
      this.data.total = this.data.total - 1;
    this.data.data = newData;
  }

  //Read single notification.
  _updateReadNotificationItem(data: NotificationData) {
    this.data.data = this.data?.data?.map((item) => {
      if (item.id === data.id) {
        item.isRead = data.isRead;
      }
      return item;
    });
  }
  //Read all notification.
  _updateReadAllNotificationItem(data: NotificationData) {
    this.data?.data?.map((item) => {
      item.isRead = true;
      return item;
    });
  }
}
