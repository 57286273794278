import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

export class HttpErrorHandler {
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}`);
      console.error(`${error.message}`);
      console.error(error.error);
      if (error.status === 401) {
        // clear token
        localStorage.removeItem("token");
        localStorage.removeItem("intercom-hash");
        window.location.href = "/login";
      }
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(() => error);
  }
}
