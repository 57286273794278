import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { Solar } from "../models";

@Injectable({
  providedIn: "root",
})
export class SolarService {
  constructor(private _http: HttpClient) { }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //This API used for get solar live status.
  getSolarLiveStatus(data: Solar) {
    const payload = data.residenceId > 0 ? data : { timeStamEnumId: data.timeStamEnumId }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.SOLAR.GET_SOLAR_LIVE_STATUS}`,
        payload,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar energy data.
  getSolarEnergyList(data: Solar) {
    const payload = data.residenceId > 0 ? data : { timeStamEnumId: data.timeStamEnumId }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.CUSTOMSOLAR.LIST_ENERGY}`,
        payload,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar carbon data.
  getSolarCarbonList(data: Solar) {
    const payload = data.residenceId > 0 ? data : { timeStamEnumId: data.timeStamEnumId }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.CUSTOMSOLAR.LIST_CARBON}`,
        payload,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar financial data.
  getSolarFinancialList(data: Solar) {
    const payload = data.residenceId > 0 ? data : { timeStamEnumId: data.timeStamEnumId }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.CUSTOMSOLAR.LIST_FINANCIAL}`,
        payload,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get solar self-sufficience data.
  getSolarSelfSufficienceList(data: Solar) {
    const payload = data.residenceId > 0 ? data : { timeStamEnumId: data.timeStamEnumId }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.CUSTOMSOLAR.LIST_SEFL_SUFFICIENCY}`,
        payload,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
