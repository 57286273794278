import { Series } from "./chart";

export enum RankingChartColor {
  GroupAvg = "#6B71EE",
  BaseLine = "#FB757A",
}

export const RankingSeries: Series[] = [
  {
    name: "Group Avg",
    key: "groupAverage",
    color: RankingChartColor.GroupAvg,
    active: true,
  },
  {
    name: "Base Line",
    key: "baseLine",
    color: RankingChartColor.BaseLine,
    active: true,
  },
];

export const SitePieChartColors = [
  "#6B71EE",
  "#6BF28E",
  "#EFC36C",
  "#A5ABF2",
  "#ED6E6E",
  "#27FCE9",
  "#28C3F9",
  "#C471FF",
  "#FFA63F",
  "#4680F0",
];

export const ValueAxisPieChart = "percentage";

export const SitePieChartSeries: Series[] = [
  {
    name: "Site 1",
    key: "site1",
    color: SitePieChartColors[0],
    active: true,
  },
  {
    name: "Site 2",
    key: "site2",
    color: SitePieChartColors[1],
    active: true,
  },
  {
    name: "Site 3",
    key: "site3",
    color: SitePieChartColors[2],
    active: true,
  },
  {
    name: "Site 4",
    key: "site2",
    color: SitePieChartColors[3],
    active: true,
  },
  {
    name: "Site 5",
    key: "site2",
    color: SitePieChartColors[4],
    active: true,
  },
  {
    name: "Site 6",
    key: "site2",
    color: SitePieChartColors[5],
    active: true,
  },
  {
    name: "Site 7",
    key: "site2",
    color: SitePieChartColors[6],
    active: true,
  },
  {
    name: "Site 8",
    key: "site2",
    color: SitePieChartColors[7],
    active: true,
  },
  {
    name: "Site 9",
    key: "site2",
    color: SitePieChartColors[8],
    active: true,
  },
  {
    name: "Site 10",
    key: "site2",
    color: SitePieChartColors[9],
    active: true,
  },
];

export enum EnergyBarChartColor {
  Baseline = "#6B71EE",
}

export const EnergyCurrentBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: EnergyBarChartColor.Baseline,
    active: true,
  },
];

export const EnergyPercentageBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "percentage",
    color: EnergyBarChartColor.Baseline,
    active: true,
  },
];

export enum CarbonBarChartColor {
  Baseline = "#FFBE3F",
}

export const CarbonCurrentBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: CarbonBarChartColor.Baseline,
    active: true,
  },
];

export const CarbonPercentageBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "percentage",
    color: CarbonBarChartColor.Baseline,
    active: true,
  },
];

export enum FinanceBarChartBarChartColor {
  Baseline = "#31B854",
}

export const FinanceCurrentBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: FinanceBarChartBarChartColor.Baseline,
    active: true,
  },
];

export const FinancePercentageBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "percentage",
    color: FinanceBarChartBarChartColor.Baseline,
    active: true,
  },
];

export enum EfficiencyLineChartColor {
  Pf = "#28C3F9",
}

export const EfficiencyLineChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: EfficiencyLineChartColor.Pf,
    active: true,
  },
];

export enum HarmonicsTHDIBarChartColor {
  Baseline = "#6B71EE",
}

export const HarmonicsTHDIBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: HarmonicsTHDIBarChartColor.Baseline,
    active: true,
  },
];

export enum HarmonicsTHDVBarChartColor {
  Baseline = "#6B71EE",
}

export const HarmonicsTHDVBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: HarmonicsTHDVBarChartColor.Baseline,
    active: true,
  },
];

export enum CurrentBalanceBarChartColor {
  Baseline = "#6BF28E",
}

export const CurrentBalanceBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: CurrentBalanceBarChartColor.Baseline,
    active: true,
  },
];

export enum CurrentUnBalanceBarChartColor {
  Baseline = "#F44336",
}

export const CurrentUnBalanceBarChartSeries: Series[] = [
  {
    name: "Baseline",
    key: "currentValue",
    color: CurrentUnBalanceBarChartColor.Baseline,
    active: true,
  },
];
