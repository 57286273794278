import * as general from "./general";
import * as landing from "./landing";
import * as auth from "./auth";

export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: "light",
  properties: {},
};

export const dark: Theme = {
  name: "dark",
  properties: {
    ...general.dark,
    ...landing.dark,
    ...auth.dark,
  },
};
