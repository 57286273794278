import { ListCustom } from "./response-custom";

export enum DeviceManagementDropdownSuggession {
  solarDeviceTypeId = "deviceTypeId",
  residenceId = "residenceId",
}

export interface DeviceManagementForm {
  deviceSN: string;
  solarDeviceTypeId: number;
  residenceId: number;
  isActive: boolean;
}

export interface DeviceManagementData {
  id: number;
  deviceSN: string;
  solarDeviceTypeId: number;
  residenceId: number;
  residenceName: string;
  address1: string;
  locationLatitude: string;
  locationLongitude: string;
  isActive: boolean;
  lastDataReceivedDate: Date;
}

export class DataTableDevice {
  page: number;
  size: number;
  total: number;
  data: DeviceManagementData[];
}
export const SolarDeviceType = [
  {
    id: 1,
    label: "Solar 1P",
    info: "This device type means it's connected to single phase residential unit. Phase (a) is connected to Power Grid and phase (c) is connected to Solar Panels.",
  },
  {
    id: 2,
    label: "Solar 3PG",
    info: "This device type means it's connected to three phase residential unit and installed on Power Grid.",
  },
  {
    id: 3,
    label: "Solar 3PS",
    info: "This device type means it's connected to three phase residential unit and installed on Solar Panels.",
  },
];

export enum DeviceEKeyField {
  deviceSN = "deviceSN",
  solarDeviceTypeId = "solarDeviceTypeId",
  residenceId = "residenceId",
  lastDataReceivedDate = "lastDataReceivedDate",
}

export enum DeviceStatusType {
  Enabled = "Enabled",
  Disabled = "Disabled",
  UnAssigned = "Unassigned",
}

export interface DeviceList {
  data?: ListCustom;
  residenceIds?: Array<number>;
}

export class DeviceTab {
  id: number;
  active: boolean;
  title: string;
  img: string;
  disabled: boolean;
}

export const DeviceHeaderTab: DeviceTab[] = [
  {
    id: 0,
    active: true,
    title: "Lens Lite",
    img: "assets/images/shield-blue-icon.svg",
    disabled: false,
  },
  {
    id: 1,
    active: false,
    title: "Sense",
    img: "assets/images/shield-yellow-icon.svg",
    disabled: false,
  },
  {
    id: 2,
    active: false,
    title: "Shield",
    img: "assets/images/shield-green-icon.svg",
    disabled: true,
  },
];

export enum QlensTabKey {
  lite = 0,
  sense = 1,
  shield = 2,
}

export enum QsensDeviceManagementDropdownSuggession {
  deviceTypeId = "deviceTypeId",
  groupId = "groupId",
  siteId = "siteId",
  transformerId = "transformerId",
  mainDBId = "mainDBId",
  subDBId = "subDBId",
  circuitId = "circuitId",
}

export interface QsensDeviceManagementForm {
  groupId: number;
  siteId: number;
  serialNumber: string;
  macId: string;
  deviceTypeId: number;
  deviceTypeFreeText: string;
  otherDeviceTypeFreeText: string;
  transformerId: number;
  mainDBId: number;
  subDBId: number;
  circuitId: number;
}

export interface QsensDeviceManagementData {
  id: number,
  groupId: number,
  groupName: string,
  groupAddress1: string,
  groupLocationLatitude: string,
  groupLocationLongitude: string,
  siteId: number,
  siteName: string,
  siteAddress1: string,
  siteLocationLatitude: string,
  siteLocationLongitude: string,
  serialNumber: string,
  macId: string,
  deviceTypeId: string,
  deviceTypeName: string,
  deviceTypeFreeText: string;
  otherDeviceTypeFreeText: string,
  transformerId: number,
  transformerName: string,
  mainDBId: number,
  mainDBName: string,
  subDBId: number,
  subDBName: string,
  circuitId: number,
  circuitName: string,
  isActive: boolean,
  lastSyncData: Date,
  isRegistered: boolean,
}

export class DataTableQsensDevice {
  page: number;
  size: number;
  total: number;
  data: QsensDeviceManagementData[];
}

export enum QsensDeviceEKeyField {
  macId = "macId",
  deviceTypeName = "deviceTypeName",
  groupName = "groupName",
  siteName = "siteName",
  transformerName = "transformerName",
  mainDBName = "mainDBName",
  subDBName = "subDBName",
  circuitName = "circuitName",
}

export interface QsensDeviceList {
  data?: ListCustom;
  groupIds?: Array<number>;
  siteIds?: Array<number>;
  deviceIds?: Array<number>;
  registered?: boolean;
}

export enum QsenseDeviceDropdownSuggession {
  siteIds = "siteIds",
  groupId = "groupId",
}
