import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MESSAGE } from "src/main/shared/constants/message";
import { ResponseCustom } from "src/main/shared/models";
import { AuthenticationService } from "../../shared/services";
import { catchError, of, tap } from "rxjs";
import { AppState } from "src/app/reducer/app.reducer";
import { Store } from "@ngrx/store";
import { LoginActions } from "./state/action-types";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  form: FormGroup;
  statusForm: { success: boolean; message: string } = null;
  isSubmitForm: boolean = false;
  showHidePassword: boolean = false;
  showSpinner: boolean = false;

  get getMessage() {
    return MESSAGE;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      rememberMe: [false],
    });
  }

  getName(name: string) {
    return this.form.get(name);
  }

  //This function used for get if any error in form.
  getError(name: string, error?: string, dirtyOrTouched: boolean = true) {
    const control: AbstractControl = this.form.get(name);
    if (!control) return;
    if (error) {
      if (dirtyOrTouched) {
        if (this.isSubmitForm) return control.invalid && control.errors[error];
        return (
          (control.dirty || control.touched) &&
          control.invalid &&
          control.errors[error]
        );
      } else {
        return control.invalid && control.errors[error];
      }
    } else if (dirtyOrTouched) {
      if (this.isSubmitForm) return control.invalid;
      return (control.dirty || control.touched) && control.invalid;
    }
    return control.invalid;
  }

  //This function used for show or hide password.
  showHidePass() {
    this.showHidePassword = !this.showHidePassword;
  }

  //This function used for call login API.
  onSubmit() {
    this.isSubmitForm = true;
    if (!this.form.valid) return;
    this.showSpinner = true;
    this.authenticationService.login({ ...this.form.value })
      .pipe(
        tap((user: ResponseCustom) => {
          this.isSubmitForm = false;
          this.showSpinner = false;
          this.toastr.success(MESSAGE.LOGIN_SUCC);
          this.authenticationService.setToken({
            accessToken: `${user?.result?.accessToken}`,
            refreshToken: `${user?.result?.refreshToken}`
          })
          this.store.dispatch(LoginActions.login({ ...this.form.value }))
          this.router.navigateByUrl("/landing");
        }),
        catchError((error) => {
          this.isSubmitForm = false;
          this.showSpinner = false;
          this.statusForm = {
            success: false,
            message: error
          }
          return of(error)
        })
      )
      .subscribe()
  }
}
