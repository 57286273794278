export interface GlobalIntellegenceListReq {
  groupId: number;
  qLensScoreTypeId: number;
}

export interface SiteScore {
  siteId: number;
  name: string;
  location: string;
  locationLatitude: string;
  locationLongitude: string;
  qLensScore: number;
  colorCode: string;
  isFiltered: boolean;
  isOwnSite: boolean;
  businessSectorId: number;
  businessSectorIcon: string;
}

export interface SiteRank {
  siteId: number;
  name: string;
  qLensRank: number;
  qLensScore: number;
  colorCode: string;
  isOwnSite: boolean;
  businessSectorId: number;
  businessSectorIcon: string;
}

export interface GlobalIntellegenceList {
  groupId: number;
  groupName: string;
  siteScores: SiteScore[];
  siteRanks: SiteRank[];
  groupQscore:number;
}
export const GlobalRank = [
  {
    id: 0,
    label: "Q Score",
  },
  {
    id: 4,
    label: "Energy",
  },
  {
    id: 3,
    label: "Carbon",
  },
  {
    id: 2,
    label: "Efficiency",
  },
  {
    id: 1,
    label: "Power Quality",
  },
];

export enum GlobalBenchMarkTabId {
  filters,
  globalRanking,
}

export interface GlobalBenchMarkTab {
  id: number;
  name: string;
}

export const GlobalBenchMarkTabArr: GlobalBenchMarkTab[] = [
  {
    id: GlobalBenchMarkTabId.filters,
    name: "Filters",
  },
  {
    id: GlobalBenchMarkTabId.globalRanking,
    name: "Ranking",
  },
];

export interface GlobalIndexFilterParams {
  groupId?: number;
  isGlobal: boolean;
  qLensScoreTypeId: number;
  sectorTypeIds: Array<number>;
  businessSectorIds: Array<number>;
  propertySizeTypeIds: Array<number>;
}

export enum SearchScope {
  global = "Global",
  site = "My Site",
}

export enum GlobalBenchFilterSuggession {
  businessSectorIds = "businessSectorIds",
  sectorTypeIds = "sectorTypeIds",
  propertySizeTypeIds = "propertySizeTypeIds",
}
