import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class WeatherService {
  constructor(private _http: HttpClient) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // This Api is used for get waether data
  getWeatherData(latitude: number, longitude: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.SOLAR.GET_WEATHER_DATA}/${latitude}/${longitude}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
