import { environment } from "src/environments/environment";
export const MapProperties = {
  zoom: 12,
  width: 1000,
  height: 300,
  mapType: "roadmap",
  mapBackgroundColor: "0x18235a",
  type: "road",
  mapRoadColor: "0x343f5d",
  fontColor: "0xffffff",
  markerImageURL: `${environment.webURL}/assets/images/marker.png`,
};
//It will provide static map image.
export const StaticMapImageAPIURL = `https://maps.googleapis.com/maps/api/staticmap?zoom=${MapProperties.zoom}&size=${MapProperties.width}x${MapProperties.height}&maptype=${MapProperties.mapType}&key=${environment.googleMapAPIKey}&style=color:${MapProperties.mapBackgroundColor}&style=feature:${MapProperties.type}|color:${MapProperties.mapRoadColor}&style=element:labels.text.fill|color:${MapProperties.fontColor}&style=feature:road|element:labels|visibility:off&style=feature:poi|element:labels|visibility:off&markers=icon:${MapProperties.markerImageURL}|`;

export const MapURL = "https://www.google.com/maps/search/?api=1&query=";
