<div class="expire-link">
  <div class="container">
    <div class="expire-link-wrap">
      <h1>Link Expired</h1>
      <p>
        Your link has expired, because you haven’t used it. Link expires in 24
        hours and can only be used once. Go back to home page
      </p>
      <button class="redirection-btn" routerLink="">Home Page</button>
    </div>
  </div>
</div>
