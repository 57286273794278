import { SolarTableKey } from "./solar";

export enum ResidentDropdownSuggession {
  residenceId = "residenceId",
  countryId = "countryId"
}

export interface ResidenceSeries {
  id: string;
  name: string;
  active: boolean;
}

export const ResidentSolarSeriesHeading: ResidenceSeries[] = [
  {
    id: "Energy",
    name: SolarTableKey.Energy,
    active: true,
  },
  {
    id: "Carbon",
    name: SolarTableKey.Carbon,
    active: false,
  },
  {
    id: "Financial",
    name: SolarTableKey.Financial,
    active: false,
  },
  {
    id: "Self-Sufficiency",
    name: SolarTableKey.selfSufficiency,
    active: false,
  },
];

export enum ResidenceView {
  GRAPHVIEW = "graphView",
  TABLEVIEW = "tableView",
}

export const ResidentViewKey = [
  {
    id: ResidenceView.GRAPHVIEW,
    label: "Graph View",
    active: true,
  },
  {
    id: ResidenceView.TABLEVIEW,
    label: "Table View",
    active: false,
  },
];

export enum ResidenceTable {
  RESIDENCE = "residence",
  DEVICE = "device",
  USER = "user",
}

export interface ResidenceTab {
  id: string;
  label: string;
  active: boolean;
}

export const ResidenceTableTab: ResidenceTab[] = [
  {
    id: ResidenceTable.RESIDENCE,
    label: "Residence Information",
    active: true,
  },
  {
    id: ResidenceTable.DEVICE,
    label: "Device Information",
    active: false,
  },
  {
    id: ResidenceTable.USER,
    label: "User Information",
    active: false,
  },
];
