import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusMessageComponent } from "./components/status-message/status-message.component";
import { CustomDrawerComponent } from "./components/custom-drawer/custom-drawer.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonDirectivesModule } from "./directives/directive.module";
import { EditSVGComponent } from "src/assets/svg/edit.component";
import { CloudSVGComponent } from "src/assets/svg/cloud.component";
import { DeleteSVGComponent } from "src/assets/svg/delete.component";
import { TabComponent } from "./components/tab/tab.component";
import { EyeSVGComponent } from "src/assets/svg/view.component";
import { ExportSolarSVGComponent } from "src/assets/svg/export-solar.component";
import { GridSolarSVGComponent } from "src/assets/svg/grid-solar.component";
import { HomeSolarSVGComponent } from "src/assets/svg/home-solar.component";
import { SubBottomTabComponent } from "./components/sub-bottom-tab/sub-bottom-tab.component";
import { GroupComponent } from "src/assets/svg/group.component";
import { DeviceManagementComponent } from "src/assets/svg/device-management.component";
import { ResidenceManagementComponent } from "src/assets/svg/residence-management.component";
import { PillDrawerComponent } from "./components/pill-drawer/pill-drawer.component";
import { EmptyComponent } from "./components/empty/empty.component";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { UtcToLocalDateConvertPipe } from "./pipes/utc-to-local-date";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { RoundDigitPipe } from "./pipes/round-digit.pipe";
import { ChartIndicatorsComponent } from "./components/chart-indicators/chart-indicators.component";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { ColumnBarChartComponent } from "./components/column-bar-chart/column-bar-chart.component";
import { SolarCommonTableComponent } from "./components/solar-common-table/solar-common-table.component";
import { HideShowColumnComponent } from "./components/hide-show-column/hide-show-column.component";
import { PerformanceTableComponent } from "./components/performance-table/performance-table.component";
import { PieChartComponent } from "./components/pie-chart/pie-chart.component";
import { SolarTimeFilterComponent } from "./components/solar-time-filter/solar-time-filter.component";
import { ModalComponent } from "./components/modal/modal.component";
import { DonutChartComponent } from "./components/donut-chart/donut-chart.component";
import { GaugeChartComponent } from "./components/gauge-chart/gauge-chart.component";
import { HorizontalColumnBarChartComponent } from "./components/horizontal-column-bar-chart/horizontal-column-bar-chart.component";
import { DatePickerComponent } from "./components/date-picker-component/date-picker.component";
import { AngularMyDatePickerModule } from "@nodro7/angular-mydatepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { LineBarChartComponent } from './components/line-bar-chart/line-bar-chart.component';
import { ChartTypesComponent } from './components/chart-types/chart-types.component';

@NgModule({
  declarations: [
    StatusMessageComponent,
    CustomDrawerComponent,
    DropdownComponent,
    AutocompleteComponent,
    LoaderComponent,
    CloudSVGComponent,
    EditSVGComponent,
    DeleteSVGComponent,
    EditSVGComponent,
    EyeSVGComponent,
    GridSolarSVGComponent,
    HomeSolarSVGComponent,
    TabComponent,
    ExportSolarSVGComponent,
    SubBottomTabComponent,
    GroupComponent,
    DeviceManagementComponent,
    ResidenceManagementComponent,
    PillDrawerComponent,
    EmptyComponent,
    DateAgoPipe,
    UtcToLocalDateConvertPipe,
    RoundDigitPipe,
    BreadcrumbsComponent,
    ChartIndicatorsComponent,
    TooltipComponent,
    LineChartComponent,
    ColumnBarChartComponent,
    SolarCommonTableComponent,
    HideShowColumnComponent,
    PerformanceTableComponent,
    PieChartComponent,
    SolarTimeFilterComponent,
    ModalComponent,
    DonutChartComponent,
    GaugeChartComponent,
    HorizontalColumnBarChartComponent,
    DatePickerComponent,
    LineBarChartComponent,
    ChartTypesComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    CommonDirectivesModule,
    AngularMyDatePickerModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule
  ],
  exports: [
    StatusMessageComponent,
    CustomDrawerComponent,
    DropdownComponent,
    AutocompleteComponent,
    LoaderComponent,
    CommonDirectivesModule,
    CloudSVGComponent,
    EditSVGComponent,
    EyeSVGComponent,
    DeleteSVGComponent,
    EditSVGComponent,
    GridSolarSVGComponent,
    HomeSolarSVGComponent,
    TabComponent,
    ExportSolarSVGComponent,
    SubBottomTabComponent,
    PillDrawerComponent,
    ResidenceManagementComponent,
    EmptyComponent,
    DateAgoPipe,
    RoundDigitPipe,
    UtcToLocalDateConvertPipe,
    ChartIndicatorsComponent,
    BreadcrumbsComponent,
    TooltipComponent,
    LineChartComponent,
    ColumnBarChartComponent,
    SolarCommonTableComponent,
    HideShowColumnComponent,
    PerformanceTableComponent,
    PieChartComponent,
    SolarTimeFilterComponent,
    ModalComponent,
    DonutChartComponent,
    GaugeChartComponent,
    HorizontalColumnBarChartComponent,
    DatePickerComponent,
    LineBarChartComponent
  ],
})
export class SharedModule { }
