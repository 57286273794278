export enum ContactUsDropdownSuggession {
  groupId = "groupId",
  siteId = "siteId",
  deviceId = "deviceId",
}

export const ContactTopicType = [
  {
    id: 1,
    label: "Technical",
  },
  {
    id: 2,
    label: "Commercial",
  },
  {
    id: 3,
    label: "General",
  },
];

export interface ContactUsDetails {
  id: number;
  title: string;
  description: string;
  phoneNumber: string;
  email: string;
  webSite: string;
  countryId: number;
  countryName: string;
  stateCityEmirateId: number;
  stateCityEmirateName: string;
  cityName: string;
}

export interface ContactUsForm {
  username: string;
  userEmail: string;
  queryType: number;
  groupId: number;
  siteId: number;
  deviceSN: string;
  message: string;
}
