export const dark: { [key: string]: string } = {
  "--landing-bg-page":
    "linear-gradient(187.24deg, #023047 0.09%, #05293a 100%), #ffffff",
  "--landing-bg-box-center":
    "linear-gradient(187.24deg, #023047 0.09%, #05293a 100%), #ffffff',radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.25) 0%, #ffffff 76.35%)",
  "--landing-box-shadow-box-center":
    "-6.84px -6.84px 17.1px 17.1px #093347, 6.84px 6.84px 17.1px 2.28px rgba(0, 0, 0, 0.45)",
  "--landing-color-text-menu": "#999999",
  "--landing-color-text-menu-hover": "#45b8ab",
  "--landing-border-btn-action": "none",
  "--landing-bg-btn-action": "#051924",
  "--landing-bg-btn-action-hover": "#45B8AB",
  "--landing-color-text-title-menu": "#ffffff",
  "--landing-border-circle-small-hover": "5px solid #45b8ab",
  "--landing-bg-circle-small-hover": "rgba(69, 184, 171, 0.1)",
  "--landing-bg-box-absolute-hover":
    "linear-gradient(358.22deg, #224565 13.2%, rgba(2, 62, 92, 0.4) 41.95%)",
  "--landing-bg-circle-center": "#051924",

  "--landing-icon-financial": "url(../../../assets/images/financial.svg)",
  "--landing-icon-quality": "url(../../../assets/images/power-quality.svg)",
  "--landing-icon-efficiency": "url(../../../assets/images/efficiency.svg)",
  "--landing-icon-carbon": "url(../../../assets/images/carbon.svg)",
  "--landing-icon-energy": "url(../../../assets/images/energy.svg)",
  "--landing-icon-group": "url(../../../assets/images/group.svg)",
  // '--landing-icon-commercial' : 'url(../../../assets/images/commercial.svg)',
  // '--landing-icon-technical' : 'url(../../../assets/images/technical.svg)',
  "--landing-icon-notification": "url(../../../assets/images/notification.svg)",
  "--landing-icon-alert": "url(../../../assets/images/alert-log.svg)",
  "--landing-icon-contact-us": "url(../../../assets/images/contact-us.svg)",
  "--landing-icon-user": "url(../../../assets/images/user.svg)",
  "--landing-icon-mdm": "url(../../../assets/images/mdm.svg)",
  "--landing-icon-chat": "url(../../../assets/images/chat.svg)",
  "--landing-icon-setting": "url(../../../assets/images/setting.svg)",
  "--landing-icon-live": "url(../../../assets/images/live.svg)",
  "--landing-icon-live-status": "url(../../../assets/images/live-status.svg)",
  "--landing-icon-control-panel":
    "url(../../../assets/images/control-panel.svg)",
  "--landing-icon-self-sufficience":
    "url(../../../assets/images/self-sufficiency.svg)",
  "--landing-icon-analytics": "url(../../../assets/images/analytics.svg)",
  "--landing-icon-benchmark": "url(../../../assets/images/benchmark.svg)",
  "--landing-icon-reporting": "url(../../../assets/images/reporting.svg)",
  "--landing-icon-tarrif": "url(../../../assets/images/tarrif.svg)",
  "--landing-icon-global": "url(../../../assets/images/global.svg)",
  "--landing-icon-kpi": "url(../../../assets/images/kpi.svg)",
};
