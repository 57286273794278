import { Graph, Series } from "./chart";
import { DataLogFilter } from "./data-log";
import { OptionItem } from "./residential";
import { TimeStampSolar } from "./solar";
import { CommonModel } from "./tab-module";

export interface Analytics {
  parameterTypeId?: number;
  parameterTypeIds?: Array<number>;
  // parameterCalTypeId: number;
  siteId: number;
  groupId: number;
  deviceId: number;
  sortByEnabledorDisabled: boolean;
  timeFrameId: number;
  performanceTypeId: number;
  fromDate: string;
  toDate: string;
  currencyCode: string;
}
export interface DropdownResponse {
  page: number,
  size: number,
  total: number,
  data: OptionItem[]
}

export interface Country {
  id: number
  currency: string;
  currencyName: string;
  currencySymbol: string;
  name: string
}

export interface Data {
  id: number
  labelXAxis: string;
  value: number;
  l1Value: number;
  l2Value: number;
  l3Value: number;
  totalValue: number;
}
export interface AnalyticsChartData {
  id: number
  labelXAxis: string;
  value: number;
  l1Value: number;
  l2Value: number;
  l3Value: number;
  totalValue: number;
  deviceDetail: DeviceDetail
}

export interface DeviceDetail {
  location: string;
  name: string;
  qscore: number;
  minValue: number;
  maxValue: number;
  averageValue: number;
}

export interface Response {
  data: Data[];
  deviceDetail: DeviceDetail;
}
export interface AnalyticsData {
  page: number;
  size: number;
  total: number;
  result: Response
}
export interface PeformanceFilter {
  id: string;
  value: string;
}

export interface MultiSelectParameterFilter {
  paramFilter: Performance[];
}
export interface ParameterFilter {
  paramFilter: {
    id: string;
    value: string;
  };
  phaseFilter: string;
}
export interface CompareFilter {
  siteIds: CommonModel,
  macAddresses: CommonModel,
  filters: {
    siteIds: {
      id: number;
      label: string;
    };
    macAddresses: {
      id: number;
      label: string;
    };
  };
  dateRange: {
    startDate: string;
    endDate: string;
  };
  timeFrameId: number;
  timeStamp: TimeStampSolar;
}
export interface AnalyticsFilters {
  performanceFilters: PeformanceFilter;
  paramFilters: MultiSelectParameterFilter;
  parameterFilters: ParameterFilter;
  compareFilters: CompareFilter;
  timeStamp: TimeStampSolar,
  timeHr: DataLogFilter,
  dateRange: {
    startDate: string,
    endDate: string
  }
}

export enum QsenseAnalyticsDropdownSuggession {
  macAddresses = "macAddresses",
  siteIds = "siteIds",
  groupId = "groupId",
  countryId = "countryId",
}

export enum AnalyticsColor {
  Bar = "#6B71EE",
  Line = "#FB757A",
  Site = "#6B71EE",
  Device = "#EFC36C",
  CompareSite = "#EFC36C",
  CompareDevice = "#6B71EE",
  Phase1 = "#6B71EE",
  Phase2 = "#27FCE9",
  Phase3 = "#C471FF",
  Total = "#0992df",
  ComparePhase1 = "#DC3226",
  ComparePhase2 = "#2C6FFF",
  ComparePhase3 = "#9B2AE5",
  CompareTotal = "#AFABF2",
  AverageValue = "#AECA3D",
  CompareAverageValue = "#CD0E56",
}
// Phase1 = "#C471FF",
// Phase2 = "#D0E4A0",
// Phase3 = "#28C3F9",
// Total = "#6B71EE",
// ComparePhase1 = "#ED6E6E",
// ComparePhase2 = "#6BF28E",
// ComparePhase3 = "#EFC36C",
// CompareTotal = "#27FCE9",

export type Parameter = {
  id: string;
  key: string;
  value: string;
  seriesLegends: Series[];
  compareSeriesLegends: Series[]
};

export type Performance = {
  id: string;
  value: string;
};

export type Phase = {
  id: string;
  active: boolean;
  value: string;
};

export enum AnalyticsFilterTabId {
  performance,
  parameter,
}
export interface AnalyticsFilterTab {
  id: number;
  name: string;
}

export const AnalyticsFilterTabArr: AnalyticsFilterTab[] = [
  {
    id: AnalyticsFilterTabId.performance,
    name: "Performance",
  },
  {
    id: AnalyticsFilterTabId.parameter,
    name: "Parameter",
  },
];

export const ParameterFilters: Parameter[] = [
  {
    id: "1",
    key: 'KVA',
    value: "kVA",
    seriesLegends: [{
      id: 1,
      name: "L1",
      key: "l1Value",
      color: AnalyticsColor.Phase1,
      active: true,
      seriesColor: true,
    },
    {
      id: 1,
      name: "L2",
      key: "l2Value",
      color: AnalyticsColor.Phase2,
      active: true,
      seriesColor: true,
    },
    {
      id: 1,
      name: "L3",
      key: "l3Value",
      color: AnalyticsColor.Phase3,
      active: true,
      seriesColor: true,
    },
    {
      id: 1,
      name: "Total",
      key: "totalValue",
      color: AnalyticsColor.Total,
      active: true,
      seriesColor: true,
    },
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Total",
        key: "totalValue",
        color: AnalyticsColor.CompareTotal,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "3",
    key: "KVAR",
    value: "kVAR",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Total",
        key: "totalValue",
        color: AnalyticsColor.Total,
        active: true,
        seriesColor: true,
      },
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Total",
        key: "totalValue",
        color: AnalyticsColor.CompareTotal,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "5",
    key: "THDI_PER",
    value: "THDI%",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.AverageValue,
        active: true,
        seriesColor: true,
      }
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.CompareAverageValue,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "7",
    key: "Voltage",
    value: "Voltage",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.AverageValue,
        active: true,
        seriesColor: true,
      }
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.CompareAverageValue,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "2",
    key: "KW",
    value: "kW",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Total",
        key: "totalValue",
        color: AnalyticsColor.Total,
        active: true,
        seriesColor: true,
      },
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Total",
        key: "totalValue",
        color: AnalyticsColor.CompareTotal,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "4",
    key: "PF",
    value: "PF",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.AverageValue,
        active: true,
        seriesColor: true,
      },
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.CompareAverageValue,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "6",
    key: "THDV_PER",
    value: "THDV%",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.AverageValue,
        active: true,
        seriesColor: true,
      },
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.CompareAverageValue,
        active: true,
        seriesColor: true,
      },
    ]
  },
  {
    id: "8",
    key: "Amps",
    value: "Amps",
    seriesLegends: [
      {
        id: 1,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.Phase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.Phase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.Phase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 1,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.AverageValue,
        active: true,
        seriesColor: true,
      }
    ],
    compareSeriesLegends: [
      {
        id: 2,
        name: "L1",
        key: "l1Value",
        color: AnalyticsColor.ComparePhase1,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L2",
        key: "l2Value",
        color: AnalyticsColor.ComparePhase2,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "L3",
        key: "l3Value",
        color: AnalyticsColor.ComparePhase3,
        active: true,
        seriesColor: true,
      },
      {
        id: 2,
        name: "Average",
        key: "averageValue",
        color: AnalyticsColor.CompareAverageValue,
        active: true,
        seriesColor: true,
      },
    ]
  },
];

export const PhaseFilters: Phase[] = [
  {
    id: "1",
    active: false,
    value: "L1",
  },
  {
    id: "2",
    active: false,
    value: "L2",
  },
  {
    id: "3",
    active: false,
    value: "L3",
  },
  {
    id: "4",
    active: true,
    value: "Total",
  },
];

export const PerformanceFilters: Performance[] = [
  {
    id: "11",
    value: "Q-Performance",
  },
  {
    id: "1",
    value: "Energy",
  },
  {
    id: "2",
    value: "Carbon",
  },
  {
    id: "4",
    value: "Efficiency",
  },
  {
    id: "5",
    value: "Power Quality",
  },
];

export enum PHASE_LIST {
  PHASE1 = "l1Value",
  PHASE2 = "l2Value",
  PHASE3 = "l3Value",
  TOTAL = "totalValue",
  AVERAGE = "averageValue",
}

export const PhaseSeries: Series[] = [
  {
    id: 1,
    name: "L1",
    key: "l1Value",
    color: AnalyticsColor.Phase1,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "L2",
    key: "l2Value",
    color: AnalyticsColor.Phase2,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "L3",
    key: "l3Value",
    color: AnalyticsColor.Phase3,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "Total",
    key: "totalValue",
    color: AnalyticsColor.Total,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "Average",
    key: "averageValue",
    color: AnalyticsColor.AverageValue,
    active: true,
    seriesColor: true,
  },
];

export const ComparePhaseSeries: Series[] = [
  {
    id: 1,
    name: "L1",
    key: "l1Value",
    color: AnalyticsColor.Phase1,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "L2",
    key: "l2Value",
    color: AnalyticsColor.Phase2,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "L3",
    key: "l3Value",
    color: AnalyticsColor.Phase3,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "Total",
    key: "totalValue",
    color: AnalyticsColor.Total,
    active: true,
    seriesColor: true,
  },
  {
    id: 1,
    name: "Average",
    key: "averageValue",
    color: AnalyticsColor.AverageValue,
    active: true,
    seriesColor: true,
  },
  {
    id: 2,
    name: "L1",
    key: "l1Value",
    color: AnalyticsColor.ComparePhase1,
    active: true,
    seriesColor: true,
  },
  {
    id: 2,
    name: "L2",
    key: "l2Value",
    color: AnalyticsColor.ComparePhase2,
    active: true,
    seriesColor: true,
  },
  {
    id: 2,
    name: "L3",
    key: "l3Value",
    color: AnalyticsColor.ComparePhase3,
    active: true,
    seriesColor: true,
  },
  {
    id: 2,
    name: "Total",
    key: "totalValue",
    color: AnalyticsColor.CompareTotal,
    active: true,
    seriesColor: true,
  },
  {
    id: 2,
    name: "Average",
    key: "averageValue",
    color: AnalyticsColor.CompareAverageValue,
    active: true,
    seriesColor: true,
  },
];
export const currData2TotalSeries: Series[] = [
  {
    id: 3,
    name: "L1",
    key: "l1Value",
    color: "#ED6E6E",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "L2",
    key: "l2Value",
    color: "#28C3FE",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "L3",
    key: "l3Value",
    color: "#6BF28E",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "Total",
    key: "totalValue",
    color: "#EFC36C",
    active: true,
    seriesColor: true,
  },
];
export const currData2AvgSeries: Series[] = [
  {
    id: 3,
    name: "L1",
    key: "l1Value",
    color: "#ED6E6E",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "L2",
    key: "l2Value",
    color: "#28C3FE",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "L3",
    key: "l3Value",
    color: "#6BF28E",
    active: true,
    seriesColor: true,
  },
  {
    id: 3,
    name: "Average",
    key: "averageValue",
    color: "#EFC36C",
    active: true,
    seriesColor: true,
  },
];
export const compData2TotalSeries: Series[] = [
  {
    id: 4,
    name: "L1",
    key: "l1Value",
    color: "#FFA33A",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "L2",
    key: "l2Value",
    color: "#ED6EC7",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "L3",
    key: "l3Value",
    color: "#36AA43",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "Total",
    key: "totalValue",
    color: "#9A553E",
    active: true,
    seriesColor: true,
  },
];
export const compData2AvgSeries: Series[] = [
  {
    id: 4,
    name: "L1",
    key: "l1Value",
    color: "#FFA33A",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "L2",
    key: "l2Value",
    color: "#ED6EC7",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "L3",
    key: "l3Value",
    color: "#36AA43",
    active: true,
    seriesColor: true,
  },
  {
    id: 4,
    name: "Average",
    key: "averageValue",
    color: "#9A553E",
    active: true,
    seriesColor: true,
  },
];
export const totalParameter: string[] = ["kVA", "kW", "kVAR"];
export const GraphSeries: Graph[] = [
  {
    name: "Reset",
    key: 'reset',
    color: AnalyticsColor.Line,
    active: false,
    img: '/assets/images/reset.svg'
  },
  {
    name: "Line",
    key: "value",
    color: AnalyticsColor.Line,
    active: false,
    img: "/assets/images/line-graph-icon-inactive.svg",
  },
  {
    name: "Bar",
    key: "value",
    color: AnalyticsColor.Bar,
    active: true,
    img: "/assets/images/bar-graph-icon-active.svg",
  },
  {
    name: "Expand",
    key: "value",
    color: AnalyticsColor.Bar,
    active: false,
    img: "/assets/images/expand-state.svg",
  },
];

export const toAnalytics = (
  performanceFilters,
  parameterFilters,
  // phaseFilter,
  siteId,
  user,
  group,
  deviceId,
  selectedTimeStamp,
  startDate,
  endDate,
  currencyCode
): Analytics => {

  return {
    parameterTypeId: 0,
    // parameterCalTypeId: +phaseFilter,
    siteId: siteId ? +siteId.id : 0,
    groupId: user?.group?.id ? user.group.id : group?.id,
    deviceId: deviceId ? deviceId?.id : 0,
    sortByEnabledorDisabled: true,
    timeFrameId: selectedTimeStamp ? selectedTimeStamp : 0,
    performanceTypeId: +performanceFilters.id ? +performanceFilters.id : 0,
    fromDate: startDate ? startDate : null,
    toDate: endDate ? endDate : null,
    currencyCode: currencyCode ? currencyCode : "",
  }
};

export const toParameterAnalytics = (
  performanceFilters,
  parameterFilters,
  // phaseFilter,
  siteId,
  user,
  group,
  deviceId,
  selectedTimeStamp,
  startDate,
  endDate,
  currencyCode
): Analytics => {

  return {
    parameterTypeId: 1,
    parameterTypeIds: parameterFilters.length > 0 ? parameterFilters.map(dt => +dt.id) : 0,
    // parameterCalTypeId: +phaseFilter,
    siteId: siteId ? +siteId.id : 0,
    groupId: user?.group?.id ? user.group.id : group?.id,
    deviceId: deviceId ? deviceId?.id : 0,
    sortByEnabledorDisabled: true,
    timeFrameId: selectedTimeStamp ? selectedTimeStamp : 0,
    performanceTypeId: +performanceFilters.id ? +performanceFilters.id : 0,
    fromDate: startDate ? startDate : null,
    toDate: endDate ? endDate : null,
    currencyCode: currencyCode ? currencyCode : "",
  }
};

export enum TIME_FRAME_IDS {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
  CUSTOM = 5,
  DAY_PER_MINUTE = 6,
  DAY_PER_15_MINUTE = 7,
  WEEK_PER_15_MINUTE = 9,
  MONTH_PER_HRS = 10,
  YEAR_PER_DAY = 11,
}

export const TIME_FRAME_DATA: DataLogFilter[] = [
  {
    id: TIME_FRAME_IDS.DAY,
    label: "Day",
    active: true,
  },
  {
    id: TIME_FRAME_IDS.WEEK,
    label: "Week",
    active: false,
  },
  {
    id: TIME_FRAME_IDS.MONTH,
    label: "Month",
    active: false,
  },
  {
    id: TIME_FRAME_IDS.YEAR,
    label: "Year",
    active: false,
  },
  {
    id: TIME_FRAME_IDS.CUSTOM,
    label: "Custom",
    active: false,
  },
];

export const TIME_FRAME_HOUR_LIST: DataLogFilter[] = [
  {
    id: TIME_FRAME_IDS.DAY,
    label: "Time: 1 hr",
    active: true,
  },
  {
    id: TIME_FRAME_IDS.DAY_PER_15_MINUTE,
    label: "Time: 15 Min",
    active: false,
  },
  {
    id: TIME_FRAME_IDS.DAY_PER_MINUTE,
    label: "Time: 1 Min",
    active: false,
  },
];
