
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, catchError, of, takeUntil, tap } from "rxjs";
import { MESSAGE } from "src/main/shared/constants/message";
import { AuthenticationService } from "src/main/shared/services";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  statusForm: { success: boolean; message: string } = null;
  isSubmitForm = false;

  ngDestroy$: Subject<void> = new Subject<void>()

  readonly MESSAGE = MESSAGE;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      userEmail: [null, [Validators.required, Validators.email]],
    });
  }

  //This function used for get if any error in form.
  getError(name: string, error?: string, dirtyOrTouched: boolean = true) {
    const control: AbstractControl = this.form.get(name);
    if (!control) return;
    if (error) {
      if (dirtyOrTouched) {
        if (this.isSubmitForm) return control.invalid && control.errors[error];
        return (
          (control.dirty || control.touched) &&
          control.invalid &&
          control.errors[error]
        );
      } else {
        return control.invalid && control.errors[error];
      }
    } else if (dirtyOrTouched) {
      if (this.isSubmitForm) return control.invalid;
      return (control.dirty || control.touched) && control.invalid;
    }
    return control.invalid;
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  //This function used for sent reset password link.
  onSubmit() {
    this.isSubmitForm = true;
    if (!this.form.valid) return;
    this.authenticationService
      .forgotPassword({ ...this.form.value })
      .pipe(
        takeUntil(this.ngDestroy$),
        tap(() => {
          this.isSubmitForm = false;
          this.toastr.success(MESSAGE.FORGOT_PASS_SUCC);
          this.router.navigateByUrl("/login");
        }),
        catchError((error) => {
          this.isSubmitForm = false;
          this.statusForm = {
            success: false,
            message: error
          }
          this.toastr.error(error)
          return of(error)
        })
      )
      .subscribe()
  }
}
