import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";
@Injectable({
  providedIn: "root",
})
export class FirebaseNotificationService {
  currentMessage = new BehaviorSubject(null);
  isGetPermission: boolean = false;
  private platformId = 1;
  private deviceToken: string;
  // Http Options
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _http: HttpClient,
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe({
      next: (res) => {
        this.deviceToken = res;
        this.isGetPermission = true;
      },
      error: (e) => {},
    });
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe({
      next: (payload) => {
        this.currentMessage.next(payload);
      },
      error: (e) => {},
    });
  }
  getDeviceToken() {
    return this.deviceToken;
  }

  getIsGetPermission() {
    return this.isGetPermission;
  }
  //This API used for add device token.
  addDevice(userId: number) {
    if (!this.deviceToken) return;
    const data = {
      userId,
      deviceToken: this.deviceToken,
      platformId: this.platformId,
    };
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.ADD_DEVICE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for delete device token.
  deleteDevice() {
    if (!this.deviceToken) return;
    const data = {
      deviceToken: this.deviceToken,
    };
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.REMOVE_DEVICE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for list notification.
  listNotification(
    data: {
      beforeTime: string;
      isRead?: boolean;
      includeDeleted: boolean;
      moduleApplicationId?: number;
    },
    size: number = 20,
    page: number = 1,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
      beforeTime: data.beforeTime,
      includeDeleted: data.includeDeleted,
    };
    if (data.isRead) {
      params["isRead"] = !data.isRead;
    }
    if (data.moduleApplicationId) {
      params["moduleApplicationId"] = data.moduleApplicationId;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.LIST_NOTIFICATION}`,
        { ...this.httpOptions, params: params },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API is used to mark all as read notification
  markAllAsReadNotification(moduleApplicationId?: number) {
    const params = {};
    if (moduleApplicationId) {
      params["moduleApplicationId"] = moduleApplicationId;
    }
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.MARK_ALL_AS_READ}`,
        {},
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for delete notification.
  deleteNotification(id: number) {
    return this._http
      .delete(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.DELETE_NOTIFICATION}${id}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API is used to read single notification
  singleReadNotification(id: number) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.SINGLE_READ}${id}`,
        {},
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get unread notification count.
  countUnreadNotification() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.NOTIFICATION.COUNT_UNREAD_NOTIFICATION}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
