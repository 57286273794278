import { createAction, props } from "@ngrx/store";
import { CommonModel, ReportFilter, ReportGroupDataList, ReportNotificationDataList, ReportRequest, ReportTab, ResponseCustom, TabModule } from "src/main/shared/models";
import { DropdownResponse } from "src/main/shared/models/analytics";


const enum reportActionTypes {
    SET_GROUP_REPORT = "[Report Page] Set Group",
    SET_SITE_REPORT = "[Report Page] Set Site",
    SET_DEVICE_REPORT = "[Report Page] Set Device",
    SET_CURRENCY = "[Report Page] Set Currency",
    SET_LOAD_FLAG = "[Report Page] Set Load Flag",
    SET_REPORT_TAB = "[Report Page] Set Selected Report Tab",
    SET_TOP_TAB = "[Report Page] Set Top Selected Tab",
    SET_BOTTOM_TAB = "[Report Page] Set Bottom  Selected Tab",
    SET_REPORT_FILTERS = "[Report Page] Set Report Filters",
    SET_NOTIFICATION_TAB = "[Report Page] Set Notification Tab",
    LOAD_GROUP_REPORT = "[Report Page] Load Group Ids",
    LOAD_GROUP_REPORT_SUCCESS = "[Report Page] Load Group Ids Success",
    LOAD_GROUP_REPORT_FAILURE = "[Report Page] Load Group Ids Failure",
    LOAD_SITE_REPORT = "[Report Page] Load Site Ids",
    LOAD_SITE_REPORT_SUCCESS = "[Report Page] Load Site Ids Success",
    LOAD_SITE_REPORT_FAILURE = "[Report Page] Load Site Ids Failure",
    LOAD_RESIDENT_REPORT = "[Report Page] Load Resident Ids",
    LOAD_RESIDENT_REPORT_SUCCESS = "[Report Page] Load Resident Ids Success",
    LOAD_RESIDENT_REPORT_FAILURE = "[Report Page] Load Resident Ids Failure",
    LOAD_COUNTRY_REPORT = "[Report Page] Load All Countries",
    LOAD_COUNTRY_REPORT_SUCCESS = "[Report Page] Load All Countries Success",
    LOAD_COUNTRY_REPORT_FAILURE = "[Report Page] Load All Countries Failure",
    LOAD_REPORT_DATA = "[Report Page] Load Reporting Data",
    LOAD_REPORT_DATA_SUCCESS = "[Report Page] Load Reporting Data Success",
    LOAD_REPORT_DATA_FAILURE = "[Report Page] Load Reporting Data Failure",
    LOAD_REPORT_NOTIFICATION = "[Report Page] Load Notification Data",
    LOAD_REPORT_NOTIFICATION_SUCCESS = "[Report Page] Load Notification Success",
    LOAD_REPORT_NOTIFICATION_FAILURE = "[Report Page] Load Notification Failure",
    RESET_REPORT_STATE = "[Report Page] Reset Report State"
}

export const loadGroupIds = createAction(
    reportActionTypes.LOAD_GROUP_REPORT,
    props<{ keyword?: string, size?: number, page?: number }>()
)

export const loadGroupIdsSuccess = createAction(
    reportActionTypes.LOAD_GROUP_REPORT_SUCCESS,
    props<{ groupIds: DropdownResponse }>()
)

export const loadGroupIdsFailure = createAction(
    reportActionTypes.LOAD_GROUP_REPORT_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadSiteIds = createAction(
    reportActionTypes.LOAD_SITE_REPORT,
    props<{ groupId: number, keyword?: string, page?: number, size?: number }>()
)

export const loadSiteIdsSuccess = createAction(
    reportActionTypes.LOAD_SITE_REPORT_SUCCESS,
    props<{ siteIds: DropdownResponse }>()
)

export const loadSiteIdsFailure = createAction(
    reportActionTypes.LOAD_SITE_REPORT_FAILURE,
    props<{ error: Partial<Error> }>()
)


export const loadResidentIds = createAction(
    reportActionTypes.LOAD_RESIDENT_REPORT,
    props<{ keyword?: string, page?: number, size?: number }>()
)

export const loadResidentIdsSuccess = createAction(
    reportActionTypes.LOAD_RESIDENT_REPORT_SUCCESS,
    props<{ residentNames: DropdownResponse }>()
)

export const loadResidentIdsFailure = createAction(
    reportActionTypes.LOAD_RESIDENT_REPORT_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadAllCountries = createAction(
    reportActionTypes.LOAD_COUNTRY_REPORT
)

export const loadAllCountriesSuccess = createAction(
    reportActionTypes.LOAD_COUNTRY_REPORT_SUCCESS,
    props<{ countries: ResponseCustom }>()
)

export const loadAllCountriesFailure = createAction(
    reportActionTypes.LOAD_COUNTRY_REPORT_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const setGroup = createAction(
    reportActionTypes.SET_GROUP_REPORT,
    props<{ group: CommonModel }>()
)

export const setSiteIds = createAction(
    reportActionTypes.SET_SITE_REPORT,
    props<{ siteIds: CommonModel }>()
)

export const setDeviceId = createAction(
    reportActionTypes.SET_DEVICE_REPORT,
    props<{ deviceId: CommonModel }>()
)

export const setLoaded = createAction(
    reportActionTypes.SET_LOAD_FLAG,
    props<{ flag: boolean }>()
)

export const setCurrency = createAction(
    reportActionTypes.SET_CURRENCY,
    props<{ currency: CommonModel }>()
)

export const setReportTab = createAction(
    reportActionTypes.SET_REPORT_TAB,
    props<{ reportTab: ReportTab }>()
)

export const setTopTab = createAction(
    reportActionTypes.SET_TOP_TAB,
    props<{ topTab: TabModule[] }>()
)

export const setBottomTab = createAction(
    reportActionTypes.SET_BOTTOM_TAB,
    props<{ bottomTab: TabModule[] }>()
)

export const setNotificationTab = createAction(
    reportActionTypes.SET_NOTIFICATION_TAB,
    props<{ notificationTab: TabModule[] }>()
)

export const setReportFilters = createAction(
    reportActionTypes.SET_REPORT_FILTERS,
    props<{ filters: ReportFilter }>()
)
export const loadReportData = createAction(
    reportActionTypes.LOAD_REPORT_DATA,
    props<{ payload: ReportRequest }>()
)

export const loadReportDataSuccess = createAction(
    reportActionTypes.LOAD_REPORT_DATA_SUCCESS,
    props<{ report: ReportGroupDataList }>()
)

export const loadReportDataFailure = createAction(
    reportActionTypes.LOAD_REPORT_DATA_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const loadNotification = createAction(
    reportActionTypes.LOAD_REPORT_NOTIFICATION,
    props<{ payload: ReportRequest }>()
)

export const loadNotificationSuccess = createAction(
    reportActionTypes.LOAD_REPORT_NOTIFICATION_SUCCESS,
    props<{ notification: ReportNotificationDataList }>()
)

export const loadNotificationFailure = createAction(
    reportActionTypes.LOAD_REPORT_NOTIFICATION_FAILURE,
    props<{ error: Partial<Error> }>()
)

export const resetReportState = createAction(
    reportActionTypes.RESET_REPORT_STATE
)