import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Globals } from "../../../app/global";
import { Theme, light, dark } from "../models/themes";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private _themeDark: Subject<boolean> = new Subject<boolean>();
  isThemeDark = this._themeDark.asObservable();
  private active: Theme = light;
  private availableThemes: Theme[] = [light, dark];

  constructor(public globals: Globals) {}

  setValueTheme(isThemeDark: boolean) {
    this._themeDark.next(isThemeDark);
    if (isThemeDark === true) {
      localStorage.setItem("darkTheme", "true");
      this.globals.isDarkTheme = "true";
    } else {
      localStorage.setItem("darkTheme", "false");
      this.globals.isDarkTheme = "false";
    }
  }

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name === dark.name;
  }

  setDarkTheme(): void {
    this.setActiveTheme(dark);
  }

  setLightTheme(): void {
    this.setActiveTheme(light);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;
    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property],
      );
    });
  }
}
