import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {
  AuthenticationService,
  TabModuleService,
  UserPermissionService,
} from "../../shared/services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userPermissionService: UserPermissionService,
    private tabService: TabModuleService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token_key = this.authenticationService.isAuthenticated();
    if (token_key) {
      await this.userPermissionService
        .getPermission()
        .then(() => {
          const [url] = state.url.split("?");
          const isPermission =
            this.userPermissionService.getPermissionRead(url);
          //If no permission and then tab is open then remove it and also navigate to landing page.
          if (!isPermission) {
            this.tabService.closeTabHeader(url);
            this.router.navigate(["/"]);
          }
          return isPermission;
        })
        .catch(() => {
          this.router.navigate(["/login"]);
          return false;
        });
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
