import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import {
  DeviceList,
  DeviceManagementForm,
} from "../models/mdm-device-management";
import { ListCustom } from "../models";

@Injectable({
  providedIn: "root",
})
export class DeviceManagementService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******  DEVICE MANAGEMENT ADMIN  ***** ///

  //This API used for create device.
  createDevice(data: DeviceManagementForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.DEVICEMANAGEMENT.CREATE_DEVICE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of devices
  listDevices(deviceData?: DeviceList) {
    const data: ListCustom = deviceData.data;
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;

    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.DEVICEMANAGEMENT.LIST_DEVICE}`,
        { ...data, residenceIds: deviceData.residenceIds },
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for Activate / deactivate device.
  activeInactiveDevice(deviceId: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.DEVICEMANAGEMENT.ACTIVE_DEACTIVE_DEVICE}/${deviceId}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This api is used for edit device data
  editDevice(id: number, data: DeviceManagementForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.DEVICEMANAGEMENT.UPDATE_DEVICE}/${id}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This Api is used for check deviceSN is exist or not
  checkDeviceSNExist(deviceSN: string) {
    const params = {
      deviceSN: deviceSN,
    };
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.DEVICEMANAGEMENT.CHECKDEVICESNEXIST}`,
        {},
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
