import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, BehaviorSubject, from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import {
  AuthenticationService,
  UserPermissionService,
} from "../../shared/services";
import { CONSTANTS } from "./../../shared/constants/constants";
import { environment } from "./../../../environments/environment";
import * as Sentry from "@sentry/browser";
import { MESSAGE } from "src/main/shared/constants/message";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private HttpErrorResponse = "HttpErrorResponse";
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  private requestData: HttpRequest<any>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userPermissionService: UserPermissionService,
  ) {
    this.startWatchStorage();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const regexURL = new RegExp(`^${environment.corsBase}.*`);
        if (
          error &&
          error.name === this.HttpErrorResponse &&
          error.status === 0 &&
          !regexURL.test(error.url)
        ) {
          Sentry.captureException(error);
        }

        if (error && error.status === 401) {
          this.requestData.push(request);
          return from(this.getNewToken()).pipe(
            switchMap((data) => {
              if (!data) {
                this.redirectLogin();
                return;
              }
              return next.handle(this.addAuthenticationToken(request)).pipe(
                catchError((error) => {
                  throw this.handleErrorRequest(error, true);
                }),
              );
            }),
            catchError((e) => {
              this.redirectLogin();
              throw e;
            }),
          );
        }
        throw this.handleErrorRequest(error);
      }),
    );
  }

  // Get new token

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authenticationService.getToken();
    if (!token) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + token),
    });
  }

  //Redirect to login page.
  private async redirectLogin() {
    await this.authenticationService.logout().catch(() => null);
    location.href = window.location.origin + "/#/login";
  }

  // This function used for handle error request.
  private handleErrorRequest(error: any, isCheckLogin: boolean = false) {
    const regexURL = new RegExp(`^${environment.corsBase}.*`);
    //HTTP 409 error status: The HTTP 409 status code (Conflict) indicates that the request could not be processed because of conflict in the request
    if (error.status === 500) {
      return "Server encountered internal error and unable to process your request.";
    }
    //If we call API for get access token based refresh token & still will get error then will redirect on login page.
    if (isCheckLogin && error.status === 401) {
      this.redirectLogin();
      return;
    }

    //HTTP 409 error status: The HTTP 409 status code (Conflict) indicates that the request could not be processed because of conflict in the request
    if (error.status === 409) {
      return error.status;
    }
    //The HTTP status code '403 forbidden — you don't have permission to access this resource' is displayed
    if (error.status === 403 && !regexURL.test(error.url)) {
      this.userPermissionService.setIsNoPermission(true);
      return error.status;
    }
    //502 bad gateway

    if (error.status === 502 && !regexURL.test(error.url)) {
      Sentry.captureException(error);
    }

    const errorNew =
      error?.error?.responseException?.exceptionMessage?.ResponseException ||
      error?.error?.responseException?.exceptionMessage?.title ||
      MESSAGE.DEFAULT;
    return errorNew;
  }

  //This function used for get new token based on refresh token.
  private async getNewToken() {
    let error = null;
    let newToken: { accessToken: string; refreshToken: string };
    this.authenticationService.getNewToken().subscribe({
      next: (res: {
        result: { accessToken: string; refreshToken: string };
      }) => {
        newToken = res.result;
        this.authenticationService.setToken(res.result);
      },
      error: (e) => {
        error = e.errors;
        return Promise.resolve(null);
      },
    });
    if (!newToken) throw new Error(error);
    return {
      token: newToken.accessToken,
      refreshToken: newToken.refreshToken,
    };
  }

  //Set refresh token in progress.
  private setRefreshTokenInProgress(status: boolean) {
    localStorage.setItem(
      CONSTANTS.LOCALSTORAGE_KEYS.IS_REFRESH_TOKEN_IN_PROGRESS,
      status.toString(),
    );
  }

  private setRefreshTokenSubjectValue(status: boolean = null) {
    localStorage.setItem(
      CONSTANTS.LOCALSTORAGE_KEYS.REFRESH_TOKEN_SUBJECT_VALUE,
      status === null ? "null" : status.toString(),
    );
    this.refreshTokenSubject.next(status);
  }

  //Get refresh token in progress.
  private getRefreshTokenInProgress() {
    const status =
      localStorage.getItem(
        CONSTANTS.LOCALSTORAGE_KEYS.IS_REFRESH_TOKEN_IN_PROGRESS,
      ) || "false";
    return status === "true" ? true : false;
  }

  private startWatchStorage() {
    window.addEventListener("storage", (event) => {
      if (event.storageArea == localStorage) {
        let v;
        try {
          v = JSON.parse(event.newValue);
        } catch (e) {
          v = event.newValue;
        }
        if (
          event.key === CONSTANTS.LOCALSTORAGE_KEYS.REFRESH_TOKEN_SUBJECT_VALUE
        ) {
          this.refreshTokenSubject.next(v);
        }
      }
    });
  }
}
