export interface CommonPerformanceTable {
  name: string;
  key: string;
}

export const GroupCurrentMonthTable: CommonPerformanceTable[] = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "Previous Month",
    key: "previousValue",
  },
  {
    name: "This Month",
    key: "currentValue",
  },
  {
    name: "Forecast",
    key: "foreCast",
  },
];

export const GroupCurrentWeekTable: CommonPerformanceTable[] = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "Previous Week",
    key: "previousValue",
  },
  {
    name: "This Week",
    key: "currentValue",
  },
  {
    name: "Forecast",
    key: "foreCast",
  },
];

export const GroupCurrentYearTable: CommonPerformanceTable[] = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "Previous Year",
    key: "previousValue",
  },
  {
    name: "This Year",
    key: "currentValue",
  },
  {
    name: "Forecast",
    key: "foreCast",
  },
];

export const GroupCurrentDayTable: CommonPerformanceTable[] = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "Yesterday",
    key: "previousValue",
  },
  {
    name: "Today",
    key: "currentValue",
  },
  {
    name: "Forecast",
    key: "foreCast",
  },
];

export const GroupPercentageMonthTable = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "MONTH",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const GroupPercentageDayTable = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "TODAY",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const GroupPercentageWeekTable = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "WEEK",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const GroupPercentageYearTable = [
  {
    name: "SITE",
    key: "siteName",
  },
  {
    name: "YEAR",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const DevicePercentageMonthTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "MONTH",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const DevicePercentageWeekTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "WEEK",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const DevicePercentageDayTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "DAY",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const DevicePercentageYearTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "YEAR",
    key: "currentValue",
  },
  {
    name: "PERCENTAGE",
    key: "percentage",
  },
];

export const DeviceCurrentMonthTable: CommonPerformanceTable[] = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS MONTH",
    key: "previousValue",
  },
  {
    name: "THIS MONTH",
    key: "currentValue",
  },
  {
    name: "FORECAST",
    key: "foreCast",
  },
];

export const DeviceCurrentWeekTable: CommonPerformanceTable[] = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS WEEK",
    key: "previousValue",
  },
  {
    name: "THIS WEEK",
    key: "currentValue",
  },
  {
    name: "FORECAST",
    key: "foreCast",
  },
];

export const DeviceCurrentDayTable: CommonPerformanceTable[] = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "YESTERDAY",
    key: "previousValue",
  },
  {
    name: "TODAY",
    key: "currentValue",
  },
  {
    name: "FORECAST",
    key: "foreCast",
  },
];

export const DeviceCurrentYearTable: CommonPerformanceTable[] = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS YEAR",
    key: "previousValue",
  },
  {
    name: "THIS YEAR",
    key: "currentValue",
  },
  {
    name: "FORECAST",
    key: "foreCast",
  },
];

export const PowerGroupDayTable = [
  {
    name: "SITE NAME",
    key: "siteName",
  },
  {
    name: "YESTERDAY",
    key: "previousValue",
  },
  {
    name: "TODAY",
    key: "currentValue",
  },
];

export const PowerGroupMonthTable = [
  {
    name: "SITE NAME",
    key: "siteName",
  },
  {
    name: "PREVIOUS MONTH",
    key: "previousValue",
  },
  {
    name: "THIS MONTH",
    key: "currentValue",
  },
];

export const PowerGroupWeekTable = [
  {
    name: "SITE NAME",
    key: "siteName",
  },
  {
    name: "PREVIOUS WEEK",
    key: "previousValue",
  },
  {
    name: "THIS WEEK",
    key: "currentValue",
  },
];

export const PowerGroupYearTable = [
  {
    name: "SITE NAME",
    key: "siteName",
  },
  {
    name: "PREVIOUS YEAR",
    key: "previousValue",
  },
  {
    name: "THIS YEAR",
    key: "currentValue",
  },
];

export const PowerDeviceDayTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "YESTERDAY",
    key: "previousValue",
  },
  {
    name: "TODAY",
    key: "currentValue",
  },
];

export const PowerDeviceMonthTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS MONTH",
    key: "previousValue",
  },
  {
    name: "THIS MONTH",
    key: "currentValue",
  },
];

export const PowerDeviceWeekTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS WEEK",
    key: "previousValue",
  },
  {
    name: "THIS WEEK",
    key: "currentValue",
  },
];

export const PowerDeviceYearTable = [
  {
    name: "DEVICE TYPE",
    key: "labelXAxis",
  },
  {
    name: "PREVIOUS YEAR",
    key: "previousValue",
  },
  {
    name: "THIS YEAR",
    key: "currentValue",
  },
];

export const SiteRedirectKey = "siteName";
export const SitePercentageKey = "percentage";
export const SiteDeviceNameKey = "deviceType";
export const SiteDeviceIdKey = "deviceName";
