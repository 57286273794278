import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { ReportParams, ReportSolarParams } from "../models";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private _http: HttpClient) { }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  //This API used for get list of report group
  reportGroupDataList(data?: ReportParams) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.REPORTS.GET_REPORT_GROUP}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of report notification
  reportNotificationDataList(data?: ReportParams) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.REPORTS.GET_REPORT_NOTIFICATION}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of report Solar
  reportSolarDataList(data?: ReportSolarParams) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.REPORTS.GET_REPORT_SOLAR}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
