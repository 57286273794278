import { DataLogHeaderTitle } from "./data-log";

export class TabModule {
  active: boolean;
  removable: boolean;
  disabled?: boolean;
  link?: string;
  title: string;
  icon?: string;
  id?: number;
}

export const MDMSubTabModule: TabModule[] = [
  {
    active: true,
    removable: true,
    title: "RESIDENCY MANAGEMENT",
    icon: "assets/images/residence.svg",
    id: 1,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    title: "DEVICE MANAGEMENT",
    icon: "assets/images/device-list-icon.svg",
    id: 2,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    title: "GROUP MANAGEMENT",
    icon: "assets/images/group.svg",
    id: 3,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    link: "unit_cost",
    title: "ELECTRICITY UNIT COST",
    icon: "assets/images/unit-cost.svg",
    id: 4,
    disabled: false,
  },
];

export const CommonSubTabModule: TabModule[] = [
  {
    active: true,
    removable: true,
    title: "LENS LITE",
    icon: "assets/images/shield-blue-icon.svg",
    id: DataLogHeaderTitle.LENSLITE,
    disabled: false,
  },
  {
    active: false,
    removable: true,
    title: "LENS +",
    icon: "assets/images/shield-blue-icon.svg",
    id: DataLogHeaderTitle.SENSE,
    disabled: false,
  },
];

export class CommonMapOption {
  id: number;
  name: string;
}

export class DeviceMapOption {
  id: number;
  name: string;
  deviceTypeName: string;
}

export class CommonModel {
  id: number;
  label: string;
  macId?: string
}

export enum DefaultView {
  Date = 1,
  Month = 2,
  Year = 3,
}
