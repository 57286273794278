import * as moment from "moment";

/**units: The unit of time (e.g., 'months', 'weeks', 'days') to subtract.
amount: The number of units to subtract.
start: A boolean flag (default to true) that determines whether to get the start or end of the calculated date.**/
export function getDateAgo(
  units: any,
  amount: number,
  start: boolean = true,
): Date {
  const now = moment();
  const subtracted = now.subtract(amount, units);
  return start
    ? subtracted.startOf(units).toDate()
    : subtracted.endOf(units).toDate();
}

export function getISODate(date: string): string {
  return moment(date).toISOString().substring(0, 10);
}

export function formatDate(date: Date, format: string): string {
  return moment(date).format(format);
}
export function isDateGreaterThanNow(dateString) {
  const toDate = moment(dateString);
  const now = moment();
  return toDate.isAfter(now);
}
