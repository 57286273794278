import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { ListCustom, MDMSubTabModule, ResidenceForm } from "../models";

@Injectable({
  providedIn: "root",
})
export class ResidentialService {
  page: number = CONSTANTS.DEFAULT_KEYS.DEFAULT_PAGE_NUMBER;
  itemsPerPage: number = CONSTANTS.DEFAULT_KEYS.PAGE_DEFAULT_SIZE;
  constructor(private _http: HttpClient) { }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  // ******  RESIDENCE   ***** ///

  //This API used for get all country.
  getAllCountry() {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.COUNTRY}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  getAllState(countryId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.STATE}`,
        { params: { countryId: countryId }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  getAllSubState(stateId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.SUBSTATE}`,
        { params: { stateId: stateId }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  getAllCity(countryId: number, stateId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.STATE_CITY_EMIRATE}`,
        { params: { countryId: countryId, stateId: stateId }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all states or emirate based on country id.
  getAllStatesOrEmirates(countryId: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.STATE_CITY_EMIRATE}`,
        { params: { countryId: countryId }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for create residence.
  createResidence(data: ResidenceForm) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.CREATE_RESIDENCE}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API used for edit residence.
  editResidence(id: number, data: ResidenceForm) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.UPDATE_RESIDENCE}/${id}`,
        data,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  // This API used for get single residence.
  getSingleResidence(id: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.GET_SINGLE_RESIDENCE}/${id}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get list of residence
  listResidence(data?: ListCustom) {
    data["sortedField"] = data["sortedField"]
      ? data["sortedField"]
      : "modifyDate";
    data["isDescending"] =
      data["isDescending"] != undefined ? data["isDescending"] : true;
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.LIST_RESIDENCE}`,
        data,
        { params: { ...data }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for Delete residence
  deleteResidence(id: number) {
    return this._http
      .delete(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.DELETE_RESIDENCE}/${id}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for Activate / deactivate residence.
  activeInactiveResidence(id: number, status: boolean) {
    return this._http
      .put(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.ACTIVE_DEACTIVE_RESIDENCE}/${id}/${status}`,
        {},
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get all residence name.
  getAllResidence(
    keyword?: string,
    size: number = this.itemsPerPage,
    page: number = this.page,
  ) {
    const params = {
      size: size.toString(),
      page: page.toString(),
    };
    if (!!keyword) {
      params["keyword"] = keyword;
    }
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.RESIDENTIAL.GET_ALL_RESIDENCE_NAME}`,
        { params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This function used for get sub bottom tabs for MDM module
  getSubBottomTabs() {
    const key = CONSTANTS.LOCALSTORAGE_KEYS.LIST_DEVICE_SUB_TABS;
    let tabs = [];
    tabs = JSON.parse(localStorage.getItem(key));
    if (!tabs?.length) {
      localStorage.setItem(key, JSON.stringify(MDMSubTabModule));
      tabs = MDMSubTabModule;
    }
    return tabs;
  }
}
